import { PhoneIcon } from "@heroicons/react/24/solid";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";

export default function Header() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      setIsVisible(scrollY >= window.innerHeight - 500);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const transitionStyle = {
    transition: "opacity 0.5s ease-in-out",
    opacity: isVisible ? 1 : 0,
    pointerEvents: isVisible ? "auto" : "none",
  };

  return (
    <div
      className="bg-white hidden fixed w-full top-0 py-3 lg:flex items-center justify-center z-50"
      style={transitionStyle}
    >
      <div className="flex items-center justify-between w-10/12">
        <img src="/logo.png" className="h-10" alt="" />
        <div className="flex items-center ml-10 font-semibold">
          <Link
            spy={true}
            smooth={true}
            to="loginForm"
            className="pageLink px-3 hover:bg-secondary"
          >
            Home
          </Link>
          <NavLink
            to="/about-us"
            className="pageLink px-3 hover:bg-secondary"
          >
            About Us
          </NavLink>
          <Link
            spy={true}
            smooth={true}
            to="howItWorks"
            className="pageLink px-3 hover:bg-secondary"
          >
            How It Works
          </Link>
          <Link
            spy={true}
            smooth={true}
            to="FAQs"
            className="pageLink px-3 hover:bg-secondary"
          >
            FAQs
          </Link>
          <a
            href="tel:+1 (800) 542-2323"
            className="pageLink px-3 hover:bg-secondary gap-1"
          >
            <PhoneIcon className="h-4" />
            +1 (800) 542-2323
          </a>
        </div>
        <div className="flex items-center justify-end">
          <Link
            spy={true}
            smooth={true}
            to="loginForm"
            className="btnPrimary shadow-none rounded-md border-2 border-secondary mr-2 bg-transparent"
          >
            Login
          </Link>
          <NavLink to="/register" className="btnPrimary shadow-none rounded-md">
            Create Account
          </NavLink>
        </div>
      </div>
    </div>
  );
}
