import React from "react";

const EmptyTable = () => {
  return (
    <tr>
      <td colSpan={100}>
        <h1 className="text-center p-4 text-lg text-gray-600">Nothing Here</h1>
      </td>
    </tr>
  );
};

export default EmptyTable;
