import { configureStore } from "@reduxjs/toolkit";

import roleSliceReducer from "./slices/roleSlice";
import settingsSliceReducer from "./slices/settingsSlice";
import userSliceReducer from "./slices/userSlice";
import { cartLinkReducer, buyLinkReducer } from "./slices/cartLinkSlice";

export const store = configureStore({
  reducer: {
    settings: settingsSliceReducer,
    user: userSliceReducer,
    role: roleSliceReducer,
    cartLink: cartLinkReducer,
    buyLink: buyLinkReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AsyncThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
};

export default store;
