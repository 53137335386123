import React from "react";
import { Link } from "react-router-dom";

export default function AboutUs() {
  return (
    <div id="AboutUs" className="flex items-center justify-center py-10">
      <div className="grid lg:grid-cols-about w-10/12 gap-10">
        <div className="flex flex-col justify-center">
          <p className="uppercase">About us</p>
          <h2 className="text-4xl font-bold mt-2">
            Link Builder <span className="text-secondary">Agency</span>
          </h2>
          <img src="/divider.png" alt="" className="mt-2 w-fit" />
          <p className="text-2xl py-5">
            <b>We are confident that we can provide backlinks</b> that align
            with your specific preferences.
          </p>
          <p className="text-gray-600">
            Link Headquarters is the premier link building platform for
            Agencies, Marketing Professionals, businesses and individuals
            looking to utilize the power of our publishing relationships.Our
            wide range of link offerings covers thousands of media outlets
            spanning various countries, languages, categories, and niches.
          </p>
          <br />
          <p className="text-gray-500">
            Once you join Link Headquarters, you will be assigned your personal
            P.R. coordinator who will expertly assist you throughout the process
            from A to Z.You will have complete access to all of our media
            portfolios, along with a diverse range of metrics that you can use
            to filter your search results.
          </p>
          <Link to="/about-us" className="btnPrimary bg-dark mt-8 py-3 px-8 text-white w-fit">
            Learn More
          </Link>
        </div>
        <div>
          <img src="/dashboard.png" className="w-full" alt="" />
        </div>
      </div>
    </div>
  );
}
