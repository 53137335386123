import React from "react";
import { Link } from "react-router-dom";

const data = [
  { title: "DZ100 to DR90", value: "450", link: "" },
  { title: "DR90 to DR80", value: "720", link: "" },
  { title: "DR70 to DR60", value: "800", link: "" },
  { title: "DR60 to DR50", value: "850", link: "" },
  { title: "DR50 to DR40", value: "900", link: "" },
  { title: "DZ100 to DR90", value: "450", link: "" },
  { title: "DR90 to DR80", value: "720", link: "" },
];

export default function BacklinkByRank() {
  return (
    <div className="card justify-between">
      <div className="w-full">
        <div className="flex items-center justify-between w-full border-b pb-4">
          <p className="w-full text-xl font-bold">Backlink By Rank</p>
          <Link to="/dashboard/backlinks/by-rank" className="btnPrimary py-1">
            See All
          </Link>
        </div>
        <table className="mt-2 w-full">
          <tbody>
            {data.map((item, index) => (
              <tr
                key={index}
                className="flex items-center justify-between py-1"
              >
                <td>{item.title}</td>
                <td>{item.value}</td>
                <td className="font-semibold text-blue-900 underline cursor-pointer">
                  View
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
