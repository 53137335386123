import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api/fetch-api";
import { SettingOption } from "../../constants/base.enum";

type SettingOptionType = {
  id: number;
  code: string;
  name: string;
  notes: string;
  isDeletable: boolean;
};

interface SettingsSlice {
  vendorOptions: SettingOptionType[];
  categoryOptions: SettingOptionType[];
  orderStatusOptions: SettingOptionType[];
  propertyOptions: SettingOptionType[];
  priceTierOptions: SettingOptionType[];
}

export const loadSettings = createAsyncThunk(
  "settings/loadSettings",
  async (_, { dispatch }) => {
    try {
      const { data: vendorOptions } = await axiosInstance.get(`/settings/vendor`);
      dispatch(
        updateSettingOptionTypesByCategory({
          category: SettingOption.Vendor,
          newOptions: vendorOptions,
        })
      );
    } catch (error) {
      console.log(error);
    }
    try {
      const { data: categoryOptions } = await axiosInstance.get(`/settings/category`);
      dispatch(
        updateSettingOptionTypesByCategory({
          category: SettingOption.Category,
          newOptions: categoryOptions,
        })
      );
    } catch (error) {
      console.log(error);
    }
    try {
      const { data: orderStatusOptions } = await axiosInstance.get(
        `/settings/orderStatus?join=orders`
      );
      dispatch(
        updateSettingOptionTypesByCategory({
          category: SettingOption.OrderStatus,
          newOptions: orderStatusOptions,
        })
      );
    } catch (error) {
      console.log(error);
    }
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    vendorOptions: [],
    categoryOptions: [],
    orderStatusOptions: [],
    propertyOptions: [],
    priceTierOptions: [],
  } as SettingsSlice,
  reducers: {
    addSettingOptionType(
      state,
      {
        payload,
      }: PayloadAction<{ category: SettingOption; newOption: SettingOptionType }>
    ) {
      switch (payload.category) {
        case SettingOption.Vendor:
          state.vendorOptions.push(payload.newOption);
          break;
        case SettingOption.Category:
          state.categoryOptions.push(payload.newOption);
          break;
        case SettingOption.OrderStatus:
          state.orderStatusOptions.push(payload.newOption);
          break;
      }
      return state;
    },

    removeSettingOptionType(
      state,
      { payload }: PayloadAction<{ category: SettingOption; idx: number }>
    ) {
      const { idx } = payload;
      switch (payload.category) {
        case SettingOption.Vendor:
          state.vendorOptions.splice(idx, 1);
          break;
        case SettingOption.Category:
          state.categoryOptions.splice(idx, 1);
          break;
        case SettingOption.OrderStatus:
          state.orderStatusOptions.splice(idx, 1);
          break;
      }
      return state;
    },

    updateSettingOptionType(
      state,
      {
        payload,
      }: PayloadAction<{
        category: SettingOption;
        idx: number;
        code: string;
        name: string;
        notes: string;
        isDeletable: boolean;
      }>
    ) {
      const { idx, code, name, notes, isDeletable } = payload;
      switch (payload.category) {
        case SettingOption.Vendor:
          state.vendorOptions[idx].code = code;
          state.vendorOptions[idx].name = name;
          state.vendorOptions[idx].notes = notes;
          break;
        case SettingOption.Category:
          state.categoryOptions[idx].name = name;
          break;
        case SettingOption.OrderStatus:
          state.orderStatusOptions[idx].name = name;
          state.orderStatusOptions[idx].isDeletable = isDeletable;
          break;
      }
      return state;
    },

    updateSettingOptionTypesByCategory(
      state,
      {
        payload,
      }: PayloadAction<{
        category: SettingOption;
        newOptions: SettingOptionType[];
      }>
    ) {
      switch (payload.category) {
        case SettingOption.Vendor:
          state.vendorOptions = payload.newOptions;
          break;
        case SettingOption.Category:
          state.categoryOptions = payload.newOptions;
          break;
        case SettingOption.OrderStatus:
          state.orderStatusOptions = payload.newOptions;
          break;
      }
    },
  },
});

export const {
  updateSettingOptionTypesByCategory,
  updateSettingOptionType,
  addSettingOptionType,
  removeSettingOptionType,
} = settingsSlice.actions;

export default settingsSlice.reducer;
