import React, { useState } from "react";

export default function FormStep2({ handlePrevious, handleNext }) {
  return (
    <div className="mt-12 grid lg:grid-cols-2 gap-5 lg:gap-16 w-full">
      <div>
        <h1 className="text-5xl font-bold">
          What type of{" "}
          <span className="text-secondary">business do you have?</span>
        </h1>
        <p className="py-8 text-gray-200">
          We work with all kinds of clients, and have tailored setups for each
          business type. So, whether you are an agency representing multiple
          customers, an affiliate running one or several sites or maybe an
          independent brand with its own SEO department, we can help you.
        </p>
        <CheckboxGrid />
        <div className="flex items-center justify-end gap-2">
          <button
            onClick={handlePrevious}
            type="submit"
            className="btnPrimary py-3 px-6 mt-10"
          >
            Back
          </button>
          <button
            onClick={handleNext}
            type="submit"
            className="btnPrimary py-3 px-6 mt-10"
          >
            Next
          </button>
        </div>
      </div>
      <div>
        <img src="/signup.png" alt="" className="mt-12 ml-auto lg:w-10/12" />
      </div>
    </div>
  );
}

const options = [
  { title: "Agency", img: "/icons/agency.png" },
  { title: "Media", img: "/icons/media.png" },
  { title: "Affiliate", img: "/icons/affiliate.png" },
  { title: "Brand/Ecommerce", img: "/icons/brand.png" },
  { title: "Operator", img: "/icons/operator.png" },
];

function CheckboxGrid() {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (event) => {
    const optionTitle = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedOptions([...selectedOptions, optionTitle]);
    } else {
      setSelectedOptions(
        selectedOptions.filter((option) => option !== optionTitle)
      );
    }
  };

  return (
    <div className="grid grid-cols-2 gap-5 text-black text-lg">
      {options.map((option) => (
        <label
          key={option.title}
          className={`cursor-pointer w-full rounded font-semibold flex items-center justify-between p-2 ${
            selectedOptions.includes(option.title)
              ? "bg-secondary"
              : "bg-gray-50"
          }`}
        >
          <div className="flex items-center gap-5">
            <div className="p-2 bg-gray-200 rounded">
              <img src={option.img} className="w-6" alt="" />
            </div>
            {option.title}
          </div>
          <input
            type="checkbox"
            className="w-4 h-4 rounded-full mr-3"
            value={option.title}
            checked={selectedOptions.includes(option.title)}
            onChange={handleOptionChange}
          />
        </label>
      ))}
    </div>
  );
}
