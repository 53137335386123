import React from "react";

export default function ContactForm() {
  return (
    <div className="flex items-center justify-center flex-col w-full">
      <p className="text-gray-500 text-center">
        *Please fill in our contact form to reach us and we will get back to you
        directly.
      </p>
      <form className="lg:w-10/12 mt-5">
        <div className="grid lg:grid-cols-2 gap-3 gap-x-6 mt-3">
          <div>
            <label htmlFor="First Name" className="inputLabel">
              First Name
            </label>
            <input
              type="text"
              placeholder="First Name"
              className="inputField mt-1 shadow"
            />
          </div>
          <div>
            <label htmlFor="Last Name" className="inputLabel">
              Last Name
            </label>
            <input
              type="text"
              placeholder="Last Name"
              className="inputField shadow mt-1"
            />
          </div>
          <div>
            <label htmlFor="Company Name" className="inputLabel">
              Company Name
            </label>
            <input
              type="text"
              placeholder="Comapny Name"
              className="inputField shadow"
            />
          </div>
          <div>
            <label htmlFor="Phone Number" className="inputLabel">
              Phone Number
            </label>
            <input
              type="text"
              placeholder="Phone Number"
              className="inputField shadow"
            />
          </div>
          <div>
            <label htmlFor="City" className="inputLabel">
              City
            </label>
            <input
              type="text"
              placeholder="City"
              className="inputField shadow"
            />
          </div>
          <div>
            <label htmlFor="Zip Code" className="inputLabel">
              Zip Code
            </label>
            <input
              type="text"
              placeholder="Zip Code"
              className="inputField shadow"
            />
          </div>
        </div>
        <div className="mt-3">
          <label htmlFor="Message" className="inputLabel">
            Message
          </label>
          <textarea
            name="message"
            id=""
            cols="30"
            rows="5"
            placeholder="Message"
            className="inputField shadow mt-1"
          ></textarea>
        </div>
        <button type="submit" className="btnPrimary mt-6 ml-auto">
          Send Message
        </button>
      </form>
    </div>
  );
}
