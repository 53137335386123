import React from "react";

interface Props {
  tool: any,
  tip: any,
  className?: string,
  topValue?: string | ""
}

export default function ToolTip({ tool, tip, className, topValue } : Props) {
  return (
    <div className="navDropdownButton relative">
      <div>
        {tool}        
      </div>
      {topValue && <div className="absolute -top-1 -right-1 flex h-2.5 items-center justify-center rounded-full bg-red-200 px-1 text-[8px] font-semibold tracking-tighter text-red-900">
        {topValue}
      </div>}
      <div
        className={`navDropdown mt-6 top-0 left-1/2 transform -translate-x-1/2 flex-col items-center z-10 w-[500px] absolute ${className}`}
      >
        <Triangle />
        <div
          style={{ fontSize: "12px" }}
          className="bg-blue-900 py-2 px-4 text-white rounded normal-case font-medium max-w-3xl whitespace-pre-wrap text-center"
        >
          {tip}
        </div>
      </div>
    </div>
  );
}

function Triangle() {
  const triangleStyles = {
    width: "0",
    height: "0",
    borderBottom: "10px solid #1E40AF", // Replace 'border-blue-900' with the desired color value
    borderLeft: "5px solid transparent",
    borderRight: "5px solid transparent",
  };

  return <div style={triangleStyles}></div>;
}
