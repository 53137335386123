import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api/fetch-api";
import { Permission } from "../../model/permission.model";
import { Role } from "../../model/role.model";

interface RoleSlice {
  roles: Role[];
  permissions: Permission[];
}

export const loadRoles = createAsyncThunk(
  "role/loadRoles",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/settings/role/getAll?join=permissions`
      );
      return data;
    } catch (e) {
      console.log(e);
      rejectWithValue(e);
    }
  }
);

export const loadPermission = createAsyncThunk(
  "role/loadPermission",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/settings/permission`);
      return data;
    } catch (e) {
      console.log(e);
      rejectWithValue(e);
    }
  }
);

const roleSlice = createSlice({
  name: "role",
  initialState: {
    roles: [],
    permissions: [],
  } as RoleSlice,
  reducers: {
    updateRole: (state, action) => {
      const { idx, updatedRole } = action.payload;
      state.roles[idx] = updatedRole;
      return state;
    },
    updatePermissions: (state, action) => {
      const { permissions, roleId } = action.payload;
      const idx = state.roles.findIndex((role) => role.id === roleId);
      if (idx !== -1) {
        state.roles[idx].permissions = permissions;
      }
      return state;
    },
  },
  extraReducers(builder) {
    builder.addCase(loadRoles.fulfilled, (state, { payload }) => {
      state.roles = payload as Role[];
    });
    builder.addCase(loadPermission.fulfilled, (state, { payload }) => {
      state.permissions = payload as Permission[];
    });
  },
});

export const { updateRole, updatePermissions } = roleSlice.actions;

export default roleSlice.reducer;
