import {
  GridColDef,
  GridRowModel,
  GridRowModesModel,
  GridRowSelectionModel,
  GridRowsProp
} from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../api/fetch-api";
import CrudGrid from "../../components/CrudGrid/CrudGrid";
import EditToolbar, { EditToolbarProps } from "../../components/EditToolbar";
import { CreatedResponseStatus, StorageKeys, UpdatedResponseStatus } from "../../constants/base.const";
import { PermissionEnum, RoleType } from "../../constants/base.enum";
import { useAppSelector } from "../../hooks/rtkHooks";

export default function PaymentType() {

  const role = useAppSelector((state) => state.user.user.roles[0]);

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>([]);
  const [loadingTable, setLoadingTable] = useState(false);

  // fetch orders data from backend
  useEffect(() => {
    setLoadingTable(true);
    axiosInstance
      .get(
        `/settings/paymentType`
      )
      .then(async (response) => {

        const rowsWithId = await response.data.map((row: GridRowModel, index: number) => {
          const { ...others } = row;
          return {
            ...others,
            customId: index + 1,
          };
        });
        setRows(rowsWithId);
        setLoadingTable(false);
      })
  }, []);

  // columns definition
  const defaultColumnDefs: GridColDef[] = [
    {
      field: "customId",
      headerName: "Id",
      editable: false,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Name",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
  ];

  const processRowUpdate = async (
    newRow: GridRowModel,
    oldRow: GridRowModel
  ) => {
    setLoadingTable(true);
    const updatedFields = Object.keys(newRow).filter(
      (fieldName) =>
        JSON.stringify(newRow[fieldName]) !== JSON.stringify(oldRow[fieldName])
    );
    const updatedRow = newRow;
    const filteredRows = rows.filter((row) => selectedRowIds.includes(row.id));
    const selectedRows = filteredRows.length > 0 ? filteredRows : [updatedRow];
    const createdRows: GridRowModel[] = []; // Allow adding only one row at a time.
    const updatedRows: GridRowModel[] = [];
    if (updatedRow.isNew) {
      createdRows.push({
        ...updatedRow,
        isNew: undefined,
        customId: undefined,
      });
    } else {
      selectedRows.forEach((selectedRow) => {
        updatedFields.forEach((updatedField) => {
          selectedRow[updatedField] = updatedRow[updatedField];
        });
        updatedRows.push(selectedRow);
      });
    }
    try {
      if (updatedRows.length) {
        const [update] = updatedRows;
        const paymentTypeId = update.id;
        const { status: updateStatus, data: updatedData } =
          await axiosInstance.patch(`/settings/paymentType/${paymentTypeId}`, {
            ...update,
          });
        if (updateStatus === UpdatedResponseStatus) {
          // TODO: define interfaces and set correct types
          setRows((oldData: any) => {
            const idx = oldData.findIndex(
              (item: any) => item.id === updatedData.id
            );
            const newData = [...oldData];
            newData[idx] = { ...updatedData, customId: idx + 1 };
            return newData as never[];
          });
        }
      }
      // create
      const createdRow = createdRows.at(0);
      if (!!createdRow) {
        const { status: createStatus, data } = await axiosInstance.post(
          `/settings/paymentType`,
          createdRow
        );
        createStatus === CreatedResponseStatus &&
          setRows(rows.map((row) => {
            if (row.id === newRow.id) {
              return { ...data, customId: rows.length }
            }
            else {
              return row
            }
          }
          ));
      }
      setLoadingTable(false);
      return updatedRow;
    } catch (e) {
      console.log(e);
    }
    setLoadingTable(false);
  }

  return (
    <>
      <div className="bg-white mt-3 h-[calc(100vh-100px)]">
        <CrudGrid
          baseApiPath="settings/paymentType"
          checkboxSelection={true}
          defaultColumnDefs={defaultColumnDefs}
          tableKey={StorageKeys.PaymentTypeTableHeaders}
          editable={
            role.type === RoleType.SuperAdmin ? true
              : !!role.permissions.find((permission) => permission.name === PermissionEnum.EditPaymentType)
          }
          deletable={
            role.type === RoleType.SuperAdmin ? true
              : !!role.permissions.find((permission) => permission.name === PermissionEnum.DeletePaymentType)
          }
          loading={loadingTable}
          pinActions={true}
          rowModesModel={rowModesModel}
          rows={rows}
          processRowUpdate={processRowUpdate}
          setLoading={setLoadingTable}
          setRowModesModel={setRowModesModel}
          setRows={setRows}
          setSelectedRowIds={setSelectedRowIds}
          slots={{
            toolbar: (props: EditToolbarProps) => EditToolbar({
              ...props,
              defaultValues: {
                createdDate: new Date(),
                showAddButton:
                  role.type === RoleType.SuperAdmin ? true
                    : !!role.permissions.find((permission) => permission.name === PermissionEnum.CreatePaymentType)
              }
            }),
          }}
          dataGridKey={""} />
      </div>
    </>
  );
}
