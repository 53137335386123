import {
  GridCellParams,
  GridColDef,
  GridRowModel,
  GridRowModesModel,
  GridRowSelectionModel,
  GridRowsProp,
  MuiEvent
} from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../api/fetch-api";
import Modal from "../../components/common/Modal";
import CrudGrid from "../../components/CrudGrid/CrudGrid";
import EditToolbar, { EditToolbarProps } from "../../components/EditToolbar";
import { CreatedResponseStatus, StorageKeys, UpdatedResponseStatus } from "../../constants/base.const";
import { ActiveStatus, PermissionEnum, RoleType } from "../../constants/base.enum";
import { useAppSelector } from "../../hooks/rtkHooks";
import Backlinks from "../Backlinks/Master";

export default function Bundle() {

  const role = useAppSelector((state) => state.user.user.roles[0]);

  const statusOption = [
    { label: ActiveStatus.Active },
    { label: ActiveStatus.Inactive },
  ];

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [selectedBundleId, setSelectedBundleId] = useState(0);

  // fetch orders data from backend
  useEffect(() => {
    setLoadingTable(true);
    axiosInstance
      .get(
        `/backlinks/bundle?join=backlinks`
      )
      .then(async (response) => {

        const rowsWithId = await response.data.map((row: GridRowModel, index: number) => {
          const { ...others } = row;
          return {
            ...others,
            customId: index + 1,
          };
        });
        setRows(rowsWithId);
        setLoadingTable(false);
      })
  }, []);

  // columns definition
  const defaultColumnDefs: GridColDef[] = [
    {
      field: "customId",
      headerName: "Id",
      editable: false,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "createdDate",
      headerName: "Created",
      editable: true,
      type: "date",
      valueGetter: (params) => new Date(params.value),
      headerAlign: "left",
    },
    {
      field: "bundleName",
      headerName: "Bundle Name",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "assignedLinks",
      headerName: "Assigned Links",
      editable: false,
      type: "number",
      headerAlign: "left",
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      editable: false,
      type: "number",
      headerAlign: "left"
    },
    {
      field: "totalRetail",
      headerName: "Total Retail",
      editable: true,
      type: "number",
      headerAlign: "left",
    },
    {
      field: "status",
      headerName: "Status",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => {
        return opt?.value || "";
      },
      valueSetter: (opt) => {
        return {
          ...opt?.row,
          status: opt?.value,
        };
      },
      valueOptions: statusOption.map((opt) => opt.label),
    },
  ];

  const processRowUpdate = async (
    newRow: GridRowModel,
    oldRow: GridRowModel
  ) => {
    setLoadingTable(true);
    const updatedFields = Object.keys(newRow).filter(
      (fieldName) =>
        JSON.stringify(newRow[fieldName]) !== JSON.stringify(oldRow[fieldName])
    );
    const updatedRow = newRow;
    const filteredRows = rows.filter((row) => selectedRowIds.includes(row.id));
    const selectedRows = filteredRows.length > 0 ? filteredRows : [updatedRow];
    const createdRows: GridRowModel[] = []; // Allow adding only one row at a time.
    const updatedRows: GridRowModel[] = [];
    if (updatedRow.isNew) {
      createdRows.push({
        ...updatedRow,
        isNew: undefined,
        customId: undefined,
      });
    } else {
      selectedRows.forEach((selectedRow) => {
        updatedFields.forEach((updatedField) => {
          selectedRow[updatedField] = updatedRow[updatedField];
        });
        updatedRows.push(selectedRow);
      });
    }
    try {
      if (updatedRows.length) {
        const [update] = updatedRows;
        const bundleId = update.id;
        const { status: updateStatus, data: updatedData } =
          await axiosInstance.patch(`/backlinks/bundle/${bundleId}`, update);
        if (updateStatus === UpdatedResponseStatus) {
          // TODO: define interfaces and set correct types
          setRows((oldData: any) => {
            const idx = oldData.findIndex(
              (item: any) => item.id === updatedData.id
            );
            const newData = [...oldData];
            newData[idx] = { ...updatedData, customId: idx };
            return newData as never[];
          });
        }
      }
      // create
      const createdRow = createdRows.at(0);
      if (!!createdRow) {
        const { status: createStatus, data } = await axiosInstance.post(
          `/backlinks/bundle`,
          createdRow
        );
        createStatus === CreatedResponseStatus &&
          setRows(rows.map((row) => {
            if (row.id === newRow.id) {
              return { ...data, customId: rows.length }
            }
            else {
              return row
            }
          }
          ));
      }
      setLoadingTable(false);
      return updatedRow;
    } catch (e) {
      console.log(e);
    }
    setLoadingTable(false);
  }

  const [openModal, setOpenModal] = useState(true);
  const handleModal =() => {
    setOpenModal(!openModal);
  }


  const handleCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    if(params.colDef.field === "assignedLinks") {
      setSelectedBundleId(params.row.id);
      handleModal();
    }
  }

  return (
    <>
    <Modal
        open={openModal}
        handleModal={handleModal}
        cancelButtonRef={undefined}
        className="max-w-5xl"
        title=""
        isLoading={false}
        handleModalSubmit={undefined}
        modalType={undefined}
        modalFormStep={1}
        setModalFormStep={undefined}
        HeaderButton={false} 
        >
        <Backlinks assignType="bundle" assignId={selectedBundleId}></Backlinks>
      </Modal>
      <div className="bg-white mt-3 h-[calc(100vh-100px)]">
        <CrudGrid
          baseApiPath="backlinks/bundle"
          checkboxSelection={true}
          dataGridKey={""}
          defaultColumnDefs={defaultColumnDefs}
          tableKey={StorageKeys.BundleTableHeaders}
          editable={
            role.type === RoleType.SuperAdmin ? true 
            : !!role.permissions.find((permission) => permission.name === PermissionEnum.EditOrderBundle)
          }
          deletable={
            role.type === RoleType.SuperAdmin ? true 
            : !!role.permissions.find((permission) => permission.name === PermissionEnum.DeleteOrderBundle)
          }
          loading={loadingTable}
          pinActions={true}
          rowModesModel={rowModesModel}
          rows={rows}
          handleCellClick={handleCellClick}
          processRowUpdate={processRowUpdate}
          setLoading={setLoadingTable}
          setRowModesModel={setRowModesModel}
          setRows={setRows}
          setSelectedRowIds={setSelectedRowIds}
          slots={{
            toolbar: (props: EditToolbarProps) => EditToolbar({ 
              ...props, 
              defaultValues: {
                createdDate: new Date(),
                status: ActiveStatus.Active,
                showAddButton: 
                role.type === RoleType.SuperAdmin ? true 
                : !!role.permissions.find((permission) => permission.name === PermissionEnum.CreateOrderBundle)
              } 
            }),
          }}
        />
      </div>
    </>
  );
}
