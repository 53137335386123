import {
  AdjustmentsHorizontalIcon,
  ArrowTopRightOnSquareIcon, CurrencyDollarIcon,
  ExclamationTriangleIcon, GlobeAltIcon,
  LinkIcon, QueueListIcon
} from "@heroicons/react/24/outline";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { NetworkPing } from "@mui/icons-material";
import { TreeItemProps } from "@mui/lab";
import React, { useEffect, useState } from "react";
import {
  FaBitcoin, FaCannabis, FaDiceD20,
  FaFileInvoiceDollar,
  FaRegHeart
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { axiosInstance } from "../../api/fetch-api";
import { SearchBox, ToolTip } from "../../components/common";
import DataTable from "../../components/common/DataTable";
import { useAppDispatch } from "../../hooks/rtkHooks";
import {
  addToCart, CartLinkSlice, decQuantity, decrementQuantity, incQuantity, incrementQuantity, removeFromCart
} from "../../store/slices/cartLinkSlice";
import { columns } from "./columns";
import { DropdownButtonRange, DropdownButtonSelect } from "./DropdownButton";
import { DropdownTeeCombo } from "./DropdownTreeCombo";

export type RangeValueType = {
  min?: number;
  max?: number;
}

type RangeConditionType = {
  $gte?: number,
  $lte?: number
}

type QueryType = {
  publication?: any;
  country?: string;
  dr?: RangeConditionType
  as?: RangeConditionType;
  da?: RangeConditionType;
  spamScore?: RangeConditionType;
  cost?: RangeConditionType;
  referringDomains?: RangeConditionType;
  monthlyTraffic?: RangeConditionType;
  $or?: any[];
}


export default function Marketplace() {
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [publication, setPublication] = useState("");
  const [searchCategories, setSearchCategories] = useState<TreeItemProps[]>([]);
  const [country, setCountry] = useState("");
  const [dr, setDr] = useState<RangeValueType>();
  const [as, setAs] = useState<RangeValueType>();
  const [da, setDa] = useState<RangeValueType>();
  const [spamScore, setSpamScore] = useState<RangeValueType>();
  const [cost, setCost] = useState<RangeValueType>();
  const [referringDomains, setReferringDomains] = useState<RangeValueType>();
  const [monthlyTraffic, setOrganicTraffic] = useState<RangeValueType>();
  const [backlinkData, setBacklinkData] = useState<any>([]);
  const [page, setPage] = useState(0);

  const getSearchQuery = () => {
    const query: QueryType = {
      // "backlinkPriceTiers.priceTierId": user.priceTier?.id,
    };
    if (!!publication) {
      query.publication = { $cont: publication };
    }
    if (!!country) {
      query.country = country;
    }
    if (!!dr) {
      query.dr = {
        $gte: dr.min,
        $lte: dr.max,
      };
    }
    if (!!as) {
      query.as = {
        $gte: as.min,
        $lte: as.max,
      };
    }
    if (!!da) {
      query.da = {
        $gte: da.min,
        $lte: da.max,
      };
    }
    if (!!spamScore) {
      query.spamScore = {
        $gte: spamScore.min,
        $lte: spamScore.max,
      };
    }
    if (!!cost) {
      query.cost = {
        $gte: cost.min,
        $lte: cost.max,
      };
    }
    if (!!referringDomains) {
      query.referringDomains = {
        $gte: referringDomains.min,
        $lte: referringDomains.max,
      };
    }
    if (!!monthlyTraffic) {
      query.monthlyTraffic = {
        $gte: monthlyTraffic.min,
        $lte: monthlyTraffic.max,
      };
    }
    if (searchCategories.length > 0) {
      const categorySearchCondList: any[] = [{ "category1.id": { "$in": searchCategories } }, { "category2.id": { "$in": searchCategories } },
      { "category3.id": { "$in": searchCategories } }, { "category4.id": { "$in": searchCategories } }, { "category5.id": { "$in": searchCategories } }
        , { "category6.id": { "$in": searchCategories } }, { "category7.id": { "$in": searchCategories } }, { "category8.id": { "$in": searchCategories } }
        , { "category9.id": { "$in": searchCategories } }, { "category10.id": { "$in": searchCategories } }, { "category11.id": { "$in": searchCategories } }
        , { "category12.id": { "$in": searchCategories } }, { "category13.id": { "$in": searchCategories } }, { "category14.id": { "$in": searchCategories } }
        , { "category15.id": { "$in": searchCategories } }];
      query.$or = categorySearchCondList;
    }
    return query;
  };

  const fetchResults = async () => {
    const search = JSON.stringify(getSearchQuery());
    const query = `/backlink?join=vendor&join=category1&join=category2&join=category3&join=category4&join=category5&join=category6&join=category7&join=category8&join=category9&join=category10&join=category11&join=category12&join=category13&join=category14&join=category15&offset=${page * pageSize}&limit=${pageSize}&s=${search}`

    axiosInstance
      .get(query)
      .then((res) => {
        if (res.status === 200) {
          const array: any = res.data?.data?.map((val: any) => ({
            ...val,
            quantity: 1,
          }));
          setBacklinkData({ data: array, total: res.data?.total, pageCount: res.data?.pageCount });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    axiosInstance.post("/backlink/getCountries").then((res) => {
      const { status, data } = res;
      if (status === 201) {
        const countries = data.map((item: any) => item.country);
        setCountries(countries);
      }
    });
    axiosInstance.get("/settings/category/getAllCategories").then((res) => {
      const { status, data } = res;
      if (status === 200) {
        setCategories(data);
      }
    });
  }, []);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      const page = 0;
      setPage(page);
      setLoading(true);
      fetchResults();
    }, 1000);
    return () => {
      clearTimeout(searchTimeout);
    };
  }, [publication]);

  useEffect(() => {
    const page = 0;
    setPage(page);
    setLoading(true);
    fetchResults();
  }, [country, searchCategories, dr, as, da, spamScore, cost, referringDomains, monthlyTraffic]);

  useEffect(() => {
    setLoading(true);
    fetchResults();
  }, [page]);

  const toFirstPage = () => {
    setPage(0);
  };
  const toPreviousPage = () => {
    if (page > 0) setPage(page - 1);
  };
  const toNextPage = () => {
    if (page < backlinkData?.pageCount - 1)
      setPage(page + 1);
  };
  const toLastPage = () => {
    setPage(backlinkData?.pageCount - 1);
  };

  const pageSize = 20; // Number of items per page
  const totalRecords = backlinkData?.total || 0; // Total number of records

  // Calculate startIndex and lastIndex based on the current page
  const startIndex = page * pageSize + 1;
  const lastIndex = Math.min((page + 1) * pageSize, totalRecords);

  const cartLinks = useSelector((state: any) => state.cartLink);

  const handleDecrement = (itemId: number) => {
    if (cartLinks.some((link: any) => link.id === itemId)) {
      dispatch(decrementQuantity(itemId));
    } else {
      dispatch(decQuantity(itemId));
    }
  };
  const handleIncrement = (itemId: number) => {
    if (cartLinks.some((link: any) => link.id === itemId)) {
      dispatch(incrementQuantity(itemId));
    } else {
      dispatch(incQuantity(itemId));
    }
  };

  const handleRemove = (itemId: number) => {
    dispatch(removeFromCart(itemId));
  };

  const handleCart = (cartLink: CartLinkSlice) => {
    dispatch(addToCart(cartLink));
  };

  const increment = (itemId: number) => {
    setBacklinkData((prevArray: any) => {
      return {
        ...prevArray,
        data: prevArray.data.map((val: any) => {
          if (val.id === itemId) {
            return {
              ...val,
              quantity: val.quantity + 1,
            };
          }
          return val;
        }),
      };
    });
  };

  const decrement = (itemId: number) => {
    setBacklinkData((prevArray: any) => {
      return {
        ...prevArray,
        data: prevArray.data.map((val: any) => {
          if (val.id === itemId) {
            return {
              ...val,
              quantity: val.quantity - 1,
            };
          }
          return val;
        }),
      };
    });
  };

  const makeCategoryStr = (backlink: any) => {
    let categoryStr = "";
    categoryStr += (!!backlink?.category1?.name ? backlink?.category1?.name : "") + (!!backlink?.category2?.name ? ", " + backlink?.category2?.name : "")
      + (!!backlink?.category3?.name ? ", " + backlink?.category3?.name : "") + (!!backlink?.category4?.name ? ", " + backlink?.category4?.name : "")
      + (!!backlink?.category5?.name ? ", " + backlink?.category5?.name : "") + (!!backlink?.category6?.name ? ", " + backlink?.category6?.name : "")
      + (!!backlink?.category7?.name ? ", " + backlink?.category7?.name : "") + (!!backlink?.category8?.name ? ", " + backlink?.category8?.name : "")
      + (!!backlink?.category9?.name ? ", " + backlink?.category9?.name : "") + (!!backlink?.category10?.name ? ", " + backlink?.category10?.name : "")
      + (!!backlink?.category11?.name ? ", " + backlink?.category11?.name : "") + (!!backlink?.category12?.name ? ", " + backlink?.category12?.name : "")
      + (!!backlink?.category13?.name ? ", " + backlink?.category13?.name : "") + (!!backlink?.category14?.name ? ", " + backlink?.category14?.name : "")
      + (!!backlink?.category15?.name ? ", " + backlink?.category15?.name : "");
    return categoryStr;
  }

  return (
    <>
      <div className="flex items-center gap-2 py-3 scroll-container flex-wrap w-full flex-1">
        <DropdownButtonSelect
          title="Country"
          Icon={GlobeAltIcon}
          options={countries}
          valueSetter={(country) => setCountry(country)}
          currentValue={country}
          name="country"
        />
        <DropdownTeeCombo
          title={"TreeCategory"}
          Icon={QueueListIcon}
          valueSetter={(selectedCategories) => {
            setSearchCategories(selectedCategories)
          }}
          name={"category"}
          options={categories}
        />
        {/* <DropdownButtonSelect
          title="Category"
          Icon={QueueListIcon}
          options={categoryOptions}
          valueSetter={(category) => {
            setCategory(category)
          }}
          currentValue={category}
          name="category"
        /> */}
        <DropdownButtonRange
          Icon={LinkIcon}
          title="Ahrefs (DR)"
          currentValue={dr}
          valueSetter={(dr) => setDr(dr)}
        />
        <DropdownButtonRange
          Icon={LinkIcon}
          title="Semrush (AS)"
          currentValue={as}
          valueSetter={(as) => setAs(as)}
        />
        <DropdownButtonRange
          Icon={LinkIcon}
          title="Moz (DA)"
          currentValue={da}
          valueSetter={(da) => setDa(da)}
        />
        <DropdownButtonRange
          Icon={ExclamationTriangleIcon}
          title="Spam Score"
          currentValue={spamScore}
          valueSetter={(spamScore) => setSpamScore(spamScore)}
        />
        <DropdownButtonRange
          Icon={CurrencyDollarIcon}
          title="Cost"
          currentValue={cost}
          valueSetter={(cost) => setCost(cost)}
        />
        <DropdownButtonRange
          Icon={LinkIcon}
          title="Referring Domains"
          currentValue={referringDomains}
          valueSetter={(referringDomains) =>
            setReferringDomains(referringDomains)
          }
        />
        <DropdownButtonRange
          Icon={NetworkPing}
          title="Organic Traffic"
          currentValue={monthlyTraffic}
          valueSetter={(monthlyTraffic) => setOrganicTraffic(monthlyTraffic)}
        />
      </div>

      <div className="flex items-center justify-between mt-3">
        <div className="flex items-center bg-white pr-3 border rounded">
          <SearchBox
            className="w-72 bg-transparent"
            onChange={({ target: { value } }: any) => {
              setPublication(value);
            }}
            value={publication}
          />
          <AdjustmentsHorizontalIcon className="w-6" />
        </div>
      </div>

      <DataTable
        previousPageDisabled={startIndex === 1}
        nextPageDisabled={lastIndex === backlinkData?.total}
        startIndex={startIndex}
        lastIndex={lastIndex}
        totalRecords={backlinkData?.total}
        toFirstPage={toFirstPage}
        toPreviousPage={toPreviousPage}
        toNextPage={toNextPage}
        toLastPage={toLastPage}
        heads={columns}
        items={backlinkData?.data?.map((backlink: any, index: number) => {
          const cost = backlink?.cost;
          const cartLink = cartLinks.find((link: any) => link.id === backlink.id);
          const isInCart = cartLinks.some((link: any) => link.id === backlink.id);
          return {
            buy: (
              <div className="text-sm flex items-center gap-4">
                <>
                  {isInCart ? (
                    <>
                      <div className="flex items-center justify-between gap-1 w-16">
                        <button
                          type="button"
                          disabled={cartLink.quantity === 1}
                          onClick={() => handleDecrement(backlink.id)}
                        >
                          <MinusCircleIcon className="cursor-pointer text-red-500 w-5" />
                        </button>
                        {cartLink.quantity}
                        <button
                          type="button"
                          onClick={() => handleIncrement(backlink.id)}
                          className="cursor-pointer text-green-500 w-5"
                        >
                          <PlusCircleIcon />
                        </button>
                      </div>
                      <p className="w-16">${cost * cartLink.quantity}</p>
                      <button
                        title="Remove from cart"
                        onClick={() => handleRemove(cartLink.id)}
                        className="btnPrimary bg-red-500 p-2 px-3 text-xs"
                      >
                        Remove Cart
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="flex items-center justify-between gap-1 w-16">
                        <button
                          type="button"
                          onClick={() => decrement(backlink.id)}
                        >
                          <MinusCircleIcon className="cursor-pointer text-red-500 w-5" />
                        </button>
                        {backlink.quantity}
                        <button
                          type="button"
                          onClick={() => increment(backlink.id)}
                          className="cursor-pointer text-green-500 w-5"
                        >
                          <PlusCircleIcon />
                        </button>
                      </div>
                      <p className="w-16">${cost}</p>
                      <button
                        onClick={() => handleCart({
                          id: backlink.id,
                          publication: backlink.publication,
                          price: backlink.cost,
                          quantity: backlink.quantity,
                          casino: backlink?.casino,
                          loan: backlink?.loan,
                          erotic: backlink?.erotic,
                          dating: backlink?.dating,
                          cbd: backlink?.cbd,
                          crypto: backlink?.crypto
                        })}
                        className="btnPrimary p-2 text-xs flex-1"
                      >
                        Add to Cart
                      </button>
                    </>
                  )}
                </>
              </div>
            ),
            media: (
              <a
                href={"https://" + backlink.publication}
                target="_blank"
                className="text-sm flex items-center gap-2 hover:text-secondary transition-all cursor-pointer"
              >
                {backlink.publication}
                <ArrowTopRightOnSquareIcon className="w-4" />
              </a>
            ),
            vendorCode: backlink.vendor.code,
            country: backlink.country,
            dr: <span className="flex justify-center">{backlink.dr}</span>,
            as: <span className="flex justify-center">{backlink.as}</span>,
            da: <span className="flex justify-center">{backlink.da}</span>,
            spamScore: (
              <span className="flex justify-center">{backlink.spam}</span>
            ),
            createdate: `${(new Date(backlink.createdDate).getMonth() + 1).toString().padStart(2, '0')}/${new Date(backlink.createdDate).getDate().toString().padStart(2, '0')}/${new Date(backlink.createdDate).getFullYear()}`,
            monthlyTraffic: (
              <span className="flex justify-center">
                {backlink.monthlyTraffic}
              </span>
            ),
            referringDomains: (
              <span className="flex justify-center">
                {backlink.referringDomains}
              </span>
            ),
            tatDays: backlink.tatDays,
            niches: (
              <div className="flex text-lg items-center justify-between gap-2 w-52">
                <ToolTip
                  tool={!!backlink.casino ? <FaDiceD20 className="w-6" /> : <FaDiceD20 className="w-5 opacity-40" />}
                  topValue={!!backlink.casino ? backlink.casino : ""}
                  tip={!!backlink.casino ? `The price of casino content is $${parseInt(backlink.cost) * parseFloat(backlink.casino)}` : "This media doesn't accept casino content"} />
                <ToolTip
                  tool={!!backlink.loan ? <FaFileInvoiceDollar className="w-6" /> : <FaFileInvoiceDollar className="w-6 opacity-40" />}
                  topValue={!!backlink.loan ? backlink.loan : ""}
                  tip={!!backlink.loan ? `The price for loan content is $${parseInt(backlink.cost) * parseFloat(backlink.loan)}` : `This media doesn't accept loan content`} />
                {!!backlink.erotic ?
                  <ToolTip
                    tool={<img src="/18plus.png" className="w-5" alt="" />}
                    topValue={backlink.erotic}
                    tip={`The price for erotic content is $${parseInt(backlink.cost) * parseFloat(backlink.erotic)}`} /> :
                  <ToolTip
                    tool={<img src="/18plus.png" className="w-5 opacity-40" alt="" />}
                    tip="This media doesn't accept erotic content" />
                }
                {!!backlink.dating ?
                  <ToolTip
                    tool={<FaRegHeart className="w-5" />}
                    topValue={backlink.dating}
                    tip={`The price for dating content is ${parseInt(backlink.cost) * parseFloat(backlink.dating)}`} /> :
                  <ToolTip
                    tool={<FaRegHeart className="w-5 opacity-40" />}
                    tip="This media doesn't accept dating content" />
                }
                {!!backlink.cbd ?
                  <ToolTip
                    tool={<FaCannabis className="w-5" />}
                    topValue={backlink.cbd}
                    tip={`The price for cbd content is $${parseInt(backlink.cost) * parseFloat(backlink.cbd)}`} /> :
                  <ToolTip
                    tool={<FaCannabis className="w-5 opacity-40" />}
                    tip="This media doesn't accept cbd content" />
                }
                {!!backlink.crypto ?
                  <ToolTip
                    tool={<FaBitcoin className="w-5" />}
                    topValue={backlink.crypto}
                    tip={`The price for crypto content is $${parseInt(backlink.cost) * parseFloat(backlink.crypto)}`} /> :
                  <ToolTip
                    tool={<FaBitcoin className="w-5 opacity-40" />}
                    tip="This media doesn't accept crypto content" />
                }
              </div>
            ),
            image: backlink.image,
            indexed: backlink.indexed,
            sponsored: backlink.sponsored,
            follow: backlink.follow,
            categories: makeCategoryStr(backlink),
            publicationSamples: backlink.publicationSamples,
            notes: backlink.notes,
          };
        })}
        className={""}
        tableHeight={40}
        isLoading={loading} />
    </>
  );
}
