import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { StorageKeys } from "./constants/base.const";
import { PermissionEnum } from "./constants/base.enum";
import { useAppDispatch, useAppSelector } from "./hooks/rtkHooks";
import { loadPermission, loadRoles } from "./store/slices/roleSlice";
import { loadSettings } from "./store/slices/settingsSlice";
import { fetchUserBytoken } from "./store/slices/userSlice";
import ThemeCustomization from "./themes";
import { getStorageValue } from "./util/localStorage.util";
import {
  About,
  AccountSettings,
  Backlinks,
  Billing,
  Contact,
  Dashboard, Login,
  Main,
  Marketplace, Merchant, MyOrders, MyQuotes, Order, OrdersPage, Roles,
  Signup,
  TermsConditions,
  Users, Vendor
} from "./views";
import AllQuotes from "./views/AllQuotes";
import Bundle from "./views/Bundle/Bundle";
import Category from "./views/Category";
import Group from "./views/Group/Group";
import ImportLogTable from "./views/ImportLog/ImportLog";
import OrderStatus from "./views/OrderStatus/OrderStatus";
import PaymentType from "./views/PaymentType/PaymentType";
import PriceTier from "./views/PriceTier/PriceTier";
import SwitchMerchant from "./views/SwitchMerchant/SwitchMerchant";
import Terms from "./views/Terms/Terms";

function App() {
  const dispatch = useAppDispatch();
  const { isSuccess, isFetching, user } = useAppSelector(
    (state) => state.user
  );
  const { orderStatusOptions } = useAppSelector((state) => state.settings);

  const role = !!user.roles?.length && user.roles[0];

  const isSuper = !!user.roles?.length && user.roles[0].name === "SuperAdmin";
  const isMerchant =
    !!user.roles?.length && user.roles[0].name === "MerchantAdmin";

  const checkPermission = (permission: PermissionEnum) => {
    if (role) {
      const userPermission = role.permissions.find(
        (permissionItem) => permissionItem?.name === permission
      );
      if (!!userPermission) {
        return true;
      }
    }
    return false;
  };

  const token = getStorageValue(StorageKeys.Token);
  let routes;
  if (isSuccess) {
    routes = [
      {
        hasPermission: true,
        route: { path: "/dashboard", element: <Main /> },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.ViewUser),
        route: {
          path: "/dashboard/settings/users",
          element: <Users />,
        },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.ViewRole),
        route: {
          path: "/dashboard/settings/roles",
          element: <Roles />,
        },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.ViewVendor),
        route: {
          path: "/dashboard/settings/vendor",
          element: <Vendor />,
        },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.ViewCategory),
        route: {
          path: "/dashboard/settings/category",
          element: <Category />,
        },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.ViewImprtLog),
        route: {
          path: "/dashboard/settings/importLog",
          element: <ImportLogTable />,
        },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.ViewPriceTier),
        route: {
          path: "/dashboard/settings/priceTiers",
          element: <PriceTier />,
        },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.ViewMerchant),
        route: {
          path: "/dashboard/settings/merchant",
          element: <Merchant />,
        },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.ViewOrderStatus),
        route: {
          path: "/dashboard/settings/orderStatus",
          element: <OrderStatus />,
        },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.ViewTerms),
        route: {
          path: "/dashboard/settings/terms",
          element: <Terms />,
        },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.ViewPaymentType),
        route: {
          path: "/dashboard/settings/paymentType",
          element: <PaymentType />,
        },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.SwitchMerchant),
        route: {
          path: "/dashboard/settings/switchMerchant",
          element: <SwitchMerchant />,
        },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.MyOrders),
        route: {
          path: `/dashboard/orders`,
          element: <OrdersPage />,
        },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.ViewOrderBundle),
        route: {
          path: `/dashboard/backlinks/bundles`,
          element: <Bundle />,
        },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.ViewMerchantGroup),
        route: {
          path: `/dashboard/backlinks/groups`,
          element: <Group />,
        },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.ChagneQuoteStatus),
        route: {
          path: "/dashboard/quotes/all-quotes",
          element: <AllQuotes />,
        },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.MyOrders),
        route: {
          path: "/dashboard/orders/my-orders",
          element: <MyOrders />,
        },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.MyQutoes),
        route: {
          path: "/dashboard/quotes/my-quotes",
          element: <MyQuotes />,
        },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.ViewBacklink),
        route: { path: "/dashboard/backlinks", element: <Backlinks /> },
      },
      {
        hasPermission: isSuper || checkPermission(PermissionEnum.MarketPlace),
        route: {
          path: "/dashboard/marketplace",
          element: <Marketplace />,
        },
      },
      {
        hasPermission: checkPermission(PermissionEnum.MyOrders),
        route: {
          path: "/dashboard/marketplace/order",
          element: <Order />,
        },
      },
      {
        hasPermission: isMerchant,
        route: {
          path: "/dashboard/marketplace/order/billing",
          element: <Billing />,
        },
      },
      {
        hasPermission: true,
        route: {
          path: "/dashboard/account-settings",
          element: <AccountSettings />,
        },
      },
      {
        hasPermission: true,
        route: { path: "*", element: <Navigate to="/dashboard" replace /> },
      },
    ];

    if (orderStatusOptions.length > 0) {
      orderStatusOptions.map((orderStatus) => {
        routes.push({
          hasPermission: isSuper || checkPermission(PermissionEnum.MyOrders),
          route: {
            path: `/dashboard/orders/${orderStatus.name}`,
            element: <OrdersPage orderStatus={orderStatus.id} />,
          },
        });
      })
    }
  } else {
    routes = [
      {
        hasPermission: true,
        route: { path: "/login", exact: true, element: <Login /> },
      },
      {
        hasPermission: true,
        route: { path: "/register", exact: true, element: <Signup /> },
      },
      {
        hasPermission: true,
        route: { path: "/contact-us", exact: true, element: <Contact /> },
      },
      {
        hasPermission: true,
        route: { path: "/about-us", exact: true, element: <About /> },
      },
      {
        hasPermission: true,
        route: {
          path: "/terms-and-conditions",
          exact: true,
          element: <TermsConditions />,
        },
      },
      {
        hasPermission: true,
        route: { path: "*", element: <Navigate to="/login" replace /> },
      },
    ];
  }

  useEffect(() => {
    dispatch(loadSettings());
    dispatch(loadPermission());
    dispatch(loadRoles());
    dispatch(fetchUserBytoken({ token }));
  }, [dispatch, token]);

  return (
    <ThemeCustomization>
      <BrowserRouter>
        <Dashboard isLoading={isFetching} loggedIn={isSuccess}>
          <Routes>
            {routes?.map(
              (route, index) =>
                route.hasPermission && <Route key={index} {...route.route} />
            )}
          </Routes>
        </Dashboard>
      </BrowserRouter>
    </ThemeCustomization>
  );
}

export default App;
