export const ChartData = [
  {
    name: "Direct",
    percentage: "10",
  },
  {
    name: "Search",
    percentage: "15",
  },
  {
    name: "Social Media",
    percentage: "20",
  },
  {
    name: "Referral",
    percentage: "15",
  },
  {
    name: "Email",
    percentage: "13",
  },
  {
    name: "Organic",
    percentage: "8",
  },
  {
    name: "Paid Ads",
    percentage: "5",
  },
  {
    name: "Affiliate",
    percentage: "9",
  },
  {
    name: "Display",
    percentage: "7",
  },
  {
    name: "Direct Mail",
    percentage: "12",
  },
  {
    name: "Video",
    percentage: "18",
  },
  {
    name: "Other",
    percentage: "14",
  },
];
