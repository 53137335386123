import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

export default function SearchBox(props) {
  return (
    <div className={`relative w-full bg-white ${props.className}`}>
      <div className="flex items-center w-full appearance-none px-3 flex-1">
        <MagnifyingGlassIcon className="w-5" />
        <input
          placeholder="Search.."
          value={props.value}
          onChange={props.onChange}
          className="bg-transparent flex-1 w-full outline-none ml-3 py-3 appearance-none"
        />
      </div>
    </div>
  );
}
