import React from "react";
import { Link } from "react-router-dom";

export default function HowItWorks() {
  return (
    <div id="howItWorks" className="flex items-center justify-center w-full py-10 lg:py-16 text-dark">
      <div className="w-10/12">
        <div className="flex items-center flex-col text-center">
          <h2 className="text-4xl font-bold">How It works</h2>
          <img src="/divider-dark.png" alt="" className="w-fit mt-2" />
          <p className="mt-3 lg:w-6/12 text-gray-500">
            We do 100% manual outreach on behalf of your clients, and you pay
            only for the published links you get.We offer a 100% refund or
            replacement guarantee if the links ever go down.
          </p>
        </div>
        <div className="w-full grid grid-cols-4 gap-7 mt-9">
          <div>
            <img src="/link.png" className="w-10" alt="" />
            <h3 className="my-2 text-xl font-bold">Media Vendor Match</h3>
            <p className="text-gray-500">
              After you have chosen your desired publication target (backlink),
              we will conduct a niche analysis specific to the selected media.
            </p>
          </div>
          <div>
            <img src="/search.png" className="w-10" alt="" />
            <h3 className="my-2 text-xl font-bold">Human Proofreading</h3>
            <p className="text-gray-500">
              Prior to being submitted for your final approval, all articles
              undergo a thorough plagiarism check and proofreading process to
              ensure their quality and authenticity.
            </p>
          </div>
          <div>
            <img src="/creation.png" className="w-9" alt="" />
            <h3 className="my-2 text-xl font-bold">
              Content Curation & Creation
            </h3>
            <p className="text-gray-500">
              One of our 100+ global content creators will produce content
              tailored to your unique specifications and business needs.
            </p>
          </div>
          <div>
            <img src="/go-live.png" className="w-9" alt="" />
            <h3 className="my-2 text-xl font-bold">Go Live</h3>
            <p className="text-gray-500">
              Upon receiving your approval, the article will be published either
              immediately or on a specific date of your choosing.
            </p>
          </div>
        </div>

        <div className="mt-10 grid lg:grid-cols-2 gap-5">
          <div>
            <img src="/link-no-comparison.png" className="w-full" alt="" />
          </div>
          <div className="flex flex-col justify-center">
            <h2 className="text-4xl font-bold">
              Why there is no comparison...
            </h2>
            <img src="/divider.png" alt="" className="w-fit mt-5" />
            <br />
            <p className="text-gray-500 mt-3">
              At Link Headquarters, we have a team of content creators and
              proofreaders who work in-house to guarantee that every piece of
              content we produce meets our publishers standards. <br />
            </p>
            <p className="mt-3 text-gray-500">
              We can efficiently create content in multiple languages, always
              prioritizing quality over speed. To ensure that the client's
              content matches the tone and style of the publication, we
              carefully consider the medium where the content will be published.
            </p>
            <p className="mt-3 text-gray-500">
              We respect the specific requirements of our clients and media
              outlets, while adhering to search engine policies regarding
              plagiarism and unethical behavior.
            </p>
            <p className="mt-3 text-gray-500">
              If you have any questions or require guidance on content creation,
              our expert team is always ready to assist you.
            </p>
            <div className="flex items-center mt-10 gap-3">
              <Link to="/register" className="btnPrimary bg-dark border-2 border-dark shadow-none text-white py-3 px-7">
                Get free access
              </Link>
              <button className="btnPrimary bg-white border-2 border-dark shadow-none py-3 px-7">
                Contact Sales
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
