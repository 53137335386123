import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import React from "react";
import Checkbox from "../../components/common/CheckBox";

export default function FormStep1({ handleNext }) {
  return (
    <div className="mt-12 grid lg:grid-cols-2 gap-5 lg:gap-16 w-full">
      <div>
        <h1 className="text-5xl font-bold">
          Link Headquarters Agency{" "}
          <span className="text-secondary">Sign up Process</span>
        </h1>
        <ul className="mt-10 space-y-3">
          <li>
            <p className="text-lg text-secondary">Content is always included</p>
            <p>
              Our professional staff of content writers makes sure you get
              quality content for approval within a few days
            </p>
          </li>
          <li>
            <p className="text-lg text-secondary">
              Live chat with industry experts
            </p>
            <p>
              Our professional staff of content writers makes sure you get
              quality content for approval within a few days
            </p>
          </li>
          <li>
            <p className="text-lg text-secondary">
              Access more than 12.000 media worldwide
            </p>
            <p>
              Our professional staff of content writers makes sure you get
              quality content for approval within a few days
            </p>
          </li>
        </ul>
        <img src="/signup.png" alt="" className="mt-12 lg:w-8/12" />
      </div>
      <div>
        <form
          onSubmit={handleNext}
          className="bg-white text-black rounded p-10 w-full"
        >
          <div className="flex items-center">
            <ArrowUturnLeftIcon className="w-5 mr-2" /> Back
          </div>
          <p className="mt-10 text-xl">What's your business email?</p>
          <p className="text-xl font-bold mt-2">
            Enter your personal business email to get started with your free
            account
          </p>
          <div className="w-full my-10">
            <label htmlFor="email" className="inputLabel">
              Email
            </label>
            <input
              type="email"
              placeholder="Enter your Email ID"
              className="inputField mt-1 mb-2"
            />
            <Checkbox
              label="I have read and agree to the terms and conditions of the Link Headquarter Agency"
              checked={undefined}
              onChange={undefined}
            />
          </div>
          <button
            type="submit"
            className="btnPrimary py-3 px-6 bg-dark text-white"
          >
            Go
          </button>
        </form>
      </div>
    </div>
  );
}
