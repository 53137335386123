import React, { useState } from "react";

export default function FormStep5({ handlePrevious, handleNext }) {
  return (
    <div className="mt-12 grid lg:grid-cols-2 gap-5 lg:gap-16 w-full">
      <div className="text-center">
        <h1 className="text-5xl font-bold capitalize">
          Verify Your <span className="text-secondary">Email</span>
        </h1>
        <div className="flex flex-col items-center">
          <img src="/icons/email.png" alt="" className="w-24 my-7" />
          <p>We've sent a 4 digit verification code to your</p>
          <p className="text-lg text-secondary">alan@gmail.com</p>
          <p className="mt-10 mb-3 text-lg">
            Please enter to verification Account
          </p>
          <ConfirmationInput />
          <div className="flex items-center justify-center gap-2">
            <button
              onClick={handlePrevious}
              type="submit"
              className="btnPrimary py-3 px-6 mt-10"
            >
              Back
            </button>
            <button
              onClick={handleNext}
              type="submit"
              className="btnPrimary py-3 px-6 mt-10"
            >
              Verify
            </button>
          </div>
          <p className="mt-5">
            Didn’t gate the code?{" "}
            <span className="text-secondary cursor-pointer">Resend</span>
          </p>
        </div>
      </div>
      <div>
        <img src="/signup.png" alt="" className="mt-12 ml-auto lg:w-10/12" />
      </div>
    </div>
  );
}

function ConfirmationInput() {
  const [confirmationCode, setConfirmationCode] = useState("");

  const handleConfirmationCodeChange = (event) => {
    const newCode = event.target.value;
    setConfirmationCode(newCode);
  };

  return (
    <div className="grid grid-cols-4 gap-2">
      <input
        type="number"
        placeholder="__"
        inputMode="numeric"
        pattern="[0-9]*"
        maxLength="1"
        value={confirmationCode[0]}
        onChange={handleConfirmationCodeChange}
        className="border border-gray-300 appearance-none w-16 rounded-md p-2 text-center text-2xl focus:outline-none focus:border-primary"
      />
      <input
        type="number"
        placeholder="__"
        inputMode="numeric"
        pattern="[0-9]*"
        maxLength="1"
        value={confirmationCode[1]}
        onChange={handleConfirmationCodeChange}
        className="border border-gray-300 appearance-none w-16 rounded-md p-2 text-center text-2xl focus:outline-none focus:border-primary"
      />
      <input
        type="number"
        placeholder="__"
        inputMode="numeric"
        pattern="[0-9]*"
        maxLength="1"
        value={confirmationCode[2]}
        onChange={handleConfirmationCodeChange}
        className="border border-gray-300 appearance-none w-16 rounded-md p-2 text-center text-2xl focus:outline-none focus:border-primary"
      />
      <input
        type="number"
        placeholder="__"
        inputMode="numeric"
        pattern="[0-9]*"
        maxLength="1"
        value={confirmationCode[3]}
        onChange={handleConfirmationCodeChange}
        className="border border-gray-300 appearance-none w-16 rounded-md p-2 text-center text-2xl focus:outline-none focus:border-primary"
      />
    </div>
  );
}
