import {
  PlusCircleIcon,
  TrashIcon
} from "@heroicons/react/24/solid";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CheckBox, InputField, ToolTip } from "../../components/common";
import DataTable from "../../components/common/DataTable";
import { NicheArray, NicheType } from "../../constants/base.enum";
import { Cart } from "../../model/cart.model";
import {
  CartLinkSlice,
  removeFromCart
} from "../../store/slices/cartLinkSlice";
import { updateField } from "../../store/slices/userSlice";
import OrderSummary from "./OrderSummary";

export default function Order() {
  const dispatch = useDispatch();
  const cartLinks: CartLinkSlice[] = useSelector((state: any) => state.cartLink);

  const [cartList, setCartList] = useState<Cart[]>([]);

  useEffect(() => {
    const tempCartList: Cart[] = [];
    const list = cartLinks.map((link: CartLinkSlice) => {
      for (let i = 0; i < link.quantity; i++) {
        tempCartList.push({
          backlinkId: link.id,
          publication: link.publication,
          price: link.price,
          customerId: "",
          restricted: "",
          articleWord: 500,
          contentByLink: false
        });
      }
    })
    setCartList(tempCartList);
    dispatch(updateField({ carts: tempCartList }));
  }, cartLinks);


  const handleRemove = (itemId: number) => {
    dispatch(removeFromCart(itemId));
  };

  const handleChange = (value: any, index: number, field: string) => {
    const tempCartList = [...cartList];
    switch (field) {
      case 'customerId':
        tempCartList[index].customerId = value;
        break;
      case 'restrictedNiche':
        tempCartList[index].restricted = value;
        const link = cartLinks.find((link: CartLinkSlice) => (link.id === tempCartList[index].backlinkId));
        if (!!link && !!value) {
          var nicheValue: number = 0.0;
          switch (value) {
            case NicheType.Casino:
              if (!!link.casino)
                nicheValue = link.casino;
              break;
            case NicheType.Loan:
              if (!!link.loan)
                nicheValue = link.loan;
              break;
            case NicheType.Erotic:
              if (!!link.erotic)
                nicheValue = link.erotic;
              break;
            case NicheType.Dating:
              if (!!link.dating)
                nicheValue = link.dating;
              break;
            case NicheType.Cbd:
              if (!!link.cbd)
                nicheValue = link.cbd;
              break;
            case NicheType.Crypto:
              if (!!link.crypto)
                nicheValue = link.crypto;
              break;
          }
          tempCartList[index].price = nicheValue * link.price;
        }
        break;
      case 'articleWord':
        tempCartList[index].articleWord = !!value ? parseInt(value) : 0;
        break;
      case 'contentByLink':
        tempCartList[index].contentByLink = value;
        break;
      default:
        break;
    }
    setCartList(tempCartList);
    dispatch(updateField({ carts: tempCartList }));
  }

  const isAvailableNiche = (cart: Cart, niche: NicheType) => {
    const link = cartLinks.find((link) => link.id === cart.backlinkId);
    switch (niche) {
      case NicheType.Casino:
        if (link?.casino) {
          return false;
        }
        break;
      case NicheType.Loan:
        if (!!link?.loan) {
          return false;
        }
        break;
      case NicheType.Erotic:
        if (!!link?.erotic) { return false; }
        break;
      case NicheType.Dating:
        if (!!link?.dating) { return false; }
        break;
      case NicheType.Cbd:
        if (!!link?.cbd) { return false; }
        break;
      case NicheType.Crypto:
        if (!!link?.crypto) { return false; }
        break;
    }
    return true;
  }

  return (
    <div className="grid gap-5">
      <OrderSummary />
      <div className="overflow-x-hidden rounded-lg bg-white shadow-lg pb-3">
        <div className="flex items-center justify-between px-4 pt-4">
          <h1 className="text-2xl font-bold my-3">Your Order</h1>
          <Link
            to="/dashboard/marketplace"
            className="btnPrimary p-2 text-white font-normal"
          >
            <PlusCircleIcon className="w-5" />
            <span className="hidden md:block"> Add more backlink sites</span>
          </Link>
        </div>
        <DataTable
          className="mt-0 py-0"
          heads={[
            "Sr#",
            "media",
            <ToolTip
              tool="custom id"
              tip="custom id tool tip"
            />,
            <ToolTip
              tool="restricted niche"
              tip="restricted niche tool tip" />,
            <ToolTip
              tool="article word count"
              tip="article word count tool tip" />,
            <ToolTip
              tool="content Inlcuded count"
              tip="content Inlcuded count tool tip" />,
            <ToolTip tool="price" tip="price tool tip" />,
            "action",
          ]}
          items={cartList.map((cart: Cart, index: number) => ({
            serialNumber: index + 1,
            media: cart.publication,
            customId: <InputField placeholder="custom id" className="py-2" value={cart.customerId} onChange={(e) => handleChange(e.target.value, index, 'customerId')} />,
            restricted_niche: (
              <FormControl margin="normal" sx={{ minWidth: 200 }}>
                <InputLabel id="label-bundle">Restricted Niche</InputLabel>
                <Select
                  labelId="demo-simple-select-label-bundle"
                  id="demo-simple-select-bundle"
                  value={cart.restricted}
                  label="Restricted Niche"
                  onChange={(e: SelectChangeEvent) => handleChange(e.target.value, index, 'restrictedNiche')}
                >
                  {
                    NicheArray.map((niche) =>
                      <MenuItem key={`bundle-item-` + niche}
                        disabled={
                          isAvailableNiche(cart, niche)
                        }
                        value={niche}
                      >
                        {niche}
                      </MenuItem>)
                  }
                </Select>
              </FormControl>
            ),
            article_word_count: (
              <InputField placeholder="Article word count" className="py-2" value={cart.articleWord === 0 ? '' : cart.articleWord} onChange={(e) => handleChange(e.target.value, index, 'articleWord')} />
            ),
            content: (
              <div className="flex justify-center w-full">
                <CheckBox label={"500 Included"} onChange={(e) => handleChange(e.target.checked, index, 'contentByLink')} />
              </div>
            ),
            price: `$${!!cart.price ? cart.price : "0"}`,
            action: (
              <TrashIcon
                onClick={() => handleRemove(cart.id as number)}
                className="w-5 text-red-500" />
            ),
          }))}
          tableHeight={0}
          isLoading={false}
        />
      </div>
    </div>
  );
}
