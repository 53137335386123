export enum SettingOption {
  Category = "category",
  Vendor = "vendor",
  OrderStatus = "orderStatus"
}

export enum RoleType {
  SuperAdmin = "super-admin",
  AgencyAdmin = "agency-admin",
  MerchantAdmin = "merchant-admin"
}

export enum PermissionEnum {
  Settings = "Settings",
  CreateUser = "create_user",
  ViewUser = "view_user",
  EditUser = "edit_user",
  DeleteUser = "delete_user",

  CreateRole = "create_role",
  ViewRole = "view_role",
  EditRole = "edit_role",
  DeleteRole = "delete_role",

  CreateVendor = "create_vendor",
  ViewVendor = "view_vendor",
  EditVendor = "edit_vendor",
  DeleteVendor = "delete_vendor",

  CreateCategory = "create_category",
  ViewCategory = "view_category",
  EditCategory = "edit_category",
  DeleteCategory = "delete_category",

  CreateImprtLog = "create_ImprtLog",
  ViewImprtLog = "view_ImprtLog",
  EditImprtLog = "edit_ImprtLog",
  DeleteImprtLog = "delete_ImprtLog",

  CreatePriceTier = "create_priceTier",
  ViewPriceTier = "view_priceTier",
  EditPriceTier = "edit_priceTier",
  DeletePriceTier = "delete_priceTier",

  CreateMerchant = "create_merchant",
  ViewMerchant = "view_merchant",
  EditMerchant = "edit_merchant",
  DeleteMerchant = "delete_merchant",

  CreateOrderStatus = "create_orderStatus",
  ViewOrderStatus = "view_orderStatus",
  EditOrderStatus = "edit_orderStatus",
  DeleteOrderStatus = "delete_orderStatus",

  CreateTerms = "create_terms",
  ViewTerms = "view_terms",
  EditTerms = "edit_terms",
  DeleteTerms = "delete_terms",

  CreatePaymentType = "create_paymentType",
  ViewPaymentType = "view_paymentType",
  EditPaymentType = "edit_paymentType",
  DeletePaymentType = "delete_paymentType",

  SwitchMerchant = "switchMerchant",

  Backlinks = "Backlinks",

  Master = "Master",
  CreateBacklink = "create_backlink",
  ViewBacklink = "view_backlink",
  EditBacklink = "edit_backlink",
  DeleteBacklink = "delete_backlink",

  CreateOrderBundle = "create_OrderBundle",
  ViewOrderBundle = "view_OrderBundle",
  EditOrderBundle = "edit_OrderBundle",
  DeleteOrderBundle = "delete_OrderBundle",

  CreateMerchantGroup = "create_MerchantGroup",
  ViewMerchantGroup = "view_MerchantGroup",
  EditMerchantGroup = "edit_MerchantGroup",
  DeleteMerchantGroup = "delete_MerchantGroup",

  Orders = "Orders",
  ChangeOrderStatus = "Super Admin Change Order Status",

  Quotes = "Quotes",
  ChagneQuoteStatus = "changeQuoteStatus",
  MyQutoes = "myQuotes",

  MyOrders = "myOrders",
  MarketPlace = "MarketPlace"
}

export enum ImportTestResult {
  Pass = "PASS",
  Fail = "FAIL"
}


export enum PriceTierType {
  PercentageAboveCost = "Percentage above cost",
  FixedAboveCost = "Fixed above cost",
  PercentageDiscountFromRetail = "Percentage discount from retail",
  FixedFromCost = "FixedFromCost"
}

export enum ActiveStatus {
  Active = "Active",
  Inactive = "Inactive"
}

export enum MerchantStatus {
  Pending = "Pending",
  Active = "Active",
  Inactive = "Inactive"
}

export enum MerchantCreditCardType {
  CreditCardNumber = "Credit Card Number",
  Experation = "Experation",
  CVVCode = "CVV Code",
  BillingZipCode = "Billing Zip Code"
}

export enum MerchantBusinessType {
  Agency = "Agency",
  Media = "Media",
  Affiliate = "Affiliate",
  Brand = "Brand/Ecommerce",
  Operator = "Operator"
}

export enum MerchantBuyingType {
  Yes = "Yes",
  No = "No"
}

export enum DocumentType {
  TermsAndCondition = "Terms and Condition",
  Privacy = "Privacy"
}

export enum NicheType {
  Casino = "Casino",
  Loan = "Loan",
  Erotic = "Erotic",
  Dating = "Dating",
  Cbd = "Cbd",
  Crypto = "Crypto"
}

export const NicheArray = [NicheType.Casino, NicheType.Loan, NicheType.Erotic, NicheType.Dating, NicheType.Cbd, NicheType.Crypto];