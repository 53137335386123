import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import React from "react";

interface DataTableProps {
  heads?: any[],
  items?: any[],
  className: string | "",
  tableHeight: number,
  isLoading: boolean,
  totalRecords?: number,
  startIndex?: number,
  lastIndex?: number,
  toNextPage?: () => void,
  toPreviousPage?: () => void,
  toFirstPage?: () => void,
  toLastPage?: () => void,
  nextPageDisabled?: boolean,
  previousPageDisabled?: boolean
}

const DataTable = ({
  heads,
  items,
  className,
  tableHeight,
  isLoading,
  totalRecords,
  startIndex,
  lastIndex,
  toNextPage,
  toPreviousPage,
  toFirstPage,
  toLastPage,
  nextPageDisabled,
  previousPageDisabled,
} : DataTableProps) => {
  return (
    <div className="w-full">
      <div className={`rounded w-full bg-white mt-4 p-3 ${className}`}>
        <div
          className={`w-full overflow-y-scroll scroll-container ${
            totalRecords ? "h-[calc(100vh-270px)]" : "h-[calc(100vh-230px)]"
          } ${tableHeight}`}
        >
          <table className="min-w-full divide-y divide-gray-200 ">
            <thead className="bg-gray-100 backdrop-blur-md sticky top-0 z-10 shadow-sm border-b-2 border-gray-300">
              <tr>
                {heads?.map((head, key) => (
                  <th key={key} scope="col" className="tableHead">
                    {head as string}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200 h-full overflow-y-scroll">
              {items?.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  {Object.values(item).map((value, key) => (
                    <td className="py-2 px-3" key={key}>
                      <div className="text-sm whitespace-nowrap px-3">
                        {value as any}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {isLoading && (
            <div className="flex items-center justify-center py-10 w-full">
              <img src="/img/loading.gif" className="w-16" alt="" />
            </div>
          )}
        </div>
      </div>
      {totalRecords && (
        <div className="flex flex-col md:flex-row items-center justify-between my-3">
          <div className="hidden md:flex item-center">
            Total Records: {totalRecords}
          </div>
          {startIndex && (
            <div className="flex items-center justify-end gap-2">
              {startIndex} - {lastIndex} of {totalRecords}
              {toFirstPage && (
                <button
                  type="button"
                  className="pagination ml-5"
                  onClick={toFirstPage}
                  disabled={previousPageDisabled}
                >
                  <FirstPage />
                </button>
              )}
              {toPreviousPage && (
                <button
                  type="button"
                  className="pagination"
                  onClick={toPreviousPage}
                  disabled={previousPageDisabled}
                >
                  <KeyboardArrowLeft />
                </button>
              )}
              {toNextPage && (
                <button
                  type="button"
                  className="pagination"
                  onClick={toNextPage}
                  disabled={nextPageDisabled}
                >
                  <KeyboardArrowRight />
                </button>
              )}
              {toLastPage && (
                <button
                  type="button"
                  className="pagination"
                  onClick={toLastPage}
                  disabled={nextPageDisabled}
                >
                  <LastPage />
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DataTable;
