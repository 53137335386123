import { PermissionEnum } from "../../constants/base.enum";

export interface RenderTree {
  id: string;
  name: string;
  children?: RenderTree[];
}

export const permissionList: RenderTree = {
  id: "0",
  name: "All",
  children: [
    {
      id: "1",
      name: PermissionEnum.Orders,
      children: [
        {
          id: "2",
          name: PermissionEnum.ChangeOrderStatus
        },
        {
          id: "65",
          name: PermissionEnum.MyOrders
        }
      ]
    },
    {
      id: "3",
      name: PermissionEnum.Backlinks,
      children: [
        {
          id: "4",
          name: "Master",
          children: [
            {
              id: "5",
              name: PermissionEnum.CreateBacklink
            },
            {
              id: "6",
              name: PermissionEnum.ViewBacklink
            },
            {
              id: "7",
              name: PermissionEnum.EditBacklink
            },
            {
              id: "8",
              name: PermissionEnum.DeleteBacklink
            }
          ]
        },
        {
          id: "9",
          name: "OrderMerchant",
          children: [
            {
              id: "10",
              name: PermissionEnum.CreateMerchantGroup
            },
            {
              id: "11",
              name: PermissionEnum.ViewMerchantGroup
            },
            {
              id: "12",
              name: PermissionEnum.EditMerchantGroup
            },
            {
              id: "13",
              name: PermissionEnum.DeleteMerchantGroup
            }
          ]
        },
        {
          id: "14",
          name: "GroupBundle",
          children: [
            {
              id: "15",
              name: PermissionEnum.CreateOrderBundle
            },
            {
              id: "16",
              name: PermissionEnum.ViewOrderBundle
            },
            {
              id: "17",
              name: PermissionEnum.EditOrderBundle
            },
            {
              id: "18",
              name: PermissionEnum.DeleteOrderBundle
            }
          ]
        }
      ]
    },
    {
      id: "19",
      name: PermissionEnum.Settings,
      children: [
        {
          id: "20",
          name: "Vendor",
          children: [
            {
              id: "21",
              name: PermissionEnum.CreateVendor
            },
            {
              id: "22",
              name: PermissionEnum.ViewVendor
            },
            {
              id: "23",
              name: PermissionEnum.EditVendor
            },
            {
              id: "24",
              name: PermissionEnum.DeleteVendor
            }
          ]
        },
        {
          id: "30",
          name: "User",
          children: [
            {
              id: "31",
              name: PermissionEnum.CreateUser
            },
            {
              id: "32",
              name: PermissionEnum.ViewUser
            },
            {
              id: "33",
              name: PermissionEnum.EditUser
            },
            {
              id: "34",
              name: PermissionEnum.DeleteUser
            }
          ]
        },
        {
          id: "35",
          name: "Role",
          children: [
            {
              id: "36",
              name: PermissionEnum.CreateRole
            },
            {
              id: "37",
              name: PermissionEnum.ViewRole
            },
            {
              id: "38",
              name: PermissionEnum.EditRole
            },
            {
              id: "39",
              name: PermissionEnum.DeleteRole
            }
          ]
        },
        {
          id: "40",
          name: "Category",
          children: [
            {
              id: "41",
              name: PermissionEnum.CreateCategory
            },
            {
              id: "42",
              name: PermissionEnum.ViewCategory
            },
            {
              id: "43",
              name: PermissionEnum.EditCategory
            },
            {
              id: "44",
              name: PermissionEnum.DeleteCategory
            }
          ]
        },
        {
          id: "45",
          name: "ImportLog",
          children: [
            {
              id: "46",
              name: PermissionEnum.ViewImprtLog
            }
          ]
        },
        {
          id: "47",
          name: "Merchant",
          children: [
            {
              id: "48",
              name: PermissionEnum.CreateMerchant
            },
            {
              id: "49",
              name: PermissionEnum.ViewMerchant
            },
            {
              id: "50",
              name: PermissionEnum.EditMerchant
            },
            {
              id: "51",
              name: PermissionEnum.DeleteMerchant
            }
          ]
        },
        {
          id: "57",
          name: PermissionEnum.SwitchMerchant
        },
        {
          id: "58",
          name: "PriceTier",
          children: [
            {
              id: "59",
              name: PermissionEnum.CreatePriceTier
            },
            {
              id: "60",
              name: PermissionEnum.ViewPriceTier
            },
            {
              id: "61",
              name: PermissionEnum.EditPriceTier
            },
            {
              id: "62",
              name: PermissionEnum.DeletePriceTier
            }
          ]
        },
        {
          id: "69",
          name: "OrderStatus",
          children: [
            {
              id: "70",
              name: PermissionEnum.CreateOrderStatus
            },
            {
              id: "71",
              name: PermissionEnum.ViewOrderStatus
            },
            {
              id: "72",
              name: PermissionEnum.EditOrderStatus
            },
            {
              id: "73",
              name: PermissionEnum.DeleteOrderStatus
            }
          ]
        },
        {
          id: "74",
          name: "Terms",
          children: [
            {
              id: "75",
              name: PermissionEnum.CreateTerms
            },
            {
              id: "76",
              name: PermissionEnum.ViewTerms
            },
            {
              id: "77",
              name: PermissionEnum.EditTerms
            },
            {
              id: "78",
              name: PermissionEnum.DeleteTerms
            }
          ]
        },
        {
          id: "79",
          name: "PaymentType",
          children: [
            {
              id: "80",
              name: PermissionEnum.CreatePaymentType
            },
            {
              id: "81",
              name: PermissionEnum.ViewPaymentType
            },
            {
              id: "82",
              name: PermissionEnum.EditPaymentType
            },
            {
              id: "83",
              name: PermissionEnum.DeletePaymentType
            }
          ]
        },
      ]
    },
    {
      id: "63",
      name: "MerchantType",
      children: [
        {
          id: "64",
          name: PermissionEnum.MarketPlace
        },
      ]
    },
    {
      id: "66",
      name: PermissionEnum.Quotes,
      children: [
        {
          id: "67",
          name: PermissionEnum.ChagneQuoteStatus
        },
        {
          id: "84",
          name: PermissionEnum.MyQutoes
        },
      ]
    },
  ]
};