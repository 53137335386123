import { useEffect, useState } from "react";
import {
  HiBookOpen, HiBriefcase, HiChatAlt2, HiCog, HiCollection, HiCube, HiCurrencyDollar, HiDocumentDuplicate, HiDocumentReport, HiDocumentText, HiFire, HiShoppingBag, HiUserCircle,
  HiUserGroup,
  HiUsers,
  HiViewGrid,
  HiCake
} from "react-icons/hi";
import { PermissionEnum, RoleType } from "../../constants/base.enum";
import { useOrderStatusCount } from "../../hooks/orderHooks";
import { useAppSelector } from "../../hooks/rtkHooks";
import { MenuItem } from "../../model/side-menu.model";

const useSideMenu = () => {
  const user = useAppSelector((state) => state.user.user);

  const [sideMenu, setSideMenu] = useState<Array<MenuItem>>([]);
  const { orderStatusOptions } = useAppSelector((state) => state.settings);
  const { data: orStatusCntData } = useOrderStatusCount({userId: user.roles[0].type === RoleType.SuperAdmin ? 0 : user.id});
  const [myOrderCount, setMyOrderCount] = useState(0);
  const [myQuoteCount, setMyQuoteCount] = useState(0);

  useEffect(() => {
    const defaultSideMenu: Array<MenuItem> = [
      {
        to: "/dashboard",
        label: "Dashboard",
        Icon: HiViewGrid,
      },
      {
        to: "/dashboard/marketplace",
        label: "Marketplace",
        Icon: HiShoppingBag,
        permission: PermissionEnum.MarketPlace,
      },
      {
        to: "/dashboard/quotes",
        label: "Quotes",
        Icon: HiDocumentDuplicate,
        permission: PermissionEnum.Quotes,
        children: [
          {
            to: "/dashboard/quotes",
            label: "All Quotes",
            Icon: HiDocumentReport,
            permission: PermissionEnum.ChagneQuoteStatus
          },
          {
            to: "/dashboard/quotes/my-quotes",
            label: "My Quotes",
            Icon: HiDocumentDuplicate,
            background: "#003FFC",
            value: myQuoteCount,
            permission: PermissionEnum.MyQutoes
          },
        ],
      },
      {
        to: "/dashboard/orders",
        label: "orders",
        Icon: HiDocumentReport,
        permission: PermissionEnum.Orders,
        children: [
          {
            to: "/dashboard/orders/my-orders",
            label: "My Orders",
            Icon: HiDocumentText,
            background: "#FC3F00",
            value: myOrderCount,
            permission: PermissionEnum.MyOrders
          },
        ],
      },
      {
        to: "/dashboard/backlinks",
        label: "Backlinks",
        Icon: HiUsers,
        permission: PermissionEnum.Backlinks,
        children: [
          {
            to: "/dashboard/backlinks",
            label: "Master",
            Icon: HiBookOpen,
            permission: PermissionEnum.Master
          },
          {
            to: "/dashboard/backlinks/bundles",
            label: "Order Bundles",
            Icon: HiChatAlt2,
            permission: PermissionEnum.ViewOrderBundle
          },
          {
            to: "/dashboard/backlinks/groups",
            label: "Merchant Groups",
            Icon: HiCollection,
            permission: PermissionEnum.ViewMerchantGroup
          },
        ],
      },
      {
        to: "/dashboard/settings",
        label: "Settings",
        Icon: HiCog,
        permission: PermissionEnum.Settings,
        children: [
          {
            to: "/dashboard/settings/users",
            label: "Users",
            Icon: HiUserGroup,
            permission: PermissionEnum.ViewUser
          },
          {
            to: "/dashboard/settings/roles",
            label: "Roles",
            Icon: HiUserCircle,
            permission: PermissionEnum.ViewRole
          },
          {
            to: "/dashboard/settings/vendor",
            label: "Vendor",
            Icon: HiCube,
            permission: PermissionEnum.ViewVendor,
          },
          {
            to: "/dashboard/settings/category",
            label: "Category",
            Icon: HiBookOpen,
            permission: PermissionEnum.ViewCategory,
          },
          {
            to: "/dashboard/settings/importLog",
            label: "Reports Import Log",
            Icon: HiDocumentReport,
            permission: PermissionEnum.ViewImprtLog
          },
          {
            to: "/dashboard/settings/priceTiers",
            label: "Price Tiers",
            Icon: HiCurrencyDollar,
            permission: PermissionEnum.ViewPriceTier
          },
          {
            to: "/dashboard/settings/merchant",
            label: "Merchant",
            Icon: HiFire,
            permission: PermissionEnum.ViewMerchant
          },
          {
            to: "/dashboard/settings/orderStatus",
            label: "OrderStatus",
            Icon: HiCollection,
            permission: PermissionEnum.ViewOrderStatus
          },
          {
            to: "/dashboard/settings/terms",
            label: "Terms",
            Icon: HiCollection,
            permission: PermissionEnum.ViewTerms
          },
          {
            to: "/dashboard/settings/paymentType",
            label: "PaymentType",
            Icon: HiCollection,
            permission: PermissionEnum.ViewPaymentType
          },
          {
            to: "/dashboard/settings/switchMerchant",
            label: "Switch Merchant",
            Icon: HiBriefcase,
            permission: PermissionEnum.SwitchMerchant
          },
          {
            to: "/dashboard/account-settings",
            label: "Account Settings",
            Icon: HiUserCircle,
          },
        ],
      },
    ];

    let totalOrderCount: number = 0;
    let totalQutoeCount: number = 0;
    if (!!orStatusCntData?.data) {
      orStatusCntData?.data.forEach((cntData) => {
        if(cntData.isQuote)
          totalQutoeCount = Number(totalQutoeCount + Number(cntData.count));
        else
          totalOrderCount = Number(totalOrderCount + Number(cntData.count));
      })
    }
    setMyOrderCount(totalOrderCount);
    setMyQuoteCount(totalQutoeCount);
    const orderStatusChild: Array<MenuItem> = orderStatusOptions?.map((orderStatus) => ({
      to: `/dashboard/orders/${orderStatus.name}`,
      label: orderStatus.name,
      Icon: HiBriefcase,
      value: orStatusCntData?.data?.find((cntData) => (cntData.orderStatusId === orderStatus.id))?.count || 0,
      // permission: PermissionEnum.ChangeOrderStatus
    })) || [];

    const newSideMenu = defaultSideMenu.map((sideMenu) => {
      if (sideMenu.label === "orders") {
        const processingMenu: MenuItem = {
          to: "/dashboard/orders/order-processing",
          label: "Order Processing",
          Icon: HiCake,
          // permission: PermissionEnum.ChangeOrderStatus,
          children: [
            {
            to: "/dashboard/orders",
            label: "All Orders",
            value: totalOrderCount,
            background: "#FC3F33",
            Icon: HiDocumentReport,
            // permission: PermissionEnum.ChangeOrderStatus
          }
        ]
      };
      if (!!processingMenu.children)
        processingMenu.children = [...processingMenu.children, ...orderStatusChild];
      if (!!sideMenu.children)
        sideMenu.children.push(processingMenu);
        else
          sideMenu.children = orderStatusChild;
      }
      return sideMenu;
    })
    setSideMenu(newSideMenu);
  }, [orderStatusOptions, orStatusCntData, myOrderCount, myQuoteCount]);
  return {
    data: sideMenu
  }
}

export default useSideMenu;