import { BuildingStorefrontIcon } from "@heroicons/react/20/solid";
import { TagIcon, UsersIcon } from "@heroicons/react/24/solid";
import React from "react";

export default function Admins() {
  return (
    <div className="grid grid-cols-4 gap-6 md:gap-5 mt-6">
      <div className="card">
        <div className="rounded-full p-2 bg-secondary w-fit">
          <UsersIcon className="w-5" />
        </div>
        <p className="font-bold text-4xl mt-3">04</p>
        <p className="mt-1 text-gray-500 text-xs font-semibold">Super Admins</p>
      </div>
      <div className="card">
        <div className="rounded-full p-2 bg-secondary w-fit">
          <BuildingStorefrontIcon className="w-5" />
        </div>
        <p className="font-bold text-4xl mt-3">10</p>
        <p className="mt-1 text-gray-500 text-xs font-semibold">
          Merchant Admins
        </p>
      </div>
      <div className="card">
        <div className="rounded-full p-2 bg-secondary w-fit">
          <BuildingStorefrontIcon className="w-5" />
        </div>
        <p className="font-bold text-4xl mt-3">30</p>
        <p className="mt-1 text-gray-500 text-xs font-semibold">
          Merchant Admins
        </p>
      </div>
      <div className="card">
        <div className="rounded-full p-2 bg-secondary w-fit">
          <TagIcon className="w-6" />
        </div>
        <p className="font-bold text-4xl mt-3">100</p>
        <p className="mt-1 text-gray-500 text-xs font-semibold">
          Whitelable Admins
        </p>
      </div>
    </div>
  );
}
