import React from "react";
import { Link } from "react-router-dom";

export default function GetStarted() {
  return (
    <div className="py-16 flex items-center justify-center bg-getStarted">
      <div className="w-10/12">
        <h2 className="text-4xl font-bold">Get Started Today!</h2>
        <p className="mt-5 text-lg">100% Risk-Free | No Monthly Fees</p>
        <div className="flex items-center mt-10 gap-3">
          <Link
            to="/register"
            className="btnPrimary bg-dark border-2 border-dark shadow-none text-white py-3 px-7"
          >
            Get free access
          </Link>
          <button className="btnPrimary border-2 border-dark shadow-none py-3 px-7">
            Contact Sales
          </button>
        </div>
      </div>
    </div>
  );
}
