import React from "react";
import { Link } from "react-router-dom";

const data = [
  { title: "Vender 1", value: "10,767" },
  { title: "Vender 2", value: "48" },
  { title: "Vender 3", value: "87" },
  { title: "Vender 4", value: "38,66" },
];

export default function BacklinkTopVendors() {
  return (
    <div className="card justify-between">
      <div className="w-full">
        <div className="flex items-center justify-between">
          <p className="text-xl font-bold">Backlink top Venders</p>
          <div className="flex items-center justify-end gap-3">
            <p className="rounded bg-gray-200 py-1 px-5 font-semibold">4</p>
            <Link to="/" className="btnPrimary py-1">
              See All
            </Link>
          </div>
        </div>
        <div className="w-full mt-2">
          {data.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-between border-b border-dashed border-gray-300 py-2"
            >
              <span>{item.title}</span>
              <span>{item.value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
