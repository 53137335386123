import React from "react";
import { Link } from "react-router-dom";

const data = [
  {
    title: "Starter Bundle",
    totalLink: "5 Links",
    cost: "$500/month",
    link: "",
  },
  {
    title: "Pro Bundle",
    totalLink: "10 Links",
    cost: "$1000/month",
    link: "",
  },
  {
    title: "Max Bundle",
    totalLink: "50 Links",
    cost: "$2500/month",
    link: "",
  },
  {
    title: "Elite Bundle",
    totalLink: "100 Links",
    cost: "$100/month",
    link: "",
  },
];

export default function BacklinkBundles() {
  return (
    <div className="card justify-between">
      <div className="w-full">
        <div className="flex items-center justify-between w-full pb-4 border-b">
          <p className="text-xl font-bold">Backlink Bundles</p>
          <div className="flex items-center justify-end gap-3">
            <button className="btnPrimary bg-dark text-white py-1">Custom Bundle</button>
            <Link to="/" className="btnPrimary py-1">
              See All
            </Link>
          </div>
        </div>
        <table className="w-full mt-2">
          <tbody>
            {data.map((item, index) => (
              <tr
                key={index}
                className="flex items-center justify-between py-1"
              >
                <td>{item.title}</td>
                <td>{item.totalLink}</td>
                <td>{item.cost}</td>
                <td className="font-semibold text-blue-900 underline cursor-pointer">
                  View
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
