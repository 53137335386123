import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "./Dropdown";
import useSideMenu from "./Menu";

const Topbar = ({ show_navlabel }) => {
  const { data: sideMenu } = useSideMenu();
  const [activeMenuItem, setActiveMenuItem] = useState("Dashboard");

  //to keep track of the active menu item even page refreshes
  useEffect(() => {
    const path = window.location.pathname;
    const label = sideMenu.find((item) => item?.to === path);
    if (label) {
      setActiveMenuItem(label.label);
    }
  }, []);

  return (
    <div className="hidden md:flex items-center justify-center">
      {sideMenu.map((item, key) => {
        const { label, to, Icon, children } = item;
        const isActive = activeMenuItem === item.label;
        return (
          <div key={key} className="sidebar-item">
            {item?.children ? (
              <Dropdown
                show_navlabel={show_navlabel}
                label={label}
                activeMenuItem={activeMenuItem}
                setActiveMenuItem={setActiveMenuItem}
                to={to}
                Icon={Icon}
                options={children}
              />
            ) : (
              <Link
                className={`pageLink px-4 hover:text-white hover:bg-primary/20 ${isActive && "bg-secondary"
                  }`}
                title={label}
                to={to}
                onClick={() => setActiveMenuItem(item.label)}
              >
                <Icon />
                <span className="ml-1">{label}</span>
              </Link>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Topbar;
