import { useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { ChartData } from "./chartData";

export default function BarChart({ title }) {
  const [chartData, setChartData] = useState({
    labels: ChartData.map((data) => data.name),
    datasets: [
      {
        label: "Traffic Sources",
        data: ChartData.map((data) => data.percentage),
        backgroundColor: "#FFBE00",
        fill: "origin",
      },
    ],
  });

  return <Bar className="w-full mt-2 h-52" data={chartData} />;
}
