import React, { useState } from "react";
import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";
import FormStep3 from "./FormStep3";
import FormStep4 from "./FormStep4";
import FormStep5 from "./FormStep5";
import FormStep6 from "./FormStep6";
import { Link } from "react-router-dom";

const Form: React.FC = () => {
  const [step, setStep] = useState(1);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleFormSubmit = (data: FormData) => {};

  const renderStep = (step: number) => {
    switch (step) {
      case 1:
        return <FormStep1 handleNext={handleNext} />;
      case 2:
        return (
          <FormStep2 handlePrevious={handlePrevious} handleNext={handleNext} />
        );
      case 3:
        return (
          <FormStep3 handlePrevious={handlePrevious} handleNext={handleNext} />
        );
      case 4:
        return (
          <FormStep4 handlePrevious={handlePrevious} handleNext={handleNext} />
        );
      case 5:
        return (
          <FormStep5 handlePrevious={handlePrevious} handleNext={handleNext} />
        );
      case 6:
        return (
          <FormStep6
            handlePrevious={handlePrevious}
            handleFormSubmit={handleFormSubmit}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="bg-dark flex items-center justify-center flex-col">
      <div className="w-10/12">
        <Link to="/">
          <img src="/logoWhite.png" alt="" className="h-16 mt-12" />
        </Link>
        {renderStep(step)}
      </div>
    </div>
  );
};

export default Form;
