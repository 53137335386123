import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Alert, Button, Snackbar } from "@mui/material";
import {
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarExport
} from "@mui/x-data-grid";
import {
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton
} from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

export interface EditToolbarProps {
  rowModesModel: GridRowModesModel;
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disable: boolean;
  defaultValues: {
    [key: string]: any
  };
}

export default function EditToolbar(props: EditToolbarProps) {
  const {
    rowModesModel,
    setRows,
    setRowModesModel,
    handleFileUpload,
    disable,
  } = props;

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleClick = () => {
    // check if there is any unsaved rows before adding new row
    if (
      Object.values(rowModesModel).filter(
        (modeModel) => modeModel.mode === GridRowModes.Edit
      ).length > 0
    ) {
      setOpenSnackbar(true);
      return;
    }

    // add new row
    const id = new Date().getTime() % 100000000;
    setRows((oldRows) => [{ id, isNew: true, ...props.defaultValues }, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "backlink" },
    }));
  };

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <GridToolbarContainer className="flex items-center space-x-1 pb-5">      
      {!!props.defaultValues?.showAddButton && <Button
        variant="text"
        onClick={handleClick}
        startIcon={<AddIcon />}
        sx={{ fontSize: "0.8125rem" }}
        disabled={disable}
        className="text-black btnSecondary"
      >
        Add{" "}
        {(currentPath === "/dashboard/backlinks" && "Backlink") ||
          (currentPath === "/dashboard/settings/users" && "User")}
      </Button>}
      <GridToolbarExport
        disabled={disable}
        className="text-black btnSecondary"
      />
      <GridToolbarColumnsButton className="text-black btnSecondary" />
      <GridToolbarFilterButton className="text-black btnSecondary" />
      <GridToolbarDensitySelector className="text-black btnSecondary" />
      {!!props.defaultValues?.showUpload && <Button
        variant="text"
        startIcon={<FileUploadIcon />}
        component="label"
        sx={{ fontSize: "0.8125rem" }}
        disabled={disable}
        className="text-black btnSecondary"
      >
        Upload
        <input
          hidden
          accept=".csv"
          multiple={false}
          type="file"
          onChange={handleFileUpload}
        />
      </Button>}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="error">Save all rows before adding a new row.</Alert>
      </Snackbar>
    </GridToolbarContainer>
  );
}
