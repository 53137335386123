import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import AuthContainer from "../../components/AuthContainer";
import InputField from "../../components/common/InputField";
import { useAppDispatch, useAppSelector } from "../../hooks/rtkHooks";
import { LoginUserProps } from "../../model/user.model";
import { clearState, loginUser } from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { isError, errorMessage } = useAppSelector((state) => state.user);

  const { control, handleSubmit } = useForm<LoginUserProps>();

  const onSubmit = (data: LoginUserProps) => {
    dispatch(loginUser(data));
  };

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
      dispatch(clearState());
    }
  }, [dispatch, errorMessage, isError]);

  return (
    <AuthContainer>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-4">
        <Controller
          control={control}
          name="email"
          defaultValue=""
          render={({ field: { onChange, value, onBlur } }) => (
            <InputField
              name="username"
              label="Username"
              type="username"
              placeholder="Email address"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          defaultValue=""
          render={({ field: { onChange, value, onBlur } }) => (
            <InputField
              name="password"
              label="Password"
              type="password"
              placeholder="Enter password"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
        <p className="text-yellow-500 text-sm mt-3 text-end underline hover:text-yellow-600">
          Forgot Password?
        </p>
        <button
          className="btnPrimary w-full mt-5 shadow-secondary/50 shadow-lg"
          type="submit"
        >
          Login Now
        </button>
        <span className="py-3 grid grid-cols-or items-center text-center">
          <div className="bg-gray-200 h-[2px] w-full"></div>
          <p>or</p>
          <div className="bg-gray-200 h-[2px] w-full"></div>
        </span>
        <button
          className="btnPrimary w-full mt-5 border border-secondary bg-white shadow-none text-secondary"
          type="button"
          onClick={() => navigate("/register")}
        >
          Sign up
        </button>
      </form>
    </AuthContainer>
  );
}
