import React from "react";
import { ToolTip } from "../../components/common";

export const columns = [
  "Price",
  "media",
  "Vendor Code",
  "country",
  <ToolTip
    tool={
      <div className="flex flex-col items-center w-9">
        <img src="/ahref.png" alt="" /> DR
      </div>
    }
    tip={
      <>
        Ahrefs Domain Rating: <br />
        It's an indicator of the site strength. Domain rating is based on site's
        backilnk profile compared to other sites in Ahrefs database. The number
        you see is an average of the last 30 days.
      </>
    }
  />,
  <ToolTip
    tool={
      <div className="w-20 flex flex-col items-center">
        <img src="/semrush.png" alt="" /> AS
      </div>
    }
    tip={
      <>
        Semrush Authority Score: <br />
        It's an indicator of the site strength. Authority score is based on the
        site's backlink profile compared to other sites in Semrush's database.
      </>
    }
  />,
  <ToolTip
    tool={
      <div className="flex flex-col items-center w-10">
        <img src="/moz.png" className="w-8" alt="" />
        DA
      </div>
    }
    tip={
      <>
        Moz Domain Authority: <br />
        It's an indicator of the site strength. Domain Authority is based on
        site's backilnk profile compared to other sites in Moz's database.
      </>
    }
  />,
  <ToolTip
    tool={
      <div className="flex flex-col items-center px-3">
        <img src="/moz.png" className="w-8" alt="" />
        Spam Score
      </div>
    }
    tip={
      <>
        Spam Score is a Moz Indicator. <br />
        It represents the percentage of sites with similar to the current site
        which have been panalized or banned by google.
      </>
    }
  />,
  "create date",
  "monthly traffic",
  "referring domains",
  "tat (days)",
  <span className="text-center flex justify-center">niches</span>,
  "image",
  "indexed",
  "sponsored",
  "follow",
  "category",
  "publication samples",
  "notes",
];
