import { PhoneIcon } from "@heroicons/react/24/solid";
import React from "react";
import { Toaster } from "react-hot-toast";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import AboutUs from "../../views/Login/AboutUs";
import Editorials from "../../views/Login/Editorials";
import FAQS from "../../views/Login/FAQs";
import GetStarted from "../../views/Login/GetStarted";
import HowItWorks from "../../views/Login/HowItWorks";
import OurClients from "../../views/Login/OurClients";
import Testimonials from "../../views/Login/Testimonials";
import ContactForm from "../ContactForm";
import Footer from "../Footer";
import Header from "../Header";

const AuthContainer: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div className="bg-white">
      <div id="loginForm" className="flex w-full min-h-screen bg-white">
        <div className="grid grid-cols-login w-full">
          <div className="bg-login bg-cover bg-left-top lg:bg-right-top p-6 lg:px-16">
            <nav className="hidden lg:flex items-center">
              <img src="/logo.png" className="h-10" alt="" />
              <div className="flex items-center ml-7 font-semibold">
                {/* <Link
                  spy={true}
                  smooth={true}
                  to="loginForm"
                  className="pageLink px-4"
                >
                  Home
                </Link> */}
                <Link
                  spy={true}
                  smooth={true}
                  to="howItWorks"
                  className="pageLink px-4"
                >
                  How It Works
                </Link>
                <NavLink
                  to="/about-us"
                  className="pageLink px-4"
                >
                  About Us
                </NavLink>
                <Link
                  spy={true}
                  smooth={true}
                  to="FAQs"
                  className="pageLink px-4"
                >
                  FAQs
                </Link>
                <a href="tel:+1 (800) 542-2323" className="pageLink px-4 gap-1">
                  <PhoneIcon className="h-4" />
                  +1 (800) 542-2323
                </a>
              </div>
            </nav>
            <div className="flex flex-col justify-center mt-16 text-dark">
              <h1 className="text-5xl">
                <span className="font-extrabold">A Trusted Link Building</span>{" "}
                <br /> & PR Company
              </h1>
              <p className="mt-7 text-2xl">
                Premiere Editorial Backlinks That Make The Difference
              </p>
              <ul className="mt-7 text-lg list-disc list pl-5">
                <li>Establish Online Authority</li>
                <li>Improve Digital Presence</li>
                <li>Expert Editorial Team</li>
                <li>1000s Of High Ranking Domains</li>
                <li>Placements guaranteed or don't pay!</li>
              </ul>
              <h3 className="text-4xl text-white font-extrabold mt-10">
                Get Started Today!
              </h3>
              <div className="flex items-center mt-7 gap-3">
                <NavLink
                  to="/register"
                  className="btnPrimary bg-dark border-2 border-dark shadow-none text-white py-3 px-7"
                >
                  Get free access
                </NavLink>
                <button className="btnPrimary border-2 border-dark shadow-none py-3 px-7">
                  Contact Sales
                </button>
              </div>
            </div>
          </div>
          <div className="p-8 rounded-md">
            <img src="/logo.png" className="h-20 ml-auto mr-auto" alt="" />
            <div className="py-8 w-full">
              <h1 className="text-2xl font-bold">Hi, Welcome Back!</h1>
              <p className="text-gray-500">
                Logical Customer Relation Manager Solution
              </p>
            </div>
            {children}
          </div>
        </div>
        <Toaster position="top-right" />
      </div>
      <Header />
      <AboutUs />
      <Editorials />
      <HowItWorks />
      <OurClients />
      <Testimonials />
      {/* <Pricing /> */}
      <FAQS
        componentTitle="Frequently Asked Questions"
        options={[
          { question: "lorem ipsum", answer: "lorem ipsum" },
          { question: "lorem ipsum", answer: "lorem ipsum" },
          { question: "lorem ipsum", answer: "lorem ipsum" },
        ]}
      />
      <GetStarted />
      <div className="w-full flex justify-center bg-[#F6F7FD]">
        <div className="w-10/12 py-16">
          <ContactForm />
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default AuthContainer;
