import { PlusCircleIcon } from '@heroicons/react/24/solid';
import ExploreIcon from '@mui/icons-material/Explore';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { Alert, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Tab, Tabs, TextareaAutosize, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/system';
import {
  GridColDef,
  GridRowModel,
  GridRowModesModel,
  GridRowSelectionModel,
  GridRowsProp
} from "@mui/x-data-grid-pro";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import format from 'date-fns/format';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../api/fetch-api";
import CrudGrid from "../../components/CrudGrid/CrudGrid";
import EditToolbar, { EditToolbarProps } from "../../components/EditToolbar";
import { CreatedResponseStatus, StorageKeys, UpdatedResponseStatus } from "../../constants/base.const";
import { DocumentType, MerchantBusinessType, MerchantBuyingType, MerchantStatus, PermissionEnum, RoleType } from "../../constants/base.enum";
import { useAppSelector } from '../../hooks/rtkHooks';
import { CommonOption } from "../../interfaces/common.type";

const statusOption = [
  {
    label: MerchantStatus.Active,
    alert: <Alert severity="success" sx={{ width: 'fit-content' }}>Active</Alert>
  },
  {
    label: MerchantStatus.Pending,
    alert: <Alert severity="info" sx={{ width: 'fit-content' }}>Pending</Alert>
  },
  {
    label: MerchantStatus.Inactive,
    alert: <Alert severity="warning" sx={{ width: 'fit-content' }}>Inactive</Alert>
  },
];

const businessTypeOption = [
  { label: MerchantBusinessType.Agency },
  { label: MerchantBusinessType.Media },
  { label: MerchantBusinessType.Affiliate },
  { label: MerchantBusinessType.Brand },
  { label: MerchantBusinessType.Operator },
];

const buyingTypeOption = [
  { label: MerchantBuyingType.Yes },
  { label: MerchantBuyingType.No },
];

interface merchantDtoProps {
  id?: number;
  merchantAdminEmail: string;
  companyName: string;
  firstName: string;
  lastName: string;
  adminPhone?: string;
  mobilePhone?: string;
  companyNumber?: string;
  extension?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  merchantComments?: string;
  termsId?: number;
  paymentTypeId?: number;
  creditCardNumber?: string;
  expiration?: string;
  cvv?: string;
  isBillingSameAddress: boolean;
  billAddress?: string;
  billCity?: string;
  billState?: string;
  billZipCode?: string;
  billCountry?: string;
  documentType?: string;
  businessType?: string;
  buying?: string;
  signedOnDate?: string;
  signedBy?: string;
  capturedIp?: string;
  documentLink?: string;
  creationDate: string;
  status: string;
  groupId?: number;
  bundleId?: number;
  tierGroupId?: number;
  staffComments?: string;
}

const documentTypeOption = [
  { label: DocumentType.TermsAndCondition },
  { label: DocumentType.Privacy }
]

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ backgroundColor: '#f0f0f0', borderRadius: "10px", padding: "0 20px 20px 10px" }}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 24px ${theme.palette.mode === 'dark' ? blue[900] : blue[100]
    };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

export default function Merchant() {

  const role = useAppSelector((state) => state.user.user.roles[0]);

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>([]);
  const [loadingTable, setLoadingTable] = useState(false);

  //for single select options
  const [tierGroupOptions, setTierGroupOptions] = useState<CommonOption[]>([]);
  const [groupOptions, setGroupOptions] = useState<CommonOption[]>([]);
  const [bundleOptions, setBundleOptions] = useState<CommonOption[]>([]);
  const [termsOptions, setTermsOptions] = useState<CommonOption[]>([]);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState<CommonOption[]>([]);

  //values for formView
  const [formViewData, setFormViewData] = useState<merchantDtoProps>({
    id: undefined,
    merchantAdminEmail: '',
    companyName: '',
    firstName: '',
    lastName: '',
    adminPhone: undefined,
    mobilePhone: '',
    companyNumber: '',
    extension: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    merchantComments: '',
    termsId: undefined,
    paymentTypeId: undefined,
    creditCardNumber: '',
    expiration: '',
    cvv: '',
    isBillingSameAddress: false,
    billAddress: '',
    billCity: '',
    billState: '',
    billZipCode: '',
    billCountry: '',
    documentType: '',
    businessType: '',
    buying: '',
    signedOnDate: format(new Date(), 'yyyy-MM-dd'),
    signedBy: '',
    capturedIp: '',
    documentLink: '',
    creationDate: format(new Date(), 'yyyy-MM-dd'),
    status: MerchantStatus.Pending,
    groupId: undefined,
    tierGroupId: undefined,
    bundleId: undefined,
    staffComments: ''
  });

  //states for modal
  const [openModal, setOpenModal] = useState(false);

  //forTab
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // fetch merchant data from backend
  useEffect(() => {
    axiosInstance.get(
      `/backlinks/group?join=backlinks`
    ).then(async (response) => {
      if (response.data) {
        const tempGroupOptions: CommonOption[] = response.data.map((row: any) => ({id: row.id, value: row.groupName }));
        setGroupOptions(tempGroupOptions);
      }
    });
    axiosInstance.get(
      `/backlinks/bundle?join=backlinks`
    ).then(async (response) => {
      if (response.data) {
        const tempBundleOptions: CommonOption[] = response.data.map((row: any) => ({id: row.id, value: row.bundleName }));
        setBundleOptions(tempBundleOptions);
      }
    });
    axiosInstance.get(
      `/settings/tierGroup?join=priceTiers`
    ).then(async (response) => {
      if (response.data) {
        const tempTierGroupOptions: CommonOption[] = response.data.map((row: any) => ({id: row.id, value: row.tierGroupName }));
        setTierGroupOptions(tempTierGroupOptions);
      }
    });

    axiosInstance.get(
      `/settings/terms`
    ).then(async (response) => {
      if (response.data) {
        const tempOptions: CommonOption[] = response.data.map((row: any) => ({id: row.id, value: row.name }));
        setTermsOptions(tempOptions);
      }
    });

    axiosInstance.get(
      `/settings/paymentType`
    ).then(async (response) => {
      if (response.data) {
        const tempOptions: CommonOption[] = response.data.map((row: any) => ({id: row.id, value: row.name }));
        setPaymentTypeOptions([...[{ id: 0, value: '' }], ...tempOptions]);
      }
    });
    loadMerchantTable();
  }, []);

  const loadMerchantTable = () => {
    setLoadingTable(true);
    axiosInstance
      .get(
        `/settings/merchant?join=group&join=bundle&join=tierGroup&join=terms&join=paymentType`
      )
      .then(async (response) => {

        const rowsWithId = await response.data.map((row: GridRowModel, index: number) => {
          const { ...others } = row;
          return {
            ...others,
            customId: index + 1,
          };
        });
        setRows(rowsWithId);
        setLoadingTable(false);
      })
  }

  const handleLogPopupClick = () => {
    handleModal()
  };

  const handleClose = () => setOpenModal(false);

  const handleModal = () => { setOpenModal(!openModal); };

  const handleSaveFormView = async () => {
    if (!!!formViewData.id) {
      setLoadingTable(true);
      const { status: createStatus } = await axiosInstance.post(
        `/settings/merchant`,
        formViewData
      );
      if (createStatus === CreatedResponseStatus) {
        loadMerchantTable();
      } else {
        alert("Add Merchant row failed!")
      }
      setLoadingTable(false);
    } else {
      const { status: updateStatus } =
        await axiosInstance.patch(`/settings/merchant/${formViewData.id}`, {
          ...formViewData,
        });
      if (updateStatus === UpdatedResponseStatus) {
        loadMerchantTable();
      } else {
        alert("Update failed!")
      }
    }
    handleClose();
  }

  const initFormViewData = () => {
    setFormViewData({
      id: undefined,
      merchantAdminEmail: '',
      companyName: '',
      firstName: '',
      lastName: '',
      adminPhone: undefined,
      mobilePhone: '',
      companyNumber: '',
      extension: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      merchantComments: '',
      termsId: undefined,
      paymentTypeId: undefined,
      creditCardNumber: '',
      expiration: '',
      cvv: '',
      isBillingSameAddress: false,
      billAddress: '',
      billCity: '',
      billState: '',
      billZipCode: '',
      billCountry: '',
      documentType: '',
      businessType: '',
      buying: '',
      signedOnDate: format(new Date(), 'yyyy-MM-dd'),
      signedBy: '',
      capturedIp: '',
      documentLink: '',
      creationDate: format(new Date(), 'yyyy-MM-dd'),
      status: MerchantStatus.Pending,
      groupId: undefined,
      tierGroupId: undefined,
      bundleId: undefined,
      staffComments: ''
    });
  }

  // columns definition
  const defaultColumnDefs: GridColDef[] = [
    {
      field: "view",
      headerName: 'Form View',
      align: "center",
      renderCell: (cellValues: any) => {
        return (
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => {
              setFormViewData({ ...cellValues.row })
              handleLogPopupClick();
            }}
          >
            <ExploreIcon />
          </IconButton>
        );
      }
    },
    {
      field: "customId",
      headerName: "Id",
      editable: false,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "merchantAdminEmail",
      headerName: "Merchant Admin Email",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "companyName",
      headerName: "Company Name",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "firstName",
      headerName: "First Name",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "adminPhone",
      headerName: "Admin Phone",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "mobilePhone",
      headerName: "Mobile Phone",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "group",
      headerName: "Assign Backlink Group",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        groupId: opt?.value
      }),
      valueOptions: groupOptions.map((opt: CommonOption) => ({
        value: opt.id,
        label: opt.value,
      })),
    },
    {
      field: "status",
      headerName: "Status",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => {
        return opt?.value || "";
      },
      valueSetter: (opt) => {
        return {
          ...opt?.row,
          status: opt?.value,
        };
      },
      valueOptions: statusOption.map((opt) => opt.label),
    },
    {
      field: "creationDate",
      headerName: "Creation Date",
      editable: true,
      type: "date",
      valueGetter: (params) => new Date(params.value),
      headerAlign: "left",
    },
    {
      field: "terms",
      headerName: "Terms",
      editable: true,
      headerAlign: "left",
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        termsId: opt?.value
      }),
      valueOptions: termsOptions.map((opt: CommonOption) => ({
        value: opt.id,
        label: opt.value,
      })),
    },
    {
      field: "paymentType",
      headerName: "Credit Card on File",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        paymentTypeId: opt?.value
      }),
      valueOptions: paymentTypeOptions.map((opt: CommonOption) => ({
        value: opt.id,
        label: opt.value,
      })),
    },
    {
      field: "tierGroup",
      headerName: "Price Tier Group",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        tierGroupId: opt?.value
      }),
      valueOptions: tierGroupOptions.map((opt: CommonOption) => ({
        value: opt.id,
        label: opt.value,
      })),
      renderCell: (params) => (
        <div>
          {params.value}
        </div>
      ),
    },
    {
      field: "documentType",
      headerName: "Document Type",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "merchantComments",
      headerName: "Merchant Comments",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "businessType",
      headerName: "Business Type",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => {
        return opt?.value || "";
      },
      valueSetter: (opt) => {
        return {
          ...opt?.row,
          businessType: opt?.value,
        };
      },
      valueOptions: businessTypeOption.map((opt) => opt.label),
    },
    {
      field: "buying",
      headerName: "Are you buying?",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => {
        return opt?.value || "";
      },
      valueSetter: (opt) => {
        return {
          ...opt?.row,
          buying: opt?.value,
        };
      },
      valueOptions: buyingTypeOption.map((opt) => opt.label),
    },
    {
      field: "address",
      headerName: "Address",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "city",
      headerName: "City",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "zipCode",
      headerName: "Zip Code",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "country",
      headerName: "Country",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "companyNumber",
      headerName: "Company Number",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "extension",
      headerName: "Extension",
      editable: true,
      type: "string",
      headerAlign: "left",
    }
  ];

  const processRowUpdate = async (
    newRow: GridRowModel,
    oldRow: GridRowModel
  ) => {
    setLoadingTable(true);
    const updatedFields = Object.keys(newRow).filter(
      (fieldName) =>
        JSON.stringify(newRow[fieldName]) !== JSON.stringify(oldRow[fieldName])
    );
    const updatedRow = newRow;
    const filteredRows = rows.filter((row) => selectedRowIds.includes(row.id));
    const selectedRows = filteredRows.length > 0 ? filteredRows : [updatedRow];
    const createdRows: GridRowModel[] = []; // Allow adding only one row at a time.
    const updatedRows: GridRowModel[] = [];
    if (updatedRow.isNew) {
      createdRows.push({
        ...updatedRow,
        isNew: undefined,
        customId: undefined,
      });
    } else {
      selectedRows.forEach((selectedRow) => {
        updatedFields.forEach((updatedField) => {
          selectedRow[updatedField] = updatedRow[updatedField];
        });
        updatedRows.push(selectedRow);
      });
    }
    try {
      if (updatedRows.length) {
        const [update] = updatedRows;
        const merchantId = update.id;
        const { status: updateStatus, data: updatedData } =
          await axiosInstance.patch(`/settings/merchant/${merchantId}`, {
            ...update,
          });
        if (updateStatus === UpdatedResponseStatus) {
          // TODO: define interfaces and set correct types
          setRows((oldData: any) => {
            const idx = oldData.findIndex(
              (item: any) => item.id === updatedData.id
            );
            const newData = [...oldData];
            newData[idx] = { ...updatedData, customId: idx + 1 };
            return newData as never[];
          });
        }
      }
      // create
      const createdRow = createdRows.at(0);
      if (!!createdRow) {
        const { status: createStatus, data } = await axiosInstance.post(
          `/settings/merchant`,
          createdRow
        );
        createStatus === CreatedResponseStatus &&
          setRows(rows.map((row) => {
            if (row.id === newRow.id) {
              return { ...data, customId: rows.length }
            }
            else {
              return row
            }
          }
          ));
      }
      setLoadingTable(false);
      return updatedRow;
    } catch (e) {
      console.log(e);
    }
    setLoadingTable(false);
  }

  const style = {
    maxHeight: "100%",
    overflow: "auto",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    whiteSpace: "pre-wrap"
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, height: role.type === RoleType.SuperAdmin ? 860 : 620 }}>
          {statusOption.find((opt) => opt.label === formViewData.status)?.alert}
          <Typography id="merchant-modal-title" variant="h5" component="h5" sx={{ textAlign: 'center', alignContent: 'center' }}>
            MerchantView
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              // textColor="inherit"
              indicatorColor="secondary"
            >
              <Tab icon={<LocalLibraryIcon />} iconPosition="start" label="Merchant Information" {...a11yProps(0)} />
              <Tab icon={<RequestQuoteIcon />} iconPosition="start" label="Billing Info" {...a11yProps(1)} />
              <Tab icon={<LibraryBooksIcon />} iconPosition="start" label="Documents" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            <Box>
              <Typography id="modal-tab1-info" variant="h6" component="h6" sx={{ backgroundColor: 'grey', padding: "2px 10px", width: "fit-content" }}>
                Basic Info
              </Typography>
              <div className='grid grid-cols-4 gap-2'>
                <TextField margin="normal" sx={{ backgroundColor: "white" }} id="merchantAdmineamil" label="Merchant Admin Email" variant="outlined"
                  defaultValue={formViewData.merchantAdminEmail}
                  onChange={(event) => setFormViewData({ ...formViewData, merchantAdminEmail: event.target.value as string })}
                />
                <TextField margin="normal" sx={{ backgroundColor: "white" }} id="company" label="Company Name" variant="outlined" required={true}
                  defaultValue={formViewData.companyName}
                  onChange={(event) => setFormViewData({ ...formViewData, companyName: event.target.value as string })}
                />
                <TextField margin="normal" sx={{ backgroundColor: "white" }} id="firstName" label="First Name" variant="outlined"
                  defaultValue={formViewData.firstName}
                  onChange={(event) => setFormViewData({ ...formViewData, firstName: event.target.value as string })}
                />
                <TextField margin="normal" sx={{ backgroundColor: "white" }} id="lastName" label="Last Name" variant="outlined"
                  defaultValue={formViewData.lastName}
                  onChange={(event) => setFormViewData({ ...formViewData, lastName: event.target.value as string })}
                />
                <TextField margin="normal" sx={{ backgroundColor: "white" }} id="adminPhone" label="Admin Phone" variant="outlined"
                  defaultValue={formViewData.adminPhone}
                  onChange={(event) => setFormViewData({ ...formViewData, adminPhone: event.target.value as string })}
                />
                <TextField margin="normal" sx={{ backgroundColor: "white" }} id="mobilePhone" label="Mobile Phone" variant="outlined"
                  defaultValue={formViewData.mobilePhone}
                  onChange={(event) => setFormViewData({ ...formViewData, mobilePhone: event.target.value as string })}
                />
                <TextField margin="normal" sx={{ backgroundColor: "white" }} id="companyNumber" label="Company Number" variant="outlined"
                  defaultValue={formViewData.companyNumber}
                  onChange={(event) => setFormViewData({ ...formViewData, companyNumber: event.target.value as string })}
                />
                <TextField margin="normal" sx={{ backgroundColor: "white" }} id="extension" label="Extension" variant="outlined"
                  defaultValue={formViewData.extension}
                  onChange={(event) => setFormViewData({ ...formViewData, extension: event.target.value as string })}
                />
                <TextField margin="normal" sx={{ backgroundColor: "white" }} id="address" label="Address" variant="outlined"
                  defaultValue={formViewData.address}
                  onChange={(event) => setFormViewData({ ...formViewData, address: event.target.value as string })}
                />
                <TextField margin="normal" sx={{ backgroundColor: "white" }} id="city" label="City" variant="outlined"
                  defaultValue={formViewData.city}
                  onChange={(event) => setFormViewData({ ...formViewData, city: event.target.value as string })}
                />
                <TextField margin="normal" sx={{ backgroundColor: "white" }} id="state" label="State" variant="outlined"
                  defaultValue={formViewData.state}
                  onChange={(event) => setFormViewData({ ...formViewData, state: event.target.value as string })}
                />
                <div className='flex'>
                  <TextField margin="normal" sx={{ backgroundColor: "white" }} id="zipCode" label="ZIP Code" variant="outlined"
                    defaultValue={formViewData.zipCode}
                    onChange={(event) => setFormViewData({ ...formViewData, zipCode: event.target.value as string })}
                  />
                  <TextField margin="normal" sx={{ backgroundColor: "white" }} id="country" label="Country" variant="outlined"
                    defaultValue={formViewData.country}
                    onChange={(event) => setFormViewData({ ...formViewData, country: event.target.value as string })}
                  />
                </div>
              </div>
              <div className='grid grid-cols-1'>
                <Typography id="merchantCommentsLabel" variant="h6" component="h6">
                  Merchant Comments
                </Typography>
                <StyledTextarea
                  id="comments"
                  aria-label="merchantComment"
                  placeholder="Merchant Comments"
                  minRows={2}
                  defaultValue={formViewData.merchantComments}
                  onChange={(event) => setFormViewData({ ...formViewData, merchantComments: event.target.value as string })}
                />
              </div>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <Box>
              <Typography id="modal-tab1-info" variant="h6" component="h6" sx={{ backgroundColor: 'grey', padding: "2px 10px", width: "fit-content" }}>
                Billing Info
              </Typography>
              <div className='grid grid-cols-4'>
                <FormControl margin="normal" sx={{ minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label-terms">Terms</InputLabel>
                  <Select
                    id="demo-simple-select-terms"
                    labelId="demo-simple-select-label"
                    value={formViewData.termsId?.toString() || ''}
                    label="Terms"
                    onChange={(event: SelectChangeEvent) => { setFormViewData({ ...formViewData, termsId: parseInt(event.target.value as string) }) }}
                  >
                    {
                      termsOptions.map((opt) => <MenuItem key={`terms-item-` + opt.id} value={opt.id}>{opt.value}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </div>
              <div className='grid grid-cols-4 gap-2'>
                <FormControl margin="normal" sx={{ minWidth: 120, backgroundColor: "white" }}>
                  <InputLabel id="demo-simple-select-label-paymentType" sx={{ backgroundColor: "white" }}>CreditCard on File</InputLabel>
                  <Select
                    labelId="demo-simple-select-label-paymentType"
                    id="demo-simple-select-paymentType"
                    value={formViewData.paymentTypeId?.toString() || ''}
                    label="CreditCard on File"
                    onChange={(event: SelectChangeEvent) => { setFormViewData({ ...formViewData, paymentTypeId: parseInt(event.target.value as string) }) }}
                    sx={{ backgroundColor: "white" }}
                  >
                    {
                      paymentTypeOptions.map((opt) => <MenuItem key={`paymentType-item-` + opt.id} value={opt.id}>{opt.value}</MenuItem>)
                    }
                  </Select>
                </FormControl>
                <TextField margin="normal" sx={{ backgroundColor: "white" }} id="creditCardNumber" label="Credit Card Number" variant="outlined"
                  defaultValue={formViewData.creditCardNumber}
                  onChange={(event) => setFormViewData({ ...formViewData, creditCardNumber: event.target.value as string })}
                />
                <TextField margin="normal" sx={{ backgroundColor: "white" }} id="expiration" label="Expiration" variant="outlined"
                  defaultValue={formViewData.expiration}
                  onChange={(event) => setFormViewData({ ...formViewData, expiration: event.target.value as string })}
                />
                <TextField margin="normal" sx={{ backgroundColor: "white" }} id="cvv" label="CVV" variant="outlined"
                  defaultValue={formViewData.cvv}
                  onChange={(event) => setFormViewData({ ...formViewData, cvv: event.target.value as string })}
                />
              </div>
              <div className='grid grid-cols-4'>
                <FormControlLabel control={<Checkbox defaultChecked={false} />} label="Billing address same as above" />
              </div>
              <div className='grid grid-cols-4 gap-2'>
                <TextField margin="normal" sx={{ backgroundColor: "white" }} id="billAddress" label="Address" variant="outlined"
                  defaultValue={formViewData.billAddress}
                  onChange={(event) => setFormViewData({ ...formViewData, billAddress: event.target.value as string })}
                />
                <TextField margin="normal" sx={{ backgroundColor: "white" }} id="billCity" label="City" variant="outlined"
                  defaultValue={formViewData.billCity}
                  onChange={(event) => setFormViewData({ ...formViewData, billCity: event.target.value as string })}
                />
                <TextField margin="normal" sx={{ backgroundColor: "white" }} id="billState" label="State" variant="outlined"
                  defaultValue={formViewData.billState}
                  onChange={(event) => setFormViewData({ ...formViewData, billState: event.target.value as string })}
                />
                <div className='flex'>
                  <TextField margin="normal" sx={{ backgroundColor: "white" }} id="billZipCode" label="ZIP Code" variant="outlined"
                    defaultValue={formViewData.billZipCode}
                    onChange={(event) => setFormViewData({ ...formViewData, billZipCode: event.target.value as string })}
                  />
                  <TextField margin="normal" sx={{ backgroundColor: "white" }} id="billCountry" label="Country" variant="outlined"
                    defaultValue={formViewData.billCountry}
                    onChange={(event) => setFormViewData({ ...formViewData, billCountry: event.target.value as string })}
                  />
                </div>
              </div>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>
            <Typography id="modal-tab1-info" variant="h6" component="h6" sx={{ backgroundColor: 'grey', padding: "2px 10px", width: "fit-content" }}>
              Documents
            </Typography>
            <div className='grid grid-cols-4 gap-2'>
              <FormControl margin="normal" sx={{ minWidth: 120 }}>
                <InputLabel id="select-title-label-termsAndCondition">Document Type</InputLabel>
                <Select
                  id="select-termsAndCondition"
                  labelId="select-id-termsAndCondition"
                  value={formViewData?.documentType || ''}
                  label="Terms"
                  onChange={(event: SelectChangeEvent) => { setFormViewData({ ...formViewData, documentType: event.target.value as string }) }}
                >
                  {
                    documentTypeOption.map((opt) => <MenuItem key={`documentType-item-` + opt.label} value={opt.label}>{opt.label}</MenuItem>)
                  }
                </Select>
              </FormControl>
              <FormControl margin="normal" sx={{ minWidth: 120 }}>
                <InputLabel id="label-businessType">Business Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label-businessType"
                  id="demo-simple-select-businessType"
                  value={formViewData?.businessType || ''}
                  label="Business Type"
                  onChange={(event: SelectChangeEvent) => { setFormViewData({ ...formViewData, businessType: event.target.value as string }) }}
                >
                  {
                    businessTypeOption.map((opt) => <MenuItem key={`status-item-` + opt.label} value={opt.label}>{opt.label}</MenuItem>)
                  }
                </Select>
              </FormControl>
              <FormControl margin="normal" sx={{ minWidth: 120 }}>
                <InputLabel id="label-buying">Buying</InputLabel>
                <Select
                  labelId="demo-simple-select-label-buying"
                  id="demo-simple-select-buying"
                  value={formViewData?.buying || ''}
                  label="Buying"
                  onChange={(event: SelectChangeEvent) => { setFormViewData({ ...formViewData, buying: event.target.value as string }) }}
                >
                  {
                    buyingTypeOption.map((opt) => <MenuItem key={`status-item-` + opt.label} value={opt.label}>{opt.label}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </div>
            <div className='grid grid-cols-4 gap-2'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                  label="SignedOnDate"
                  value={dayjs(formViewData.signedOnDate)}
                  onChange={(newValue) => { setFormViewData({ ...formViewData, signedOnDate: (newValue as Dayjs).format('yyyy-MM-dd') }) }}
                  sx={{ marginTop: '16px' }}
                />
              </LocalizationProvider>
              <TextField margin="normal" sx={{ backgroundColor: "white" }} id="signedBy" label="Signed by" variant="outlined"
                defaultValue={formViewData.signedBy}
                onChange={(event) => setFormViewData({ ...formViewData, signedBy: event.target.value as string })}
              />
              <TextField margin="normal" sx={{ backgroundColor: "white" }} id="capturedIp" label="Captured IP" variant="outlined"
                defaultValue={formViewData.capturedIp}
                onChange={(event) => setFormViewData({ ...formViewData, capturedIp: event.target.value as string })}
              />
              <TextField margin="normal" sx={{ backgroundColor: "white" }} id="documentLink" label="Document Link" variant="outlined"
                defaultValue={formViewData.documentLink}
                onChange={(event) => setFormViewData({ ...formViewData, documentLink: event.target.value as string })}
              />
            </div>
          </CustomTabPanel>
          {role.type === RoleType.SuperAdmin &&
            <Box sx={{ marginTop: '10px' }}>
              <Typography id="modal-tab1-info" variant="h6" component="h6" sx={{ backgroundColor: 'grey', padding: "2px 10px", width: "fit-content" }}>
                By LinkHeadquaters Staff
              </Typography>
              <div className='grid grid-cols-4 gap-2'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateField
                    label="Creation Date"
                    value={dayjs(formViewData.creationDate)}
                    onChange={(newValue) => { setFormViewData({ ...formViewData, creationDate: (newValue as Dayjs).format('yyyy-MM-dd') }) }}
                    sx={{ marginTop: '16px' }}
                  />
                </LocalizationProvider>
                <FormControl margin="normal" sx={{ minWidth: 120 }}>
                  <InputLabel id="label-status">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label-status"
                    id="demo-simple-select-status"
                    value={formViewData?.status || ''}
                    label="Status"
                    onChange={(event: SelectChangeEvent) => { setFormViewData({ ...formViewData, status: event.target.value as string }) }}
                  >
                    {
                      statusOption.map((opt) => <MenuItem key={`status-item-` + opt.label} value={opt.label}>{opt.label}</MenuItem>)
                    }
                  </Select>
                </FormControl>
                <FormControl margin="normal" sx={{ minWidth: 120 }}>
                  <InputLabel id="label-group">BacklinkGroup</InputLabel>
                  <Select
                    labelId="demo-simple-select-label-group"
                    id="demo-simple-select-group"
                    value={formViewData.groupId?.toString() || ''}
                    label="BacklinkGroup"
                    onChange={(event: SelectChangeEvent) => { setFormViewData({ ...formViewData, groupId: parseInt(event.target.value as string) }) }}
                  >
                    {
                      groupOptions.map((opt) => <MenuItem key={`group-item-` + opt.id} value={opt.id}>{opt.value}</MenuItem>)
                    }
                  </Select>
                </FormControl>
                <FormControl margin="normal" sx={{ minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label-tierGroup">PriceTierGroup</InputLabel>
                  <Select
                    labelId="demo-simple-select-label-tierGroup"
                    id="demo-simple-select-tierGroup"
                    value={formViewData.tierGroupId?.toString() || ''}
                    label="PriceTierGroup"
                    onChange={(event: SelectChangeEvent) => {
                      setFormViewData({ ...formViewData, tierGroupId: parseInt(event.target.value as string) })
                    }}
                  >
                    {
                      tierGroupOptions.map((opt) => <MenuItem key={`tierGroup-item-` + opt.id} value={opt.id}>{opt.value}</MenuItem>)
                    }
                  </Select>
                </FormControl>
                <FormControl margin="normal" sx={{ minWidth: 120 }}>
                  <InputLabel id="label-bundle">Bundle Group</InputLabel>
                  <Select
                    labelId="demo-simple-select-label-bundle"
                    id="demo-simple-select-bundle"
                    value={formViewData.bundleId?.toString() || ''}
                    label="Bundle Group"
                    onChange={(event: SelectChangeEvent) => { setFormViewData({ ...formViewData, bundleId: parseInt(event.target.value as string) }) }}
                  >
                    {
                      bundleOptions.map((opt) => <MenuItem key={`bundle-item-` + opt.id} value={opt.id}>{opt.value}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </div>
              <div className='grid grid-cols-1'>
                <Typography id="staffCommentsLabel" variant="h6" component="h6">
                  Staff Comments
                </Typography>
                <StyledTextarea
                  id="staffComments"
                  aria-label="staffComments"
                  placeholder="Staff Comments"
                  minRows={2}
                  defaultValue={formViewData.staffComments}
                  onChange={(event) => setFormViewData({ ...formViewData, staffComments: event.target.value as string })}
                />
              </div>
            </Box>
          }
          <div className='flex justify-end py-2'>
            <Button
              variant="contained"
              color="success"
              sx={{ marginRight: '5px' }}
              onClick={handleSaveFormView}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
      <div className="bg-white mt-3 h-[calc(100vh-100px)]">
        <button className="btnPrimary py-1 px-3 my-3"
          onClick={() => {
            initFormViewData();
            setOpenModal(true);
          }
          }>
          <PlusCircleIcon className="w-4" />
          Add Merchant By Form
        </button>
        <CrudGrid
          baseApiPath="settings/merchant"
          checkboxSelection={true}
          defaultColumnDefs={defaultColumnDefs}
          tableKey={StorageKeys.MerchantTableHeaders}
          editable={
            role.type === RoleType.SuperAdmin ? true
              : !!role.permissions.find((permission) => permission.name === PermissionEnum.EditMerchant)
          }
          deletable={
            role.type === RoleType.SuperAdmin ? true
              : !!role.permissions.find((permission) => permission.name === PermissionEnum.DeleteMerchant)
          }
          loading={loadingTable}
          pinActions={true}
          rowModesModel={rowModesModel}
          rows={rows}
          processRowUpdate={processRowUpdate}
          setLoading={setLoadingTable}
          setRowModesModel={setRowModesModel}
          setRows={setRows}
          setSelectedRowIds={setSelectedRowIds}
          rowSelectionModel={selectedRowIds}
          slots={{
            toolbar: (props: EditToolbarProps) => EditToolbar({
              ...props,
              defaultValues: {
                createdDate: new Date(),
                showAddButton:
                  role.type === RoleType.SuperAdmin ? true
                    : !!role.permissions.find((permission) => permission.name === PermissionEnum.CreateMerchant)
              }
            }),
          }}
          dataGridKey={""} />
      </div>
    </>
  );
}
