import { Cancel, CheckCircle } from "@mui/icons-material";
import { CircularProgress, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../api/fetch-api";
import { Status } from "../../components/common";
import EmptyTable from "../../components/EmptyTable";
import LoadingTable from "../../components/LoadingTable";

const DetailItem = ({ label, value }: { label: string; value: string }) => {
  return (
    <div className="grid grid-cols-2">
      <h3 className="text-md font-bold my-2">{label}</h3>
      <text className="my-2">{value}</text>
    </div>
  );
};

export default function OrderDetails({ orderId }: { orderId?: number }) {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<any>(null);

  // const user = useAppSelector((state) => state.user.user);

  const navigate = useNavigate();

  useEffect(() => {
    if (!orderId) {
      navigate("/dashboard");
    } else
      axiosInstance
        .get(
          `/order/${orderId}?join=orderBacklinks&join=orderBacklinks.backlink&join=payment&join=orderStatus`
        )
        .then((res) => {
          const { status, data } = res;
          if (status === 200) {
            setOrder(data);
          } else {
            toast.error("Not found");
            navigate("/dashboard");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Not found");
          navigate("/dashboard");
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);

  // const processOrderHandler = (order: any, approved: boolean = false) => {
  //   if (approved) {
  //     setIsApproving(true);
  //   } else {
  //     setIsRejecting(true);
  //   }
  //   axiosInstance
  //     .patch(`/order/${orderId}`, {
  //       id: orderId,
  //       approved,
  //     })
  //     .then((res) => {
  //       console.log(res.data);
  //       setOrder((order: any) => ({ ...order, approved }));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(() => {
  //       setIsApproving(false);
  //       setIsRejecting(false);
  //     });
  // };

  // const isSuper = user?.roles[0]?.type === RoleType.SuperAdmin;
  const isQuote = order?.isQuote;

  return (
    <>
      <table className="mt-3 w-full">
        <thead className="bg-white border-b">
          <tr>
            <th scope="col" className="tableHead p-0 pl-6 py-2 text-center">
              #
            </th>
            <th scope="col" className="tableHead p-0 pl-6 py-2 text-center">
              Customer ID
            </th>
            <th scope="col" className="tableHead p-0 pl-6 py-2 text-center">
              Restricted Niche
            </th>
            <th scope="col" className="tableHead p-0 pl-6 py-2 text-center">
              Content By Link
            </th>
            <th scope="col" className="tableHead p-0 pl-6 py-2 text-center">
              Article Word Count
            </th>
            <th scope="col" className="tableHead p-0 pl-6 py-2 text-center">
              Price
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <LoadingTable />
          ) : !order?.orderBacklinks?.length ? (
            <EmptyTable />
          ) : (
            order &&
            order.orderBacklinks.map((orderBacklink: any, index: number) => {
              const {
                customerId,
                restricted,
                contentByLink,
                price,
                articleWord,
              } = orderBacklink;
              return (
                <tr
                  key={index + 1}
                  className="border-b border-dashed border-gray-300 hover:bg-gray-50 hover:cursor-pointer"
                >
                  <td className="text-center">
                    <div className="py-1 pl-6">{index + 1}</div>
                  </td>
                  <td className="text-center">
                    <div className="py-1 pl-6">{customerId}</div>
                  </td>
                  <td className="text-center">
                    <div className="py-1 pl-6">
                      {restricted}
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="py-1 pl-6">
                      {contentByLink ? (
                        <CheckCircle color="success" />
                      ) : (
                        <Cancel color="error" />
                      )}
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="py-1 pl-6">{articleWord}</div>
                  </td>
                  <td className="text-center">
                    <div className="py-1 pl-6">${price}</div>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      <div className="flex flex-row items-center justify-start w-fit gap-4 mt-5">
        <h3 className="text-md font-bold">Status:</h3>
        {loading ? (
          <CircularProgress size={34} color="warning" />
        ) : (
          <>
            <Status
              type={
                isQuote
                  ? "unfinished"
                  : order?.orderStatus?.name
              }
            />
            {/* {isSuper && !isQuote && isPending && (
              <div className="flex flex-row mx-auto gap-2">
                <button
                  className="text-xs btnPrimary bg-blue-700 text-white px-3 flex-nowrap w-24"
                  onClick={() => {
                    processOrderHandler(order, true);
                  }}
                  disabled={disabled}
                >
                  {isApproving ? (
                    <CircularProgress color="primary" size={20} />
                  ) : (
                    "Approve"
                  )}
                </button>
                <button
                  className="text-xs btnPrimary bg-red-700 text-white px-3 flex-nowrap w-24"
                  onClick={() => {
                    processOrderHandler(order, false);
                  }}
                  disabled={disabled}
                >
                  {isRejecting ? (
                    <CircularProgress color="primary" size={20} />
                  ) : (
                    "Reject"
                  )}
                </button>
              </div>
            )} */}
          </>
        )}
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h2 className="text-xl font-bold my-3 pt-4">Billing Details</h2>
          {loading ? (
            <LinearProgress
              color="warning"
              sx={{
                height: 3,
                borderRadius: 0,
              }}
            />
          ) : (
            <>
              <DetailItem label="Business Name" value={order.businessName} />
              <DetailItem label="Address" value={order.address} />
              <DetailItem label="County" value={order.county} />
              <DetailItem label="City" value={order.city} />
              <DetailItem label="Postal Code" value={order.postalCode} />
              <DetailItem label="Invoice Email" value={order.invoiceEmail} />
            </>
          )}
        </div>
        <div>
          <h2 className="text-xl font-bold my-3 pt-4">Payment Details</h2>
          {loading ? (
            <LinearProgress
              color="warning"
              sx={{
                height: 3,
                borderRadius: 0,
              }}
            />
          ) : (
            <>
              <DetailItem
                label="Card Number"
                value={order?.payment?.cardNumber || ""}
              />
              <DetailItem label="County" value={order?.payment?.county || ""} />
              <DetailItem label="Year" value={order?.payment?.year || ""} />
              <DetailItem label="Month" value={order?.payment?.month || ""} />
              <DetailItem label="poNumber" value={order?.poNumber || ""} />
            </>
          )}
        </div>
      </div>
    </>
  );
}
