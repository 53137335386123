import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { Autocomplete, Box, Button, darken, lighten, Stack, styled, TextField, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { GridRowModel } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../api/fetch-api";
import { useAppDispatch, useAppSelector } from '../../hooks/rtkHooks';
import { CommonOption } from '../../interfaces/common.type';
import { updateMerchant } from '../../store/slices/userSlice';

interface MerchantOptionType {
  id: number,
  mailName: string,
  companyName: string,
}

export default function SwitchMerchant() {

  const [openModal, setOpenModal] = useState(true);
  const [options, setOptions] = useState<MerchantOptionType[]>([]);
  const [selectedMerchant, setSelectedMerchant] = useState<MerchantOptionType | null>(null);

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state?.user?.user);

  const handleClose = () => {
    setOpenModal(!openModal);
  }

  useEffect(() => {
    axiosInstance
      .get(
        `/settings/merchant`
      )
      .then(async (response) => {

        const merchantRows = await response.data.map((row: GridRowModel, index: number) => {
          return {
            id: row.id,
            mailName: row.merchantAdminEmail,
            companyName: row.company
          };
        });
        setOptions(merchantRows);
        if(!!user.merchant){
          const tempMerchant: MerchantOptionType = merchantRows.find((merchant: any) => merchant?.id === user?.merchant?.id);
          setSelectedMerchant(tempMerchant);
        }
      })    
  }, [user.merchant]);

  const style = {
    maxHeight: "100%",
    overflow: "auto",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    whiteSpace: "pre-wrap"
  };

  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8),
  }));

  const GroupItems = styled('ul')({
    padding: 0,
  });

  const handleSwitchMerchant = () => {
    if (!!selectedMerchant) {
      const userMerchant: CommonOption = {id: selectedMerchant.id, value: selectedMerchant.companyName};
      dispatch(updateMerchant(userMerchant));
      handleClose();
    }
  }

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >        
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" marginBottom={"10px"}>
            Switch Merchant
          </Typography>
          
          <Stack direction="column" spacing={2} className='items-center'>            
            <Autocomplete
              id="grouped-demo"
              options={options.sort((a, b) => -b.mailName.localeCompare(a.mailName))}
              groupBy={(option) => option.mailName}
              getOptionLabel={(option) => option.companyName}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Merchant Name" />}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
              defaultValue={selectedMerchant}
              value={selectedMerchant}
              onChange={(event, newValue) => {
                setSelectedMerchant(newValue);
              }}
            />
            <Button
              variant="contained"
              color="warning"
              startIcon={<SwitchAccountIcon />}
              onClick={() => handleSwitchMerchant()}
            >Switch Now
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
