import React from "react";
import Admins from "./Admins";
import BacklinkTopVendors from "./BacklinkTopVendors";
import BacklinkByRank from "./BacklinkByRank";
import BacklinkBundles from "./BacklinkBundles";
import { Link } from "react-router-dom";
import OrdersTable from "../OrdersPage/OrdersTable";
import {
  PaperClipIcon,
  SparklesIcon,
  ArchiveBoxIcon,
  UserPlusIcon,
} from "@heroicons/react/24/solid";
import BarChart from "../../components/common/BarChart";

export default function Main() {
  const data = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "Sample Data",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: ["red", "blue", "yellow", "green", "purple", "orange"],
      },
    ],
  };
  const options = {
    scales: {
      x: {
        type: "category",
        position: "bottom",
        id: "x-axis-0", // Use the 'x-axis-0' ID for x-axis
      },
      y: {
        beginAtZero: true,
      },
    },
  };
  return (
    <>
      <div className="grid grid-cols-2 gap-6">
        <div>
          <div className="bg-white p-6 card">
            <div className="flex items-center justify-between">
              {" "}
              <p className="text-4xl font-bold m-2">
                Hello, <span className="font-normal">Alan</span>
              </p>
              <div className="flex items-center justify-end gap-3">
                <button className="btnPrimary bg-dark text-white">
                  Sign Out
                </button>
                <button className="btnPrimary">Reports</button>
              </div>
            </div>
            <p className="text-lg font-medium m-2">Welcome back, on board</p>
            <div className="grid grid-cols-4 gap-4 mt-5">
              <div className="rounded-xl p-5 bg-pink-100">
                <div className="rounded-full p-2 bg-pink-400 text-white w-fit">
                  <SparklesIcon className="w-5" />
                </div>
                <p className="font-bold text-3xl mt-3">$1K</p>
                <p className="mt-2 text-gray-600 text-xs font-semibold">
                  Total Sales
                </p>
                <p className="mt-2 text-blue-600 text-[10px] font-semibold">
                  +8% from yesterday
                </p>
              </div>
              <div className="rounded-xl p-5 bg-yellow-100">
                <div className="rounded-full p-2 bg-yellow-400 text-white w-fit">
                  <ArchiveBoxIcon className="w-5" />
                </div>
                <p className="font-bold text-3xl mt-3">300</p>
                <p className="mt-2 text-gray-600 text-xs font-semibold">
                  Total Orders
                </p>
                <p className="mt-2 text-blue-600 text-[10px] font-semibold">
                  +6% from yesterday
                </p>
              </div>
              <div className="rounded-xl p-5 bg-green-100">
                <div className="rounded-full p-2 bg-green-400 text-white w-fit">
                  <PaperClipIcon className="w-5" />
                </div>
                <p className="font-bold text-3xl mt-3">5</p>
                <p className="mt-2 text-gray-600 text-xs font-semibold">
                  Links Sold
                </p>
                <p className="mt-2 text-blue-600 text-[10px] font-semibold">
                  +10% from yesterday
                </p>
              </div>
              <div className="rounded-xl p-5 bg-purple-100">
                <div className="rounded-full p-2 bg-purple-400 text-white w-fit">
                  <UserPlusIcon className="w-5" />
                </div>
                <p className="font-bold text-3xl mt-3">15</p>
                <p className="mt-2 text-gray-600 text-xs font-semibold">
                  New Customers
                </p>
                <p className="mt-2 text-blue-600 text-[10px] font-semibold">
                  +3% from yesterday
                </p>
              </div>
            </div>
          </div>
          <div className="card mt-6">
            <div className="flex items-center justify-between">
              <p className="text-2xl font-bold m-2">Traffic Sources</p>
              <button className="btnPrimary">Actions</button>
            </div>
            <BarChart data={data} options={options} />
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <BacklinkTopVendors />
          <BacklinkByRank />
          <BacklinkBundles />
        </div>
      </div>
      <Admins />
      <div className="w-full flex items-center justify-between mt-6">
        <p className="text-xl font-bold m-2">Latest Orders</p>
        <Link to="/dashboard/orders/my-orders" className="btnPrimary">
          See All Orders
        </Link>
      </div>
      <OrdersTable
        tableLocation="dashboard"
        tableHeight="h-[calc(100vh-500px)]"
        className="px-0 mt-2"
      />
    </>
  );
}
