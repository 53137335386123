import React from "react";
import StepForm from "./StepForm";

export default function SignUp() {
  return (
    <div className="bg-dark flex items-center flex-col min-h-screen text-white">
      <StepForm />
    </div>
  );
}
