import useSWR from "swr";
import { getOrderStatusCount } from "../api/orderAPIs";

interface getOrderStatusCountReqestProps {
  userId: number;
}


export const useOrderStatusCount = ({userId}: getOrderStatusCountReqestProps) => {
  const { data, isLoading, mutate } = useSWR({userId}, getOrderStatusCount);

  return {
    isLoading,
    data,
    refresh: mutate
  }
}