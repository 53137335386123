import React from "react";
import Footer from "../../components/Footer";
import HeaderMain from "../../components/HeaderMain";

export default function About() {
  return (
    <div>
      <HeaderMain />
      <div className="w-full flex flex-col items-center justify-center mb-16">
        <div className="w-10/12 lg:w-10/12">
          <div className="py-10 lg:py-16 text-center">
            <h1 className="text-5xl font-bold">
              About <span className="text-secondary">Us</span>
            </h1>
            <p className="mt-3 text-lg">Link Headquarters Agency</p>
          </div>
          <div className="grid grid-cols-2 gap-10">
            <div>
              <h2 className="text-lg font-bold">
                At Link Headquarters, we strive to meet all customer requests
                and provide customized services that are perfectly suited to
                your needs.
              </h2>
              <p className="mt-2">
                We specialize in high-authority link building, catering to
                businesses of any size. Our services guarantee predictable time
                frames, complete transparency, and include consulting.
              </p>
              <p className="mt-5">
                While we offer a free-access platform, we also place great
                importance on building strong, long-lasting relationships with
                both our clients and the media.
              </p>
              <p className="mt-5">
                Use our platform to purchase your placements, and our client
                dashboard to submit your articles, or if you prefer, our award
                winning editorial team can handle all aspects of content
                creation, pitching, and rapid editing for your placements.
              </p>
              <p className="mt-5">
                We operate virtually with a team of professionals located
                throughout the US and the world, ensuring easy accessibility
                across all time zones. Our experienced team members boast an
                average of 10 years of agency and corporate marketing knowledge,
                bringing unparalleled expertise to the table.
              </p>
              <p className="mt-5">
                Our areas of expertise include link building, content creation,
                media relations, and administration, which enable us to provide
                the best all-around experience for you.
              </p>
            </div>
            <div>
              <img src="/about.png" alt="" className="w-full" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
