import React from "react";

export default function Editorials() {
  return (
    <div className="flex items-center justify-center w-full bg-[#F6F7FD]">
      <div className=" py-10 grid lg:grid-cols-editorials gap-10 w-10/12">
        <div>
          <img src="./editorials.png" alt="" className="w-full" />
        </div>
        <div className="flex justify-center flex-col">
          <h2 className="text-4xl font-extrabold">
            Real Editorials, Real Traffic, <br /> Real Results!
          </h2>
          <ul className="mt-5 text-xl list-disc pl-5">
            <li>Access to Link Headquarters platform</li>
            <li>Live chat and fast communication with your personal rep</li>
            <li>Content creation and publication of articles</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
