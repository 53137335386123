import React from "react";
import Footer from "../../components/Footer";
import HeaderMain from "../../components/HeaderMain";
import ContactForm from "../../components/ContactForm";

export default function About() {
  return (
    <div>
      <HeaderMain />
      <div className="w-full flex flex-col items-center justify-center mb-16">
        <div className="w-10/12 lg:w-10/12">
          <div className="py-10 lg:py-16 text-center">
            <h1 className="text-5xl font-bold">
              Contact <span className="text-secondary">Us</span>
            </h1>
            <p className="mt-3 text-lg">
              Have questions about link Headquarter?
            </p>
          </div>
          
          <ContactForm />
        </div>
      </div>
      <Footer />
    </div>
  );
}
