import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { InputField } from "../../components/common";
import { CartLinkSlice } from "../../store/slices/cartLinkSlice";
import { updatePoNumber } from "../../store/slices/userSlice";

export default function OrderSummary() {
  const cartLinks: CartLinkSlice[] = useSelector((state: any) => state.cartLink);
  const dispatch = useDispatch();
  const subtotal = cartLinks.reduce(
    (sum, link) => sum + link.price * link.quantity,
    0
  );

  const [poNumber, setPoNumber] = useState('');

  const handleChange = (value: string) => {
    setPoNumber(value);
    dispatch(updatePoNumber(value));
  }

  return (
    <div className="p-4 rounded-lg bg-white shadow-md grid lg:grid-cols-3 gap-5 sticky top-0 z-20">
      <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-2">
        <h1 className="text-xl font-extrabold">Order Summary</h1>
        <div className="flex items-center justify-between lg:justify-end gap-3">
          <p className="font-semibold text-lg whitespace-nowrap ">
            {cartLinks.length} link{cartLinks.length > 1 && "s"}
          </p>
          <p className="text-lg ">${subtotal.toFixed(2)}</p>
        </div>
      </div>
      <InputField placeholder="Enter PO Number" value={poNumber} onChange={(e) => handleChange(e.target.value)}/>
      <Link to="/dashboard/marketplace/order/billing">
        <button type="button" className="btnPrimary w-full text-white">
          Complete your order
        </button>
      </Link>
    </div>
  );
}
