import React from "react";

interface Props {
  label?: string,
  checked?: boolean,
  disabled?: boolean,
  onChange?: (e: any) => void;
}

const Checkbox = ({ label, checked, disabled, onChange }: Props) => {
  return (
    <label className="flex gap-2 cursor-pointer">
      <input
        type="checkbox"
        className="form-checkbox text-secondary h-4 w-4 cursor-pointer"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span>{label}</span>
    </label>
  );
};

export default Checkbox;
