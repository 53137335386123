import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "./index.css";

interface DropdownTeeComboProps {
  title: string;
  Icon: any;
  currentValue?: any[];
  valueSetter: (value: any[]) => void;
  name: string;
  options: any[];
}


interface TreeDataProps {
  label: string;
  id: number;
  checked: boolean;
  expanded: boolean;
  children?: TreeDataProps[];
}

export function DropdownTeeCombo({
  title,
  Icon,
  currentValue,
  valueSetter,
  name,
  options,
}: DropdownTeeComboProps) {


  const [treeData, setTreeData] = React.useState<TreeDataProps[]>([]);


  // function convertToTreeDataProps(data: any[]): TreeDataProps[] {
  //   const result: TreeDataProps[] = [];
  //   data.forEach((item) => {
  //     const newItem: TreeDataProps = { label: item.name, id: item.id, checked: false, expanded: false };
  //     if (item.children && item.children.length > 0) {
  //       newItem.children = convertToTreeDataProps(item.children);
  //     }
  //     result.push(newItem);
  //   });
  //   return result;
  // }

  function setCheckedTreeData(id: number, checked: boolean) {
    const tempSelIds: number[] = [];
    const tempTreeData = [...treeData];
    tempTreeData.forEach((item) => {
      if (id === item.id) {
        item.checked = checked;
      }
      if (item.checked) {
        tempSelIds.push(item.id);
      }
      if (item.children && item.children.length > 0) {
        item.children.forEach((itemChild) => {
          if (id === itemChild.id) {
            itemChild.checked = checked;
            item.expanded = true;
          }
          if (itemChild.checked) {
            tempSelIds.push(itemChild.id);
          }
          if (itemChild.children && itemChild.children.length > 0) {
            itemChild.children.forEach((itemChild1) => {
              if (id === itemChild1.id) {
                itemChild1.checked = checked;
                itemChild.expanded = true;
              }
              if (itemChild1.checked) {
                tempSelIds.push(itemChild1.id);
              }
            })
          }
        })
      }
    });
    setTreeData(tempTreeData);
    valueSetter(tempSelIds);
  }

  useEffect(() => {
    const data: TreeDataProps[] = options.map((item: any) => {
      let d1Item: TreeDataProps = { label: item.name, id: item.id, checked: false, expanded: false }
      if (item.children.length > 0) {
        const d1child: TreeDataProps[] = item.children.map((child1Item: any) => {
          let d2Item: TreeDataProps = { label: child1Item.name, id: child1Item.id, checked: false, expanded: false }
          if (child1Item.children.length > 0) {
            const d2child: TreeDataProps[] = child1Item.children.map((child2Item: any) => {
              return { label: child2Item.name, id: child2Item.id, checked: false, expanded: false }
            });
            d2Item = { ...d2Item, children: d2child };
          }
          return d2Item;
        });
        d1Item = { ...d1Item, children: d1child };
      }
      return d1Item;
    })
    setTreeData(data);
  }, [options]);

  const [isOpen, setIsOpen] = useState(false);

  const onChange = (currentNode: any, selectedNodes: any[]) => {
    setCheckedTreeData(currentNode.id, currentNode.checked);
  };

  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <Menu.Button title="Text Editor Guide" onClick={() => setIsOpen(!isOpen)}>
            <div
              className={`btnSecondary px-3 text-sm flex-nowrap whitespace-nowrap font-medium ${!!currentValue ? "bg-yellow-200" : "bg-white"
                }`}
            >
              {Icon && <Icon className="w-5" />} {title}{" "}
              {!!currentValue && `(${currentValue})`}
              <ChevronDownIcon className="w-4" />
            </div>
          </Menu.Button>
          {isOpen && (
            <Transition
              as={Fragment}
              show={true}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-0 top-0 mt-10 bg-white p-5 z-20 shadow-lg shadow-black/15 rounded-lg w-72">
                <div className="w-full">
                  <DropdownTreeSelect data={treeData} onChange={onChange} className="mdl-demo" />
                </div>
              </Menu.Items>
            </Transition>
          )}
        </>
      )}
    </Menu>
  );
}