import React from "react";
import { Link } from "react-router-dom";
import { PhoneIcon } from "@heroicons/react/24/solid";

export default function HeaderMain() {
  return (
    <div className="bg-white sticky w-full top-0 py-3 lg:flex items-center justify-center z-50">
      <div className="flex items-center justify-between w-10/12">
        <img src="/logo.png" className="h-10" alt="" />
        <div className="flex items-center ml-10 font-semibold">
          <Link to="/" className="pageLink px-3 hover:bg-secondary">
            Home
          </Link>
          <Link to="/about-us" className="pageLink px-3 hover:bg-secondary">
            About Us
          </Link>
          <Link to="/contact-us" className="pageLink px-3 hover:bg-secondary">
            Contact Us
          </Link>
          <a
            href="tel:+1 (800) 542-2323"
            className="pageLink px-3 hover:bg-secondary gap-1"
          >
            <PhoneIcon className="h-4" />
            +1 (800) 542-2323
          </a>
        </div>
        <div className="flex items-center justify-end">
          <Link
            to="/login"
            className="btnPrimary shadow-none rounded-md border-2 border-secondary mr-2 bg-transparent"
          >
            Login
          </Link>
          <Link to="/register" className="btnPrimary shadow-none rounded-md">
            Create Account
          </Link>
        </div>
      </div>
    </div>
  );
}
