import React from "react";
import { LinearProgress } from "@mui/material";

const LoadingTable = () => {
  return (
    <tr>
      <td colSpan={100}>
        <LinearProgress
          color="warning"
          sx={{
            height: 3,
            borderRadius: 0,
          }}
        />
      </td>
    </tr>
  );
};

export default LoadingTable;
