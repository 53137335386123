// src/Tabs.js
import React, { useState } from "react";

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="tabs">
      <ul className="flex items-center gap-3 border-b-2 border-secondary">
        {tabs.map((tab, index) => (
          <li
            key={index}
            className={`tab-item cursor-pointer px-5 rounded-b-none ${index === activeTab ? "btnPrimary" : ""}`}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </li>
        ))}
      </ul>
      <div className="tab-content">{tabs[activeTab].content}</div>
    </div>
  );
};

export default Tabs;
