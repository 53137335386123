import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { IconType } from "react-icons";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import { RoleType } from "../../constants/base.enum";
import { useAppSelector } from "../../hooks/rtkHooks";
import { MenuItem } from "../../model/side-menu.model";

interface Props {
  label?: string;
  options?: MenuItem[] | undefined;
  Icon?: IconType | undefined;
  show_navlabel?: boolean;
  to?: string;
  activeMenuItem?: string;
  setActiveMenuItem?: React.Dispatch<React.SetStateAction<string>> | undefined;
}

export default function Dropdown(props: Props) {
  const {
    label,
    options,
    Icon,
    show_navlabel,
    to,
    activeMenuItem,
    setActiveMenuItem,
  } = props;

  const [role] = useAppSelector((state) => state.user.user.roles);

  return (
    <Menu as="div" className="drop_down_menu">
      <div>
        <Menu.Button
          title={label}
          className="hover:bg-primary/20 p-2 rounded flex items-center focus:outline-none w-full hover:no-underline hover:text-white"
        >
          <div
            className={
              show_navlabel
                ? "flex items-center w-full "
                : "flex items-center w-full justify-center"
            }
          >
            {Icon && <Icon />}
            <span
              className={show_navlabel ? "ml-3 capitalize" : "ml-1 capitalize "}
            >
              {label}
            </span>
          </div>
          <FaAngleDown className="ml-1" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={
            show_navlabel
              ? "dropdown_options bg-beta rounded-xl pl-5"
              : "dropdown_options_small bg-white shadow-lg p-3 rounded-xl absolute top-0 mt-12 text-black"
          }
        >
          {options?.map((item) => {
            const isActive = activeMenuItem === item.label;


            // if (!!roles) {
            //   if (!role || !roles.includes(role.name)) {
            //     return <React.Fragment key="dropdown-menuitem-react.fragment-3"></React.Fragment>;
            //   }
            // }
            if (role) {
              if (role.type === RoleType.SuperAdmin) {
                if (item.label === "My Orders") {
                  return <React.Fragment key="react.fragment-3"></React.Fragment>;
                }
              } else {
                const { permission } = item;
                const permissions = [...role.permissions];
                if (!!permission) {
                  const ownPermission = permissions.find((pm) => pm.name === permission);
                  if (!!!ownPermission) {
                    return <React.Fragment key={"react.fragment-" + item.label + "-dropdown"}></React.Fragment>;
                  }
                }
              }
            } else {
              return <React.Fragment key="react.fragment-2"></React.Fragment>;
            }
            if (!!item?.children) {
              return (
                <Dropdown
                  show_navlabel={!!show_navlabel}
                  label={item.label}
                  activeMenuItem={activeMenuItem}
                  setActiveMenuItem={setActiveMenuItem}
                  to={to}
                  Icon={item.Icon}
                  options={item?.children}
                />
              );
            } else {
              return (
                <div key={`dropdown-menuitem-${item.label}`}>
                  <Link
                    className={`pageLink justify-between py-1 ${show_navlabel && "hover:text-white"
                      } ${isActive ? "bg-secondary" : ""}`}
                    title={item.label}
                    to={`${item.to}`}
                    onClick={() =>
                      setActiveMenuItem && setActiveMenuItem(item.label)
                    }
                  >
                    <span className="flex items-center">
                      {" "}
                      {Icon && <item.Icon />}
                      <span className="ml-3 capitalize">{item.label}</span>
                    </span>
                    {item.value && (
                      <p
                        style={{ backgroundColor: item.background }}
                        className="text-center w-12 text-white text-sm rounded-full"
                      >
                        {item.value}
                      </p>
                    )}
                    {/* {show_navlabel && <span className="ml-3">{item.label}</span>} */}
                  </Link>
                </div>
              );
            }
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
