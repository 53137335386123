import React from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export default function Tab3({
  formViewData,
  setFormViewData,
  documentTypeOption,
  businessTypeOption,
  buyingTypeOption,
}) {
  return (
    <div>
      <div className="grid grid-cols-4 gap-2">
        <FormControl margin="normal" sx={{ minWidth: 120 }}>
          <InputLabel id="select-title-label-termsAndCondition">
            Document Type
          </InputLabel>
          <Select
            id="select-termsAndCondition"
            labelId="select-id-termsAndCondition"
            value={formViewData?.documentType || ""}
            label="Terms"
            onChange={(event) => {
              setFormViewData({
                ...formViewData,
                documentType: event.target.value,
              });
            }}
          >
            {documentTypeOption.map((opt) => (
              <MenuItem
                key={`documentType-item-` + opt.label}
                value={opt.label}
              >
                {opt.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl margin="normal" sx={{ minWidth: 120 }}>
          <InputLabel id="label-businessType">Business Type</InputLabel>
          <Select
            labelId="demo-simple-select-label-businessType"
            id="demo-simple-select-businessType"
            value={formViewData?.businessType || ""}
            label="Business Type"
            onChange={(event) => {
              setFormViewData({
                ...formViewData,
                businessType: event.target.value,
              });
            }}
          >
            {businessTypeOption.map((opt) => (
              <MenuItem key={`status-item-` + opt.label} value={opt.label}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl margin="normal" sx={{ minWidth: 120 }}>
          <InputLabel id="label-buying">Buying</InputLabel>
          <Select
            labelId="demo-simple-select-label-buying"
            id="demo-simple-select-buying"
            value={formViewData?.buying || ""}
            label="Buying"
            onChange={(event) => {
              setFormViewData({
                ...formViewData,
                buying: event.target.value,
              });
            }}
          >
            {buyingTypeOption.map((opt) => (
              <MenuItem key={`status-item-` + opt.label} value={opt.label}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="grid grid-cols-4 gap-2">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateField
            label="SignedOnDate"
            value={dayjs(formViewData.signedOnDate)}
            onChange={(newValue) => {
              setFormViewData({
                ...formViewData,
                signedOnDate: newValue.format("yyyy-MM-dd"),
              });
            }}
            sx={{ marginTop: "16px" }}
          />
        </LocalizationProvider>
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="signedBy"
          label="Signed by"
          variant="outlined"
          defaultValue={formViewData.signedBy}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              signedBy: event.target.value,
            })
          }
        />
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="capturedIp"
          label="Captured IP"
          variant="outlined"
          defaultValue={formViewData.capturedIp}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              capturedIp: event.target.value,
            })
          }
        />
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="documentLink"
          label="Document Link"
          variant="outlined"
          defaultValue={formViewData.documentLink}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              documentLink: event.target.value,
            })
          }
        />
      </div>
    </div>
  );
}
