import ExploreIcon from "@mui/icons-material/Explore";
import { IconButton } from "@mui/material";
import {
  GridColDef,
  GridRowModel,
  GridRowModesModel,
  GridRowSelectionModel,
  GridRowsProp,
} from "@mui/x-data-grid-pro";
import React, { useEffect, useRef, useState } from "react";
import { axiosInstance } from "../../api/fetch-api";
import { Modal, SelectBox } from "../../components/common";
import CrudGrid from "../../components/CrudGrid/CrudGrid";
import EditToolbar, { EditToolbarProps } from "../../components/EditToolbar";
import {
  CreatedResponseStatus,
  StorageKeys,
  UpdatedResponseStatus,
} from "../../constants/base.const";
import { PermissionEnum, RoleType } from "../../constants/base.enum";
import { useOrderStatusCount } from "../../hooks/orderHooks";
import { useAppSelector } from "../../hooks/rtkHooks";
import { CommonOption } from "../../interfaces/common.type";
import OrderDetails from "../OrderDetails";
interface IOrdersTableProps {
  tableLocation?: string;
  orderStatus?: number;
  userId?: number;
  tableHeight?: string;
}

export default function OrdersTable({
  tableLocation,
  orderStatus,
  tableHeight,
  userId,
}: IOrdersTableProps) {
  const role = useAppSelector((state) => state.user.user.roles[0]);
  const user = useAppSelector((state) => state.user.user);
  const { refresh } = useOrderStatusCount({
    userId: user.roles[0].type === RoleType.SuperAdmin ? 0 : user.id,
  });

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>(
    []
  );
  const [loadingTable, setLoadingTable] = useState(false);
  const [orderStatusOption, setOrderStatusOption] = useState<CommonOption[]>(
    []
  );
  const [selOrderId, setSelOrderId] = useState(0);

  // fetch orders data from backend
  useEffect(() => {
    setLoadingTable(true);
    let url = ``;
    if (!!orderStatus) {
      if (role.type === RoleType.SuperAdmin) {
        url = `/order?join=orderStatus&filter=orderStatus.id||$eq||${orderStatus}`;
      } else {
        url = `/order?join=orderStatus&join=user&filter=orderStatus.id||$eq||${orderStatus}&filter=user.id||$eq||${user.id}`;
      }
    } else {
      if (userId) {
        url = `/order/user/` + userId;
      } else {
        url = `/order?join=orderStatus`;
      }
    }
    axiosInstance.get(url).then(async (response) => {
      const rowsWithId = await response.data.map(
        (row: GridRowModel, index: number) => {
          const { ...others } = row;
          return {
            ...others,
            customId: index + 1,
          };
        }
      );
      setRows(rowsWithId);
      setLoadingTable(false);
    });

    axiosInstance.get(`/settings/orderStatus`).then(async (response) => {
      const tempOrderStatusOption: CommonOption[] = [];
      if (response.data) {
        response.data.map((row: any, index: number) => {
          if (role.type === RoleType.SuperAdmin) {
            tempOrderStatusOption.push({ id: row.id, value: row.name });
          } else {
            if (row.systemStatus) {
              tempOrderStatusOption.push({ id: row.id, value: row.name });
            }
          }
        });
        setOrderStatusOption(tempOrderStatusOption);
      }
    });
  }, [orderStatus, userId, role.type]);

  const processRowUpdate = async (
    newRow: GridRowModel,
    oldRow: GridRowModel
  ) => {
    setLoadingTable(true);
    const updatedFields = Object.keys(newRow).filter(
      (fieldName) =>
        JSON.stringify(newRow[fieldName]) !== JSON.stringify(oldRow[fieldName])
    );
    const updatedRow = newRow;
    const filteredRows = rows.filter((row) => selectedRowIds.includes(row.id));
    const selectedRows = filteredRows.length > 0 ? filteredRows : [updatedRow];
    const createdRows: GridRowModel[] = []; // Allow adding only one row at a time.
    const updatedRows: GridRowModel[] = [];
    if (updatedRow.isNew) {
      createdRows.push({
        ...updatedRow,
        isNew: undefined,
        customId: undefined,
      });
    } else {
      selectedRows.forEach((selectedRow) => {
        updatedFields.forEach((updatedField) => {
          selectedRow[updatedField] = updatedRow[updatedField];
        });
        updatedRows.push(selectedRow);
      });
    }
    try {
      if (updatedRows.length) {
        const [update] = updatedRows;
        const { status: updateStatus, data: updatedData } =
          await axiosInstance.post(`/order/updateStatus`, {
            ...update,
          });
        if (updateStatus === UpdatedResponseStatus) {
          // TODO: define interfaces and set correct types
          setRows((oldData: any) => {
            const idx = oldData.findIndex(
              (item: any) => item.id === updatedData.id
            );
            const newData = [...oldData];
            newData[idx] = { ...updatedData, customId: idx + 1 };
            return newData as never[];
          });
        }
      }
      // create
      const createdRow = createdRows.at(0);
      if (!!createdRow) {
        const { status: createStatus, data } = await axiosInstance.post(
          `/order`,
          createdRow
        );
        createStatus === CreatedResponseStatus &&
          setRows(
            rows.map((row) => {
              if (row.id === newRow.id) {
                return { ...data, customId: rows.length };
              } else {
                return row;
              }
            })
          );
      }
      setLoadingTable(false);
      if (!!refresh) refresh();
      return updatedRow;
    } catch (e) {
      console.log(e);
    }
    setLoadingTable(false);
  };

  // columns definition
  const defaultColumnDefs: GridColDef[] = [
    {
      field: "customId",
      headerName: "Id",
      editable: false,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "businessName",
      headerName: "BusinessName",
      editable: false,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "address",
      headerName: "Address",
      editable: false,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "county",
      headerName: "County",
      editable: false,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "city",
      headerName: "City",
      editable: false,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "orderStatus",
      headerName: "Status",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || null,
      valueSetter: (opt) => ({
        ...opt?.row,
        orderStatus: {
          id: orderStatusOption.find((option) => option.id === opt.value)?.id,
        },
      }),
      valueOptions: orderStatusOption.map((opt) => ({
        value: opt.id,
        label: opt.value,
      })),
    },
    {
      field: "createdAt",
      headerName: "Date",
      editable: false,
      valueGetter: (params) =>
        !!params.value
          ? `${(new Date(params.value).getMonth() + 1)
              .toString()
              .padStart(2, "0")}/${new Date(params.value)
              .getDate()
              .toString()
              .padStart(2, "0")}/${new Date(params.value).getFullYear()}`
          : new Date().toDateString(),
      headerAlign: "left",
    },
    {
      field: "view",
      headerName: "View",
      align: "center",
      renderCell: (cellValues: any) => {
        return (
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => {
              setSelOrderId(cellValues.row.id);
              handleModal();
            }}
          >
            <ExploreIcon />
          </IconButton>
        );
      },
    },
  ];

  // Modal
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  return (
    <>
      {tableLocation === "page" && (
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold my-3">All Orders</h1>
          <div className="flex items-center justify-end gap-4">
            <SelectBox options={["1 month ago", "2", "3"]} className="py-2" />
            <button className="btnPrimary text-white">Download Invoice</button>
          </div>
        </div>
      )}
      <div
        className={`mt-3 ${
          tableHeight ? tableHeight : "h-[calc(100vh-100px)]"
        }`}
      >
        <CrudGrid
          baseApiPath="order"
          checkboxSelection={true}
          tableKey={StorageKeys.OrderTableHeaders}
          defaultColumnDefs={defaultColumnDefs}
          editable={
            role.type === RoleType.SuperAdmin
              ? true
              : !!role.permissions.find(
                  (permission) =>
                    permission.name === PermissionEnum.ChangeOrderStatus
                )
          }
          loading={loadingTable}
          pinActions={true}
          rowModesModel={rowModesModel}
          rows={rows}
          setLoading={setLoadingTable}
          processRowUpdate={processRowUpdate}
          setRowModesModel={setRowModesModel}
          setRows={setRows}
          setSelectedRowIds={setSelectedRowIds}
          slots={{
            toolbar: (props: EditToolbarProps) =>
              EditToolbar({
                ...props,
                defaultValues: { showAddButton: false },
              }),
          }}
          dataGridKey={""}
        />
      </div>

      <Modal
        open={open}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        className="max-w-5xl"
        title="Order Details"
        isLoading={false}
        HeaderButton={undefined}
      >
        <OrderDetails orderId={selOrderId} />
      </Modal>
    </>
  );
}
