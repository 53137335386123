import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../api/fetch-api";
import { SelectBox } from "../../components/common";
import EmptyTable from "../../components/EmptyTable";
import LoadingTable from "../../components/LoadingTable";
import { useAppSelector } from "../../hooks/rtkHooks";

export default function MyQuotes() {
  const user = useAppSelector((state) => state.user.user);

  const [quotes, setQuotes] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [finishingOrderIds, setFinishingOrderIds] = useState<number[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance
      .get(`/order/user/${user.id}/quote`)
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          setQuotes(data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user]);

  const finishOrderHanlder = (quote: any) => {
    setFinishingOrderIds((prev) => [...prev, quote.id]);
    axiosInstance
      .patch(`/order/${quote.id}`, {
        id: quote.id,
        isQuote: false,
      })
      .then((res) => {
        setQuotes((quotes) => {
          const idx = quotes.findIndex((q) => q.id === quote.id);
          quotes[idx].isQuote = false;
          return quotes;
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setFinishingOrderIds((prev) => prev.filter((id) => id !== quote.id));
      });
  };

  return (
    <>
      <div className="-mt-3 border-b grid grid-cols-marketTopBar items-center pb-2">
        <div className="flex flex-col">
          <p className="text-sm">Dashboard</p>
          <div className="flex items-center space-x-2 text-xs text-gray-500">
            <Link to="/dashbo ard">Dashboard</Link>
            <Link to="/dashboard/quotes" className="flex items-center">
              <ChevronDoubleRightIcon className="w-3" /> Orders
            </Link>
            <Link
              to="/dashboard/quotes/my-quotes"
              className="flex items-center"
            >
              <ChevronDoubleRightIcon className="w-3" /> My Quotes
            </Link>
          </div>
        </div>
        <div className="flex items-center justify-center gap-3"></div>
        <div className="flex itmes-center justify-end gap-3"></div>
      </div>
      <h1 className="text-2xl font-bold my-3">My Quotes</h1>
      <div className="w-full bg-white shadow-md p-7 rounded-lg">
        <div className="flex items-center justify-between">
          <h4 className="text-xl font-bold">Quotes</h4>
          <div className="flex items-center justify-end gap-4">
            <SelectBox options={["1 month ago", "2", "3"]} className="py-2" />
            <button className="btnPrimary text-white">Download Invoice</button>
          </div>
        </div>
        <table className="mt-3 w-full">
          <thead className="bg-white border-b">
            <tr>
              <th scope="col" className="tableHead p-0 pl-6 py-2">
                Action
              </th>
              <th scope="col" className="tableHead p-0 pl-6 py-2">
                Date
              </th>
              <th scope="col" className="tableHead p-0 pl-6 py-2">
                Business name
              </th>
              <th scope="col" className="tableHead p-0 pl-6 py-2">
                County
              </th>
              <th scope="col" className="tableHead p-0 pl-6 py-2">
                City
              </th>
              <th scope="col" className="tableHead p-0 pl-6 py-2">
                Address
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <LoadingTable />
            ) : !quotes.length ? (
              <EmptyTable />
            ) : (
              quotes.map((quote: any) => {
                const isFinishing = finishingOrderIds.includes(quote.id);
                const isFinished = !quote.isQuote;
                return (
                  <Fragment key={quote.id}>
                    <tr
                      className="border-b border-dashed border-gray-300 hover:bg-gray-50 hover:cursor-pointer"
                      onClick={() => {
                        navigate(`/dashboard/orders/${quote.id}`);
                      }}
                    >
                      <td>
                        <div className="py-1 text-gray-600">
                          <button
                            className="text-xs btnPrimary bg-green-700 text-white px-3 flex-nowrap w-32"
                            onClick={() => {
                              finishOrderHanlder(quote);
                            }}
                            disabled={isFinishing || isFinished}
                          >
                            {isFinishing ? (
                              <CircularProgress color="primary" size={20} />
                            ) : (
                              <>
                                <BsCheckCircleFill className="text-lg" />
                                {isFinished ? "Finished" : "Create Order"}
                              </>
                            )}
                          </button>
                        </div>
                      </td>
                      <td>
                        <div className="py-1 pl-6">
                          {new Date(quote.createdAt).toLocaleString()}
                        </div>
                      </td>
                      <td>
                        <div className="py-1 pl-6">{quote.businessName}</div>
                      </td>
                      <td>
                        <div className="py-1 pl-6">{quote.county}</div>
                      </td>
                      <td>
                        <div className="py-1 pl-6">{quote.city}</div>
                      </td>
                      <td>
                        <div className="py-1 pl-6">{quote.address}</div>
                      </td>
                    </tr>
                  </Fragment>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
