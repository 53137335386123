import React from "react";
import { SelectBox } from "../../components/common";

export default function FormStep4({ handlePrevious, handleNext }) {
  return (
    <div className="mt-12 grid lg:grid-cols-2 gap-5 lg:gap-16 w-full">
      <div>
        <h1 className="text-5xl font-bold capitalize">
          Fill <span className="text-secondary">Out</span>
        </h1>
        <p className="py-8 text-gray-200">
          Almost there! It’s important to us that we understand our users – Let
          us know a little more about you and we are ready to start!
        </p>
        <div className="grid lg:grid-cols-2 gap-x-4 gap-y-5">
          <div className="grid lg:grid-cols-2 gap-4">
            <div className="w-full">
              <label htmlFor="First Name" className="inputLabel">
                First Name
              </label>
              <input
                type="text"
                placeholder="First Name"
                className="inputField mt-1 text-black"
              />
            </div>
            <div className="w-full">
              <label htmlFor="Last Name" className="inputLabel">
                Last Name
              </label>
              <input
                type="text"
                placeholder="Last Name"
                className="inputField mt-1 text-black"
              />
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="City" className="inputLabel">
              City
            </label>
            <input
              type="email"
              placeholder="City"
              className="inputField mt-1 text-black"
            />
          </div>
          <div className="w-full">
            <label htmlFor="email" className="inputLabel">
              Email
            </label>
            <input
              type="email"
              placeholder="Email address"
              className="inputField mt-1 text-black"
            />
          </div>
          <div className="w-full">
            <label htmlFor="Postal Code" className="inputLabel">
              Postal Code
            </label>
            <input
              type="text"
              placeholder="Postal Code"
              className="inputField mt-1 text-black"
            />
          </div>
          <div className="w-full">
            <label htmlFor="Company" className="inputLabel">
              Company
            </label>
            <input
              type="text"
              placeholder="Company"
              className="inputField mt-1 text-black"
            />
          </div>
          <div className="w-full">
            <label htmlFor="Phone Number" className="inputLabel">
              Phone Number
            </label>
            <input
              type="text"
              placeholder="Phone Number"
              className="inputField mt-1 text-black"
            />
          </div>
          <SelectBox
            label="County"
            className="rounded-md"
            options={["US", "In", "UK"]}
          />
          <div className="w-full">
            <label htmlFor="Currency" className="inputLabel">
              Currency
            </label>
            <input
              type="text"
              placeholder="Currency"
              className="inputField mt-1 text-black"
            />
          </div>
          <div className="w-full">
            <label htmlFor="Monthtly Budget" className="inputLabel">
              Monthly Budget
            </label>
            <input
              type="text"
              placeholder="Monthly Budget"
              className="inputField mt-1 text-black"
            />
          </div>
        </div>

        <div className="flex items-center justify-end gap-2">
          <button
            onClick={handlePrevious}
            type="submit"
            className="btnPrimary py-3 px-6 mt-10"
          >
            Back
          </button>
          <button
            onClick={handleNext}
            type="submit"
            className="btnPrimary py-3 px-6 mt-10"
          >
            Next
          </button>
        </div>
      </div>
      <div>
        <img src="/signup.png" alt="" className="mt-12 ml-auto lg:w-10/12" />
      </div>
    </div>
  );
}
