import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {
  GridColDef,
  GridRowModel,
  GridRowModesModel,
  GridRowSelectionModel,
  GridRowsProp
} from "@mui/x-data-grid-pro";
import Papa from "papaparse";
import React, { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";

import BeenhereIcon from '@mui/icons-material/Beenhere';
import CancelIcon from "@mui/icons-material/Cancel";
import DiscountIcon from '@mui/icons-material/Discount';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { axiosInstance, fetchAPI } from "../../api/fetch-api";
import Modal from "../../components/common/Modal";
import CrudGrid from "../../components/CrudGrid/CrudGrid";
import EditToolbar, { EditToolbarProps } from "../../components/EditToolbar";
import { StorageKeys, UpdatedResponseStatus } from "../../constants/base.const";
import { ImportTestResult, NicheType, SettingOption } from "../../constants/base.enum";
import { useAppDispatch, useAppSelector } from "../../hooks/rtkHooks";
import { CommonOption } from "../../interfaces/common.type";
import { changeHidden, changeOrder } from "../../store/slices/userSlice";
import { getHeaderNameFromField } from "../../util/datagrid.util";
import ImportLogTable from "../ImportLog/ImportLog";

const categoryFields = [
  "category1",
  "category2",
  "category3",
  "category4",
  "category5",
  "category6",
  "category7",
  "category8",
  "category9",
  "category10",
  "category11",
  "category12",
  "category13",
  "category14",
  "category15",
]

const nicheFields = [
  "niche1",
  "niche2",
  "niche3",
  "niche4",
  "niche5",
  "niche6"
]

const multiplierFields = [
  "multiplier1",
  "multiplier2",
  "multiplier3",
  "multiplier4",
  "multiplier5",
  "multiplier6"
]

const booleanFields = [
  "image",
  "indexed",
  "news",
  "sponsored"
]

const numberFields = [
  "dr",
  "as",
  "da",
  "alexaRank",
  "monthlyTraffic",
  "spam",
  "referringDomains",
  "tatDaysMin",
  "tatDaysMax",
]

const stringFields = [
  "update",
  "country",
  "city",
  "state",
  "follow",
  "publicationSamples",
  "notes"
]

export interface AssignedProps {
  assignType?: string,
  assignId?: number
}

export default function Backlinks(props: AssignedProps) {
  const {
    vendorOptions,
    categoryOptions,
  } = useAppSelector((state) => state.settings);

  const [data, setData] = useState([]);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [dataGridKey, setDataGridKey] = useState(0);
  const [uploadedData, setUploadedData] = useState<any>([]);
  const [uploadStatusRow, setUploadStatusRow] = useState<any>({});
  const [groupOptions, setGroupOptions] = useState<any>([]);
  const [bundleOptions, setBundleOptions] = useState<any>([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedBundle, setSelectedBundle] = useState('');

  const groupComboHandleChange = (event: SelectChangeEvent) => {
    setSelectedGroup(event.target.value);
  };

  const bundleComboHandleChange = (event: SelectChangeEvent) => {
    setSelectedBundle(event.target.value);
  };

  useEffect(() => {
    const rowsWithId = data.map((row: GridRowModel, index: number) => {
      const { ...others } = row;
      return {
        ...others,
        customId: index + 1,
      };
    });
    setRows(rowsWithId);
  }, [data]);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>(
    []
  );
  const [loadingTable, setLoadingTable] = useState(false);
  const user = useAppSelector((state) => state?.user?.user);
  // const order = user.order?.split(",");
  const hidden = user.hidden?.split(",");
  const dispatch = useAppDispatch();

  const onOrderChange = useCallback(
    (order: string, hidden: string) => {
      dispatch(changeOrder(order));
      dispatch(changeHidden(hidden));
      fetchAPI({
        url: `/users/${user.id}`,
        method: "PATCH",
        body: { order, hidden },
      });
    },
    [dispatch, user.id]
  );

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const defaultColumnDefs: GridColDef[] = [
    {
      field: "customId",
      headerName: "ID",
      editable: false,
      type: "string",
      headerAlign: "left",
      disableExport: true
    },
    {
      field: "vendor",
      headerName: "Vendor Code",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        vendor: { id: opt?.value },
      }),
      valueOptions: vendorOptions.map((opt) => ({
        value: opt.id,
        label: opt.code,
      })),
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      editable: true,
      valueGetter: (params) => new Date(params.value),
      type: "date",
    },
    {
      field: "update",
      headerName: "Update",
      editable: true,
      type: "string",
    },
    {
      field: "publication",
      headerName: "Publication",
      editable: true,
      type: "string",
    },
    {
      field: "cost",
      headerName: "Cost",
      editable: true,
      valueFormatter: ({ value }) => currencyFormatter.format(value),
      type: 'number',
    },
    {
      field: "country",
      headerName: "Country",
      editable: true,
      type: "string",
    },
    {
      field: "city",
      headerName: "City",
      editable: true,
      type: "string",
    },
    {
      field: "state",
      headerName: "State",
      editable: true,
      type: "string",
    },
    {
      field: "dr",
      headerName: "DR",
      editable: true,
      type: "number",
    },
    {
      field: "as",
      headerName: "AS (Semrush)",
      editable: true,
      type: "number",
    },
    {
      field: "da",
      headerName: "DA",
      editable: true,
      type: "number",
    },
    {
      field: "alexaRank",
      headerName: "Alexa Rank",
      editable: true,
      type: "string",
    },
    {
      field: "monthlyTraffic",
      headerName: "Monthly Traffic",
      editable: true,
      type: "string",
    },
    {
      field: "spam",
      headerName: "Spam",
      editable: true,
      type: "string",
    },
    {
      field: "referringDomains",
      headerName: "Referring Domains",
      editable: true,
      type: "number",
    },
    {
      field: "isHomepageLink",
      headerName: "Is homepage link",
      editable: true,
      type: "boolean",
    },
    {
      field: "category1",
      headerName: "Category 1",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        vendor: { id: opt?.value },
      }),
      valueOptions: categoryOptions.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    },
    {
      field: "category2",
      headerName: "Category 2",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        vendor: { id: opt?.value },
      }),
      valueOptions: categoryOptions.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    },
    {
      field: "category3",
      headerName: "Category 3",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        vendor: { id: opt?.value },
      }),
      valueOptions: categoryOptions.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    },
    {
      field: "category4",
      headerName: "Category 4",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        vendor: { id: opt?.value },
      }),
      valueOptions: categoryOptions.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    },
    {
      field: "category5",
      headerName: "Category 5",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        vendor: { id: opt?.value },
      }),
      valueOptions: categoryOptions.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    },
    {
      field: "category6",
      headerName: "Category 6",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        vendor: { id: opt?.value },
      }),
      valueOptions: categoryOptions.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    },
    {
      field: "category7",
      headerName: "Category 7",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        vendor: { id: opt?.value },
      }),
      valueOptions: categoryOptions.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    },
    {
      field: "category8",
      headerName: "Category 8",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        vendor: { id: opt?.value },
      }),
      valueOptions: categoryOptions.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    },
    {
      field: "category9",
      headerName: "Category 9",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        vendor: { id: opt?.value },
      }),
      valueOptions: categoryOptions.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    },
    {
      field: "category10",
      headerName: "Category 10",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        vendor: { id: opt?.value },
      }),
      valueOptions: categoryOptions.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    },
    {
      field: "category11",
      headerName: "Category 11",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        vendor: { id: opt?.value },
      }),
      valueOptions: categoryOptions.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    },
    {
      field: "category12",
      headerName: "Category 12",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        vendor: { id: opt?.value },
      }),
      valueOptions: categoryOptions.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    },
    {
      field: "category13",
      headerName: "Category 13",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        vendor: { id: opt?.value },
      }),
      valueOptions: categoryOptions.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    },
    {
      field: "category14",
      headerName: "Category 14",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        vendor: { id: opt?.value },
      }),
      valueOptions: categoryOptions.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    },
    {
      field: "category15",
      headerName: "Category 15",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        vendor: { id: opt?.value },
      }),
      valueOptions: categoryOptions.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    },
    {
      field: "niche1",
      headerName: "Niche 1",
      editable: false,
      type: "string",
      valueGetter: (params) => !!params?.row?.casino ? NicheType.Casino : "",
    },
    {
      field: "niche2",
      headerName: "Niche 2",
      editable: false,
      type: "string",
      valueGetter: (params) => !!params?.row?.loan ? NicheType.Loan : "",
    },
    {
      field: "niche3",
      headerName: "Niche 3",
      editable: false,
      type: "string",
      valueGetter: (params) => !!params?.row?.erotic ? NicheType.Erotic : "",
    },
    {
      field: "niche4",
      headerName: "Niche 4",
      editable: false,
      type: "string",
      valueGetter: (params) => !!params?.row?.dating ? NicheType.Dating : "",
    },
    {
      field: "niche5",
      headerName: "Niche 5",
      editable: false,
      type: "string",
      valueGetter: (params) => !!params?.row?.cbd ? NicheType.Cbd : "",
    },
    {
      field: "niche6",
      headerName: "Niche 6",
      editable: false,
      type: "string",
      valueGetter: (params) => !!params?.row?.crypto ? NicheType.Crypto : "",
    },
    {
      field: "multiplier1",
      headerName: "Multiplier 1",
      editable: true,
      type: "number",
      valueGetter: (params) => params?.row?.casino || "",
    },
    {
      field: "multiplier2",
      headerName: "Multiplier 2",
      editable: true,
      type: "number",
      valueGetter: (params) => params?.row?.loan || "",
    },
    {
      field: "multiplier3",
      headerName: "Multiplier 3",
      editable: true,
      type: "number",
      valueGetter: (params) => params?.row?.erotic || "",
    },
    {
      field: "multiplier4",
      headerName: "Multiplier 4",
      editable: true,
      type: "number",
      valueGetter: (params) => params?.row?.dating || "",
    },
    {
      field: "multiplier5",
      headerName: "Multiplier 5",
      editable: true,
      type: "number",
      valueGetter: (params) => params?.row?.cbd || "",
    },
    {
      field: "multiplier6",
      headerName: "Multiplier 6",
      editable: true,
      type: "number",
      valueGetter: (params) => params?.row?.crypto || "",
    },
    {
      field: "tatDaysMin",
      headerName: "TAT Min (Days)",
      editable: true,
      type: "number",
    },
    {
      field: "tatDaysMax",
      headerName: "TAT Max (Days)",
      editable: true,
      type: "number",
    },
    {
      field: "image",
      headerName: "Image",
      editable: true,
      type: "boolean",
    },
    {
      field: "indexed",
      headerName: "Indexed",
      editable: true,
      type: "boolean",
    },
    {
      field: "sponsored",
      headerName: "Sponsored*",
      editable: true,
      type: "boolean",
    },
    {
      field: "follow",
      headerName: "Dofollow/Nofollow",
      editable: true,
      type: "string",
    },
    {
      field: "publicationSamples",
      headerName: "Publication Samples",
      editable: true,
      type: "string",
    },
    {
      field: "notes",
      headerName: "Notes",
      editable: true,
      type: "string",
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      type: "string",
    },
  ];

  useEffect(() => {
    setLoadingTable(true);
    if (!!props.assignType) {
      axiosInstance
        .get((
          `/backlink?join=vendor&join=newCategory1&join=newCategory2&join=newCategory3&join=newCategory4&join=newCategory5&join=newCategory6&join=newCategory7&join=newCategory8&join=newCategory9&join=newCategory10&join=newCategory11&join=newCategory12&join=newCategory13&join=newCategory14&join=newCategory15&join=category1&join=category2&join=category3&join=category4&join=category5&join=category6&join=category7&join=category8&join=category9&join=category10&join=category11&join=category12&join=category13&join=category14&join=category15&join=backlinkNiches${props.assignType === 'bundle' ? `&join=bundle&filter=bundle.id||$eq||${props.assignId}` : `&join=group&filter=group.id||$eq||${props.assignId}`}`)
        )
        .then((response) => {
          setData(response.data);
          setLoadingTable(false);
        })
    } else {
      axiosInstance.get(
        `/backlinks/group?join=backlinks`
      ).then(async (response) => {
        if (response.data) {
          const tempGroupOptions: CommonOption[] = response.data.map((row: any, index: number) =>({ id: row.id, value: row.groupName }));
          setGroupOptions(tempGroupOptions);
        }
      });
      axiosInstance.get(
        `/backlinks/bundle?join=backlinks`
      ).then(async (response) => {        
        if (response.data) {
          const tempBundleOptions: CommonOption[] = response.data.map((row: any, index: number) => ({ id: row.id, value: row.bundleName }));
          setBundleOptions(tempBundleOptions);
        }
      });
      axiosInstance
        .get(
          `/backlink?join=vendor&join=category1&join=category2&join=category3&join=category4&join=category5&join=category6
          &join=category7&join=category8&join=category9&join=category10&join=category11&join=category12&join=category13&join=category14&join=category15
          &join=backlinkNiches`
        )
        .then((response) => {
          setData(response.data);
          setLoadingTable(false);
        })
    }
  }, [props]);

  const processRowUpdate = async (
    newRow: GridRowModel,
    oldRow: GridRowModel
  ) => {
    setLoadingTable(true);
    // format category fields
    Object.values(SettingOption).forEach((category) => {
      const categoryId = newRow[category]?.id;
      if (categoryId === 0) {
        newRow[category] = undefined;
      }
    });

    // updated field names
    const updatedFields = Object.keys(newRow).filter(
      (fieldName) =>
        JSON.stringify(newRow[fieldName]) !== JSON.stringify(oldRow[fieldName])
    );
    const updatedRow = newRow;

    const filteredRows = rows.filter((row) => selectedRowIds.includes(row.id));
    const selectedRows = filteredRows.length > 0 ? filteredRows : [updatedRow];
    const createdRows: GridRowModel[] = []; // Allow adding only one row at a time.
    const updatedRows: GridRowModel[] = [];

    if (updatedRow.isNew) {
      createdRows.push({
        ...updatedRow,
        isNew: undefined,
        customId: undefined,
      });
    } else {
      selectedRows.forEach((selectedRow) => {
        updatedFields.forEach((updatedField) => {
          selectedRow[updatedField] = updatedRow[updatedField];
        });
        updatedRows.push(selectedRow);
      });
    }

    try {
      if (updatedRows.length) {
        const [update] = updatedRows;
        const backlinkId = update.id;
        const { status: updateStatus, data: updatedData } =
          await axiosInstance.patch(`/backlink/${backlinkId}`, update);
        if (updateStatus === UpdatedResponseStatus) {
          // TODO: define interfaces and set correct types
          setData((oldData: any) => {
            const idx = oldData.findIndex(
              (item: any) => item.id === updatedData.id
            );
            const newData = [...oldData];
            newData[idx] = updatedData;
            return newData as never[];
          });
        }
      }
      // create
      // const createdRow = createdRows.at(0);
      // if (!!createdRow) {
      //   const { status: createStatus, data } = await axiosInstance.post(
      //     `/backlink`,
      //     createdRow
      //   );
      // }
      setLoadingTable(false);
      return updatedRow;
    } catch (e) {
      console.log(e);
    }
    setLoadingTable(false);
  };

  const makeImportLogRowStr = (index: number, vendor: string, publication: string, detail: string) => {
    return index + ". VendorCode:'" + vendor + "' - Publication: '" + publication + "' Detail:" + detail + "\n"
  }

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("e.target.files", e.target.files);
    if (!e.target.files || loadingTable) return;
    if (e.target.files.length === 0) return;
    setDataGridKey((prev) => prev + 1);
    const file = e.target.files[0];
    setLoadingTable(true);
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async function (results) {
        let failed: number = 0;
        let skipped: number = 0;
        let errorRowIndex: number = 0;
        let successRowIndex: number = 0;
        let successRowsStr = "The following rows will be imported or updated\n";
        let errorRowsStr = "\nThe following rows will not be imported\n";

        /**
         * Algorithm
         * 
         *  if (!vendor || !publication) {
         *    skipped++;
         *    errorList.push(row);
         *  }
         *  else {
         *    if (vender in vendorList && category in categoryList) {
         *      successList.push(row);
         *      passed++;
         *    }
         *    else {
         *      errorList.push(row);
         *      failed++;
         *    }
         *  }
         */

        const parsedData: any[] = [];
        (results.data as GridRowsProp).forEach((excelRow) => {
          const vendorHeaderName = getHeaderNameFromField(defaultColumnDefs, "vendor");
          const publicationHeaderName = getHeaderNameFromField(defaultColumnDefs, "publication");
          if (vendorHeaderName && publicationHeaderName) {
            const vendorValue = excelRow[vendorHeaderName];
            const publicationValue = excelRow[publicationHeaderName];
            if (!vendorValue || !publicationValue) {
              if (!!vendorValue || !!publicationValue) {
                skipped++;
                let detail = !!vendorValue ? "Vendor Code is empty" : !!publicationValue ? "Publication is empty" : "";
                errorRowsStr += makeImportLogRowStr(++errorRowIndex, vendorValue, publicationValue, detail);
              }
            }
            else {
              let foundWrongCategory = false;
              let wrongCategoryList: string[] = [];
              categoryFields.forEach((categoryField) => {
                const categoryHeaderName = getHeaderNameFromField(defaultColumnDefs, categoryField) as string;
                const categoryValue = excelRow[categoryHeaderName];
                if (
                  categoryHeaderName &&
                  !!categoryValue &&
                  !categoryOptions.find((categoryOption) => categoryOption.name === categoryValue)
                ) {
                  wrongCategoryList.push(categoryValue);
                  foundWrongCategory = true;
                }
              })

              if (
                !foundWrongCategory &&
                !!vendorOptions.find((vendorOption) => vendorOption.code === vendorValue)
              ) {
                successRowsStr += makeImportLogRowStr(++successRowIndex, vendorValue, publicationValue, "");
                let newRow: any = {
                  "vendorId": vendorOptions.find((vendorOption) => vendorOption.code === vendorValue.trim())?.id as number,
                  "publication": publicationValue
                }
                categoryFields.forEach((categoryField) => {
                  const categoryValue = excelRow[getHeaderNameFromField(defaultColumnDefs, categoryField) as string];
                  const categoryId = categoryOptions.find((categoryOption) => categoryOption.name === categoryValue.trim())?.id;
                  if (categoryId) {
                    newRow[categoryField + "Id"] = categoryId;
                  }
                });
                for (let i = 0; i < 6; i++) {
                  const nicheValue: string = excelRow[getHeaderNameFromField(defaultColumnDefs, nicheFields[i]) as string];
                  if (nicheValue) {
                    const multiplierFieldValue = excelRow[getHeaderNameFromField(defaultColumnDefs, multiplierFields[i]) as string];
                    const multiplierValue = parseFloat(multiplierFieldValue.split(":")[1]);
                    if (!!multiplierValue) {
                      switch (nicheValue.toLowerCase()) {
                        case NicheType.Casino.toLowerCase():
                          newRow[NicheType.Casino.toLowerCase()] = multiplierValue;
                          break;
                        case NicheType.Loan.toLowerCase():
                          newRow[NicheType.Loan.toLowerCase()] = multiplierValue;
                          break;
                        case NicheType.Erotic.toLowerCase():
                          newRow[NicheType.Erotic.toLowerCase()] = multiplierValue;
                          break;
                        case NicheType.Dating.toLowerCase():
                          newRow[NicheType.Dating.toLowerCase()] = multiplierValue;
                          break;
                        case NicheType.Cbd.toLowerCase():
                          newRow[NicheType.Cbd.toLowerCase()] = multiplierValue;
                          break;
                        case NicheType.Crypto.toLowerCase():
                          newRow[NicheType.Crypto.toLowerCase()] = multiplierValue;
                          break;
                      }
                    }
                  }
                }
                booleanFields.forEach((booleanField) => {
                  const booleanValue = excelRow[getHeaderNameFromField(defaultColumnDefs, booleanField) as string];
                  if (booleanValue) {
                    newRow[booleanField] = booleanValue === "yes";
                  }
                })
                const costValue = excelRow[getHeaderNameFromField(defaultColumnDefs, "cost") as string];
                if (costValue)
                  newRow["cost"] = +costValue.replace("$", "");
                numberFields.forEach((numberField) => {
                  const numberValue = excelRow[getHeaderNameFromField(defaultColumnDefs, numberField) as string];
                  if (numberValue) {
                    newRow[numberField] = +numberValue;
                  }
                })
                const createdDateValue = excelRow[getHeaderNameFromField(defaultColumnDefs, "createdDate") as string];
                if (createdDateValue)
                  newRow["createdDate"] = new Date(createdDateValue);
                stringFields.forEach((stringField) => {
                  const stringValue = excelRow[getHeaderNameFromField(defaultColumnDefs, stringField) as string];
                  if (stringValue) {
                    newRow[stringField] = stringValue.trim();
                  }
                })
                parsedData.push(newRow);
              }
              else {
                let detail = "";
                if (!vendorOptions.find((vendorOption) => vendorOption.code === vendorValue)) {
                  detail = vendorValue + " is not matched in system VendorCode";
                }
                if (foundWrongCategory) {
                  detail = wrongCategoryList.join(", ") + " is not matched in system Category";
                }
                errorRowsStr += makeImportLogRowStr(++errorRowIndex, vendorValue, publicationValue, detail);
                failed++;
              }
            }
          }
        })
        try {
          const { data } = await axiosInstance.post(`/settings/importLog`, {
            testResult: failed === 0 ? ImportTestResult.Pass : ImportTestResult.Fail,
            totalRows: parsedData.length + failed + skipped,
            passed: parsedData.length,
            failed: failed,
            skipped: skipped,
            submitedDate: new Date(),
            comittedDate: null,
            resultLog: successRowsStr + errorRowsStr
          });
          setUploadStatusRow(data);
        } catch (e: any) {
          console.log(e);
          toast.error("ImportLog Save Error!");
        }
        setUploadedData(parsedData);
        handleModal();
        setLoadingTable(false);
      },
    });
  };

  const [openModal, setOpenMoal] = useState(true);

  const handleModal = () => {
    setOpenMoal(!openModal);
  };
  const cancelButtonRef = useRef(null);

  const commitUpload = async () => {
    if (uploadedData.length > 0) {
      try {
        const { data } = await axiosInstance.post(`/backlink/bulk`, {
          bulk: uploadedData,
        });
        const dataWithCustomId = data.map(
          (d: GridRowModel, dIdx: number) => ({
            ...d,
            customId: dIdx + 1,
          })
        );
        setRows([...rows, ...dataWithCustomId]);
      } catch (e: any) {
        console.log(e);
        toast.error("Upload Error!");
      }
      try {
        uploadStatusRow.comittedDate = new Date();
        await axiosInstance.patch(
          `/settings/importLog/${uploadStatusRow.id}`,
          uploadStatusRow
        );

      } catch (e) {
        console.log(e);
      }
    } else {
      toast.error("Upload Failed!");
    }
    handleModal();
  }

  const assignToMerchantGroup = async () => {
    if (selectedGroup === '') {
      alert("You should select a group!")
      return;
    }
    if (selectedRowIds.length === 0) {
      alert("You should select table rows!")
      return;
    }
    const { status: createStatus, data } = await axiosInstance.post(
      `/backlinks/group/updateBacklinks`,
      {
        id: selectedGroup,
        backlinkIds: selectedRowIds
      }
    );
    if (createStatus === 201) {
      if (data) {
        alert("Assign Successed!");
      } else {
        alert("Assign Failed!");
      }
    }
  }

  const assignToBundleGroup = async () => {
    if (selectedBundle === '') {
      alert("You should select a bundle!")
      return;
    }
    if (selectedRowIds.length === 0) {
      alert("You should select table rows!")
      return;
    }
    const { status: createStatus, data } = await axiosInstance.post(
      `/backlinks/bundle/updateBacklinks`,
      {
        id: selectedBundle,
        backlinkIds: selectedRowIds
      }
    );
    if (createStatus === 201) {
      if (data) {
        alert("Assign Successed!");
      } else {
        alert("Assign Failed!");
      }
    }
  }

  return (
    <>
      <h1 className="text-2xl font-bold">Backlinks</h1>
      <div className="bg-white mt-3 h-[calc(100vh-100px)]">
        {!!!props.assignType &&
          <>
            <Modal
              open={openModal}
              handleModal={handleModal}
              cancelButtonRef={cancelButtonRef}
              className="max-w-5xl"
              title="ImportLog"
              isLoading={false}
              handleModalSubmit={undefined}
              modalType={undefined}
              modalFormStep={1}
              setModalFormStep={undefined}
              HeaderButton={false}
            >
              <Stack direction="row" spacing={2} className='items-center'>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<CancelIcon />}
                  onClick={() => handleModal()}
                >Reject
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<VerifiedIcon />}
                  onClick={() => commitUpload()}
                >Commit
                </Button>
              </Stack>
              <ImportLogTable></ImportLogTable>
            </Modal>


            <div>
              <Stack direction="row" spacing={2} sx={{ alignItems: 'center', backgroundColor: '#f5f5f5', padding: '16px' }}>
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                  <InputLabel id="demo-simple-select-helper-label">Merchant Group</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectedGroup}
                    label="Merchant Group"
                    onChange={groupComboHandleChange}
                  >
                    <MenuItem key="merchant-menuitem-none" value="">
                      <em>None</em>
                    </MenuItem>
                    {groupOptions.map((group: CommonOption) => (
                      <MenuItem key={`merchant-menuitem-${group.id}`} value={group.id}>
                        {group.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<BeenhereIcon />}
                  onClick={() => assignToMerchantGroup()}
                  style={{ height: '45px' }}
                >Assign To Merchant Group
                </Button>
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                  <InputLabel id="demo-simple-select-helper-label">Bundle Group</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectedBundle}
                    label="Bundle Group"
                    onChange={bundleComboHandleChange}
                  >
                    <MenuItem key="merchant-menuitem-none" value="">
                      <em>None</em>
                    </MenuItem>
                    {bundleOptions.map((bundle: CommonOption) => (
                      <MenuItem key={`merchant-menuitem-${bundle.id}`} value={bundle.id}>
                        {bundle.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DiscountIcon />}
                  onClick={() => assignToBundleGroup()}
                  style={{ height: '45px' }}
                >Assign To Bundle Group
                </Button>
              </Stack>
            </div>
          </>
        }
        <CrudGrid
          baseApiPath="backlink"
          checkboxSelection={true}
          dataGridKey={dataGridKey}
          tableKey={StorageKeys.BacklinkTableHeaders}
          defaultColumnDefs={defaultColumnDefs}
          hiddenFields={hidden}
          editable={true}
          loading={loadingTable}
          pinActions={!!!props.assignType ? true : undefined}
          rowModesModel={rowModesModel}
          onOrderChange={onOrderChange}
          rows={rows}
          handleFileUpload={handleFileUpload}
          processRowUpdate={processRowUpdate}
          setLoading={setLoadingTable}
          setRowModesModel={setRowModesModel}
          setRows={setRows}
          setSelectedRowIds={setSelectedRowIds}
          slots={!!!props.assignType ? {
            toolbar: (props: EditToolbarProps) =>
              EditToolbar({ ...props, defaultValues: { showUpload: true, showAddButton: true } }),
          } : undefined} />
      </div>
    </>
  );
}
