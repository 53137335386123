import React from "react";
import Footer from "../../components/Footer";
import HeaderMain from "../../components/HeaderMain";

export default function TermsConditions() {
  return (
    <div>
      <HeaderMain />
      <div className="w-full flex flex-col items-center justify-center mb-16">
        <div className="w-10/12 lg:w-7/12 text-center">
          <div className="py-10 lg:py-16">
            <h1 className="text-5xl font-bold">
              Terms & <span className="text-secondary">Conditions</span>
            </h1>
            <p className="mt-3 text-lg">Link Headquarters Agency</p>
          </div>
          <h2 className="text-lg font-bold">1. INTRODUCTION</h2>
          <p className="mt-2">
            1.1 – These terms and conditions (these “Terms”), are entered into
            by and between Link Headquarter Group ApS, busi-ness registration
            no. 41638745, Vestergade 48H, 2., DK-8000 Aar-hus C, Denmark, (the
            “Company”) and the entity (the “Customer” and the Company and the
            Customer in-dividually a “Party” and collectively the “Parties”)
            accepting and placing the order for services provided by the Company
            as agreed in the order. These Terms govern the Parties’ rights and
            obligations with regards to the delivery of services from the
            Company to the Customer.
          </p>
          <p className="mt-5">
            1.2 – These Terms and the order, includ-ing any appendices enclosed
            to the order, form the entire agreement be-tween the Company and the
            Cus-tomer (the “Customer Agree-ment”). The effective date of the
            Customer Agreement is the date, the Customer places the order in the
            Company’s proprietary platform (the “Order Platform”) or directly to
            a representative of the Company (the “Effective Date”).
          </p>
          <p className="mt-5">
            1.3 – These Terms apply to all services provided by the Company to
            the Customer, including but not limited to the Company’s
            link-building be-tween the Customer and third par-ties (e.g.
            publishers) and preparation of articles, regardless of the ordering
            method.
          </p>
          <h2 className="mt-7 text-lg font-bold">2. SUBCONTRACTORS</h2>
          <p className="mt-2">
            2.1 – The Company may, at its sole dis-cretion, engage one or more
            subcon-tractors or other third parties to per-form and deliver (all
            or part of) the services under the Customer Agree-ment on its
            behalf.
          </p>
          <h2 className="mt-7 text-lg font-bold">3. ADDITIONAL SERVICES</h2>
          <p className="mt-2">
            2.1 – The Company may, at its sole dis-cretion, engage one or more
            subcon-tractors or other third parties to per-form and deliver (all
            or part of) the services under the Customer Agree-ment on its
            behalf.
          </p>
          <p className="mt-5">
            3.2 Unless otherwise specifically speci-fied in the agreement
            concerning the Additional Services, these Terms shall also apply to
            the Company’s delivery of the Additional Services.
          </p>
          <h2 className="mt-7 text-lg font-bold">4. PRICING</h2>
          <p className="mt-2">
            4.1 The Customer shall pay the agreed prices for the services set
            out in the order. All prices stated in the Order Platform are
            exclusive of VAT. The Company may adjust the prices and the price
            model in the Order Plat-form at any time.
          </p>
          <h2 className="mt-7 text-lg font-bold">5. PAYMENT TERMS</h2>
          <p className="mt-2">
            5.1 Payment of a specific invoice shall be made by the Customer no
            later than 8 days after receipt of the invoice. In case of late
            payment, the Company shall be entitled to charge default in-terest
            in accordance with section 5(1) of the Danish Interest Act (in
            Dan-ish: Renteloven).
          </p>
          <p className="mt-5">
            5.2 If a service cannot be provided for reasons for which third
            parties (e.g. publishers) are responsible or cannot be provided
            within the period agreed in the order, the Customer will re-ceive a
            credit note for amounts paid to the Customer’s specific credit
            ac-count set up by the Company unless the Parties have agreed
            otherwise in an mutual written agreement.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
