import React from "react";

export default function OurClients() {
  return (
    <div>
      <div className="flex items-center justify-center flex-col lg:py-16 bg-ourClients bg-cover bg-center">
        <div className="w-10/12 text-center flex-col flex items-center">
          <h2 className="text-4xl font-bold">
            <span className="text-secondary">Our</span> Clients
          </h2>
          <img src="/divider-black.png" alt="" className="w-fit my-4" />
          <p className="lg:w-6/12 text-xl font-medium">
            Agencies and professionals that know better trust Link Headquarters
            to maximize opportunities and growth for their clients.
          </p>
          <div className="bg-white shadow-md py-3 px-6 rounded-lg gap-5 grid grid-cols-2 lg:grid-cols-6 mt-7">
            <div className="flex justify-center items-center">
              <img src="/airbnb.png" alt="" />
            </div>
            <div className="flex justify-center items-center">
              <img src="/google.png" alt="" />
            </div>
            <div className="flex justify-center items-center">
              <img src="/fedex.png" alt="" />
            </div>
            <div className="flex justify-center items-center">
              <img src="/microsoft.png" alt="" />
            </div>
            <div className="flex justify-center items-center">
              <img src="/hubspot.png" alt="" />
            </div>
            <div className="flex justify-center items-center">
              <img src="/walmart.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="py-10 lg:py-16">
        <div className="text-center flex-col flex items-center">
          <h2 className="text-4xl font-bold">
            Link Headquarters #1 Authority for <br />
            <span className="text-secondary">link building!</span>
          </h2>
          <img src="/divider-black.png" alt="" className="w-fit my-4" />
          <p className="lg:w-6/12 text-gray-500">
            Backlinks hold immense value for SEO, as they signify validation
            from one website to another. Essentially, when other sites provide
            backlinks to your website, it sends a signal to search engines that
            your content is credible and trustworthy.
          </p>
          <div className="mt-8 border-2 rounded-xl grid lg:grid-cols-3 gap-5 p-7 lg:w-7/12">
            <div>
              <h3 className="text-4xl font-bold">5,000+</h3>
              <p className="mt-2 text-gray-500">
                Highly Curated DA50+ Publishers
              </p>
            </div>
            <div>
              <h3 className="text-4xl font-bold">2,000+</h3>
              <p className="mt-2 text-gray-500">Satisfied Customers</p>
            </div>
            <div>
              <h3 className="text-4xl font-bold">50+</h3>
              <p className="mt-2 text-gray-500">Content Creators</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
