import { GridColDef, GridRowModel, GridRowModesModel, GridRowsProp } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../api/fetch-api";
import { SelectBox } from "../../components/common";
import CrudGrid from "../../components/CrudGrid/CrudGrid";
import EditToolbar, { EditToolbarProps } from "../../components/EditToolbar";
import { StorageKeys } from "../../constants/base.const";

export default function AllQuotes() {
  const statusOption = [
    { label: "Pending" },
    { label: "Hold" },
  ];
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  useEffect(() => {
    setLoadingTable(true);
    axiosInstance
      .get(
        `/order?join=payment&join=orderBacklinks&s=${JSON.stringify({
          isQuote: true,
        })}`
      )
      .then(async (response) => {
        const rowsWithId = await response.data.map((row: GridRowModel, index: number) => {
          const { ...others } = row;
          return {
            ...others,
            customId: index + 1,
          };
        });
        setRows(rowsWithId);
        setLoadingTable(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingTable(false);
      });
  }, []);

  const defaultColumnDefs: GridColDef[] = [
    {
      field: "customId",
      headerName: "Id",
      editable: false,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "businessName",
      headerName: "BusinessName",
      editable: false,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "address",
      headerName: "Address",
      editable: false,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "county",
      headerName: "County",
      editable: false,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "city",
      headerName: "City",
      editable: false,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "orderStatus",
      headerName: "Status",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => {
        return opt?.value || "";
      },
      valueSetter: (opt) => {
        return {
          ...opt?.row,
          orderStatus: opt?.value,
        };
      },
      valueOptions: statusOption.map((opt) => opt.label),
    },
    {
      field: "createdAt",
      headerName: "Date",
      editable: false,
      type: "string",
      headerAlign: "left",
    }
  ];

  // Modal
  // const [open, setOpen] = useState(true);
  // const cancelButtonRef = useRef(null);
  // const handleModal = () => {
  //   setOpen(!open);
  // };

  return (
    <>
      {(
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold my-3">All Quotes</h1>
          <div className="flex items-center justify-end gap-4">
            <SelectBox options={["1 month ago", "2", "3"]} className="py-2" />
            <button className="btnPrimary text-white">Download Invoice</button>
          </div>
        </div>
      )}
      <div className="bg-white mt-3 h-[calc(100vh-100px)]">
        <CrudGrid
          baseApiPath="orders"
          checkboxSelection={true}
          dataGridKey={""}
          tableKey={StorageKeys.OrderTableHeaders}
          defaultColumnDefs={defaultColumnDefs}
          editable={true}
          loading={loadingTable}
          pinActions={true}
          rowModesModel={rowModesModel}
          rows={rows}
          setLoading={setLoadingTable}
          setRowModesModel={setRowModesModel}
          setRows={setRows}
          slots={{
            toolbar: (props: EditToolbarProps) =>
              EditToolbar({ ...props, defaultValues: { showAddButton : false } }),
          }}
        />
      </div>
    </>
  );
}