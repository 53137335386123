import React from "react";

export default function FormStep6({ handlePrevious, handleFormSubmit }) {
  return (
    <div className="mt-12 grid lg:grid-cols-2 gap-5 lg:gap-16 w-full">
      <div>
        <h1 className="text-5xl font-bold capitalize">
          Set your <span className="text-secondary">Password</span>
        </h1>
        <p className="py-8 text-gray-200">
          Final Step- Secure Your account by setting a password.
        </p>
        <form>
          <div className="grid lg:grid-cols-2 gap-4">
            <div className="w-full">
              <label htmlFor="Password" className="inputLabel">
                Password
              </label>
              <input
                type="text"
                placeholder="Password"
                className="inputField mt-1 text-black"
              />
            </div>
            <div className="w-full">
              <label htmlFor="Confirm Your Password" className="inputLabel">
                Confirm Your Password
              </label>
              <input
                type="text"
                placeholder="Confirm Your Password"
                className="inputField mt-1 text-black"
              />
            </div>
          </div>

          <div className="flex items-center justify-end gap-2">
            <button
              onClick={handlePrevious}
              type="submit"
              className="btnPrimary py-3 px-6 mt-10"
            >
              Back
            </button>
            <button
              onClick={handleFormSubmit}
              type="submit"
              className="btnPrimary py-3 px-6 mt-10"
            >
              Finish
            </button>
          </div>
        </form>
      </div>
      <div>
        <img src="/signup.png" alt="" className="mt-12 lg:w-10/12" />
      </div>
    </div>
  );
}
