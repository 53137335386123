import React from "react";
import TextField from "@mui/material/TextField";

export default function Tab1({ formViewData, setFormViewData }) {
  return (
    <div className="mt-5">
      <label className="inputLabel mt-2">Basic Info</label>
      <div className="grid grid-cols-4 gap-x-4">
        <TextField
          margin="normal"
          id="merchantAdminEmail"
          label="Merchant Admin Email"
          variant="outlined"
          defaultValue={formViewData.merchantAdminEmail}
          value={formViewData.merchantAdminEmail}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              merchantAdminEmail: event.target.value,
            })
          }
        />
        <TextField
          margin="normal"
          id="company"
          label="Company Name"
          variant="outlined"
          required={true}
          defaultValue={formViewData.companyName}
          value={formViewData.companyName}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              companyName: event.target.value,
            })
          }
        />
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="firstName"
          label="First Name"
          variant="outlined"
          defaultValue={formViewData.firstName}
          value={formViewData.firstName}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              firstName: event.target.value,
            })
          }
        />
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="lastName"
          label="Last Name"
          variant="outlined"
          defaultValue={formViewData.lastName}
          value={formViewData.lastName}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              lastName: event.target.value,
            })
          }
        />
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="adminPhone"
          label="Admin Phone"
          variant="outlined"
          defaultValue={formViewData.adminPhone}
          value={formViewData.adminPhone}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              adminPhone: event.target.value,
            })
          }
        />
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="mobilePhone"
          label="Mobile Phone"
          variant="outlined"
          defaultValue={formViewData.mobilePhone}
          value={formViewData.mobilePhone}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              mobilePhone: event.target.value,
            })
          }
        />
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="companyNumber"
          label="Company Number"
          variant="outlined"
          defaultValue={formViewData.companyNumber}
          value={formViewData.companyNumber}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              companyNumber: event.target.value,
            })
          }
        />
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="extension"
          label="Extension"
          variant="outlined"
          defaultValue={formViewData.extension}
          value={formViewData.extension}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              extension: event.target.value,
            })
          }
        />
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="address"
          label="Address"
          variant="outlined"
          defaultValue={formViewData.address}
          value={formViewData.address}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              address: event.target.value,
            })
          }
        />
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="city"
          label="City"
          variant="outlined"
          defaultValue={formViewData.city}
          value={formViewData.city}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              city: event.target.value,
            })
          }
        />
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="state"
          label="State"
          variant="outlined"
          defaultValue={formViewData.state}
          value={formViewData.state}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              state: event.target.value,
            })
          }
        />
        <div className="flex">
          <TextField
            margin="normal"
            sx={{ backgroundColor: "white" }}
            id="zipCode"
            label="ZIP Code"
            variant="outlined"
            defaultValue={formViewData.zipCode}
            value={formViewData.zipCode}
            onChange={(event) =>
              setFormViewData({
                ...formViewData,
                zipCode: event.target.value,
              })
            }
          />
          <TextField
            margin="normal"
            sx={{ backgroundColor: "white" }}
            id="country"
            label="Country"
            variant="outlined"
            defaultValue={formViewData.country}
            value={formViewData.country}
            onChange={(event) =>
              setFormViewData({
                ...formViewData,
                country: event.target.value,
              })
            }
          />
        </div>
      </div>
      <div>
        <label className="inputLabel mt-3 mb-2">Merchant Comments</label>
        <textarea
          className="inputField"
          placeholder="Merchant comments"
        ></textarea>
      </div>
    </div>
  );
}
