export const API_URL = process.env.REACT_APP_API_URL;

export enum StorageKeys {
  Token = "LH-Token",
  OrderTableHeaders = "LH-OrderTableHeaders",
  BacklinkTableHeaders = "LH-BacklinkTableHeaders",
  MarketplaceTableHeaders = "LH-MarketplaceTableHeaders",
  UserTableHeaders = "LH-UserTableHeaders",
  VendorTableHeaders = "LH-VendorTableHeaders",
  CategoryTableHeaders = "LH-CategoryTableHeaders",
  NicheTableHeaders = "LH-NicheTableHeaders",
  ImportLogTableHeaders = "LH-ImportLogTableHeaders",
  PriceTierTableHeaders = "LH-PriceTierTableHeaders",
  TierGroupTableHeaders = "LH-TierGroupTableHeaders",
  BundleTableHeaders = "LH-BundleTableHeaders",
  GroupTableHeaders = "LH-GroupTableHeaders",
  MerchantTableHeaders = "LH-MerchantTableHeaders",
  OrderStatusTableHeaders = "LH-OrderStatusTableHeaders",
  TermsTableHeaders = "LH-TermsTableHeaders",
  PaymentTypeTableHeaders = "LH-PaymentTypeTableHeaders",
}

export const CreatedResponseStatus = 201;
export const UpdatedResponseStatus = 200;