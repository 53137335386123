import { Checkbox, FormControl, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import {
  GridColDef, GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowSelectionModel,
  GridRowsProp
} from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../api/fetch-api";
import CrudGrid from "../../components/CrudGrid/CrudGrid";
import EditToolbar, { EditToolbarProps } from "../../components/EditToolbar";
import { CreatedResponseStatus, StorageKeys, UpdatedResponseStatus } from "../../constants/base.const";
import { PermissionEnum, PriceTierType, RoleType } from "../../constants/base.enum";
import { useAppSelector } from "../../hooks/rtkHooks";
import { CommonOption } from "../../interfaces/common.type";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

interface MultiComboStateType {
  id: number;
  values: string[];
}

export default function PriceTier() {

  const role = useAppSelector((state) => state.user.user.roles[0]);

  const tierOption = [
    { label: PriceTierType.PercentageAboveCost },
    { label: PriceTierType.FixedFromCost },
    { label: PriceTierType.PercentageDiscountFromRetail },
  ];

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>([]);
  const [loadingTable, setLoadingTable] = useState(false);
  // const [rowSelectionModel, setRowSelectionModel] = useState<GridInputRowSelectionModel>([])

  const [tierGroupOptions, setTierGroupOptions] = useState<CommonOption[]>([]);
  const [selTierGroupName, setSelTierGroupName] = useState<MultiComboStateType[]>([]);
  const [tierGroupNames, setTierGroupNames] = useState<string[]>([])


  const [groupRows, setGroupRows] = useState<GridRowsProp>([]);
  const [groupRowModesModel, setGroupRowModesModel] = React.useState<GridRowModesModel>({});
  const [loadingGroupTable, setLoadingGroupTable] = useState(false);

  // fetch orders data from backend
  useEffect(() => {
    loadPriceTierData();
    loadTierGroupData();
  }, []);

  const loadPriceTierData = async () => {
    setLoadingTable(true);
    let url = ``;
    url = `/settings/priceTier?join=tierGroups`;
    axiosInstance
      .get(url)
      .then(async (response) => {
        const tempSelTierGroupName: MultiComboStateType[] = [];
        const rowsWithId = await response.data.map((row: GridRowModel, index: number) => {
          if (!!row?.tierGroups && row?.tierGroups.length > 0) {
            tempSelTierGroupName.push({
              id: row?.id,
              values: row?.tierGroups.map((group: any) => group.tierGroupName)
            })
          }
          if (tempSelTierGroupName.length === 0) {
            tempSelTierGroupName.push({
              id: row?.id,
              values: [""]
            })
          }
          setSelTierGroupName(tempSelTierGroupName);
          const { ...others } = row;
          return {
            ...others,
            customId: index + 1,
          };
        });
        setRows(rowsWithId);
        setLoadingTable(false);
      })
  }

  const loadTierGroupData = async () => {
    setLoadingGroupTable(true);
    axiosInstance
      .get(
        `/settings/tierGroup?join=priceTiers`
      )
      .then(async (response) => {
        const tempGroupOptions: CommonOption[] = [];
        const tempGroupNames: string[] = [];
        const rowsWithId = await response.data.map((row: GridRowModel, index: number) => {
          tempGroupNames.push(row.tierGroupName);
          tempGroupOptions.push({ id: row.id, value: row.tierGroupName })
          const { ...others } = row;
          return {
            ...others,
            customId: index + 1,
          };
        });
        setGroupRows(rowsWithId);
        setLoadingGroupTable(false);
        setTierGroupOptions(tempGroupOptions);
        setTierGroupNames(tempGroupNames);
      })
  }

  const handleChange = (id: number, event: any) => {
    const {
      target: { value }
    } = event;
    const tempSelTierGroups = [...selTierGroupName];
    let selTierGroupPos = tempSelTierGroups.findIndex(item => item.id === id);
    if (selTierGroupPos === -1) {
      tempSelTierGroups.push({ id: id, values: (typeof value === 'string' ? value.split(",") : value) });
    } else {
      tempSelTierGroups[selTierGroupPos].values = (typeof value === 'string' ? value.split(",") : value);
    };
    setSelTierGroupName(tempSelTierGroups);

  };

  // columns definition
  const defaultColumnDefs: GridColDef[] = [
    {
      field: "customId",
      headerName: "Id",
      editable: false,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "tierName",
      headerName: "Tier Name",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "startingCost",
      headerName: "Start Cost",
      editable: true,
      type: "number",
      headerAlign: "left",
    },
    {
      field: "endingCost",
      headerName: "End Cost",
      editable: true,
      type: "number",
      headerAlign: "left",
    },
    {
      field: "tierType",
      headerName: "Tier Type",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => {
        return opt?.value || "";
      },
      valueSetter: (opt) => {
        return {
          ...opt?.row,
          tierType: opt?.value,
        };
      },
      valueOptions: tierOption.map((opt) => opt.label),
    },
    {
      field: "tierValue",
      headerName: "Tier Value",
      editable: true,
      type: "number",
      headerAlign: "left"
    },
    {
      field: "roundTo",
      headerName: "Round To",
      editable: true,
      type: "number",
      headerAlign: "left",
    },
    {
      field: "tierGroups",
      headerName: "Group",
      editable: false,
      headerAlign: "left",
      // valueGetter: (opt) => {
      //   return opt?.value.map((row: any) => row?.tierGroupName).join(", ") || "";
      // }
      renderCell: (cellValue: any) => {
        const isEditing = !!rowModesModel[cellValue?.id] ? (rowModesModel[cellValue?.id].mode === GridRowModes.Edit ? true : false) : false;
        return (
          <div>
            <FormControl sx={{ m: 1, width: 300 }}>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                disabled={!isEditing}
                value={selTierGroupName.find(item => item.id === cellValue?.id)?.values || ['']}
                onChange={(event: any) => handleChange(cellValue?.id, event)}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => {
                  const renderStr = selected.filter((item) => item !== "");
                  return renderStr.length > 1 ? renderStr.join(', ') : renderStr
                }}
                MenuProps={MenuProps}
              >
                {tierGroupNames.map((name, index) => {
                  const isExist = (selTierGroupName.find(item => item.id === cellValue?.id)?.values)?.indexOf(name);
                  return (
                    <MenuItem key={index} value={name}>
                      <Checkbox checked={isExist !== undefined ? isExist > -1 : false} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
        )
      }
    }
  ];

  const defaultGroupColumnDefs: GridColDef[] = [
    {
      field: "customId",
      headerName: "Id",
      editable: false,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "tierGroupName",
      headerName: "Name",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "assignedTiers",
      headerName: "Tiers",
      editable: true,
      type: "number",
      headerAlign: "left",
    }

  ];

  const processRowUpdate = async (
    newRow: GridRowModel,
    oldRow: GridRowModel
  ) => {
    setLoadingTable(true);
    const updatedFields = Object.keys(newRow).filter(
      (fieldName) =>
        JSON.stringify(newRow[fieldName]) !== JSON.stringify(oldRow[fieldName])
    );
    const updatedRow = newRow;
    const filteredRows = rows.filter((row) => selectedRowIds.includes(row.id));
    const selectedRows = filteredRows.length > 0 ? filteredRows : [updatedRow];
    const createdRows: GridRowModel[] = []; // Allow adding only one row at a time.
    const updatedRows: GridRowModel[] = [];
    if (updatedRow.isNew) {
      const updateTierGroupNames: string[] = selTierGroupName.find((item) => item.id === updatedRow?.id)?.values as string[];
      const addTierGroups: any[] = updateTierGroupNames.map((name) => {
        const group = tierGroupOptions.find((item) => item.value === name);
        if (!!group) {
          return {
            id: group.id,
            tierGroupName: group.value
          }
        }
      })
      const filterTierGroups = addTierGroups.filter(item => !!item);
      createdRows.push({
        ...updatedRow,
        isNew: undefined,
        customId: undefined,
        tierGroups: filterTierGroups
      });
    } else {
      selectedRows.forEach((selectedRow) => {
        updatedFields.forEach((updatedField) => {
          selectedRow[updatedField] = updatedRow[updatedField];
        });
        const updateTierGroupNames: string[] = selTierGroupName.find((item) => item.id === selectedRow?.id)?.values as string[];
        const updateTierGroups: any[] = updateTierGroupNames.map((name) => {
          const group = tierGroupOptions.find((item) => item.value === name);
          if (!!group) {
            return {
              id: group.id,
              tierGroupName: group.value
            }
          }
        })
        updatedRows.push({ ...selectedRow, tierGroups: updateTierGroups });
      });
    }
    try {
      if (updatedRows.length) {
        const [update] = updatedRows;
        const priceTierId = update.id;
        const { status: updateStatus, data: updatedData } =
          await axiosInstance.patch(`/settings/priceTier/${priceTierId}`, update);
        if (updateStatus === UpdatedResponseStatus) {
          // TODO: define interfaces and set correct types
          setRows((oldData: any) => {
            const idx = oldData.findIndex(
              (item: any) => item.id === updatedData.id
            );
            const newData = [...oldData];
            newData[idx] = { ...updatedData, customId: idx };
            return newData as never[];
          });
        }
      }
      // create
      const createdRow = createdRows.at(0);
      if (!!createdRow) {
        const { status: createStatus, data } = await axiosInstance.post(
          `/settings/priceTier`,
          createdRow
        );
        if (createStatus === CreatedResponseStatus) {
          if (createdRow.tierGroups.length > 0) {
            const tempSelTierGroupName = [...selTierGroupName];
            tempSelTierGroupName.push({
              id: data.id,
              values: createdRow.tierGroups.map((item: any) => item.tierGroupName)
            })
            setSelTierGroupName(tempSelTierGroupName);
          }
          setRows(rows.map((row) => {
            if (row.id === newRow.id) {
              return { ...data, customId: rows.length }
            }
            else {
              return row
            }
          }
          ));
        }
      }
      setLoadingTable(false);
      return updatedRow;
    } catch (e) {
      console.log(e);
    }
    setLoadingTable(false);
  }

  const processGroupRowUpdate = async (
    newRow: GridRowModel,
    oldRow: GridRowModel
  ) => {
    setLoadingGroupTable(true);
    const updatedFields = Object.keys(newRow).filter(
      (fieldName) =>
        JSON.stringify(newRow[fieldName]) !== JSON.stringify(oldRow[fieldName])
    );
    const updatedRow = newRow;
    const filteredRows = rows.filter((row) => selectedRowIds.includes(row.id));
    const selectedRows = filteredRows.length > 0 ? filteredRows : [updatedRow];
    const createdRows: GridRowModel[] = []; // Allow adding only one row at a time.
    const updatedRows: GridRowModel[] = [];
    if (updatedRow.isNew) {
      createdRows.push({
        ...updatedRow,
        isNew: undefined,
        customId: undefined,
      });
    } else {
      selectedRows.forEach((selectedRow) => {
        updatedFields.forEach((updatedField) => {
          selectedRow[updatedField] = updatedRow[updatedField];
        });
        updatedRows.push(selectedRow);
      });
    }
    try {
      if (updatedRows.length) {
        const [update] = updatedRows;
        const groupId = update.id;
        const { status: updateStatus, data: updatedData } =
          await axiosInstance.patch(`/settings/tierGroup/${groupId}`, update);
        if (updateStatus === UpdatedResponseStatus) {
          // TODO: define interfaces and set correct types
          setGroupRows((oldData: any) => {
            const idx = oldData.findIndex(
              (item: any) => item.id === updatedData.id
            );
            const newData = [...oldData];
            newData[idx] = { ...updatedData, customId: idx };
            return newData as never[];
          });
        }
      }
      // create
      const createdRow = createdRows.at(0);
      if (!!createdRow) {
        const { status: createStatus, data } = await axiosInstance.post(
          `/settings/tierGroup`,
          createdRow
        );
        createStatus === CreatedResponseStatus &&
          setGroupRows(groupRows.map((row) => {
            if (row.id === newRow.id) {
              return { ...data, customId: rows.length }
            }
            else {
              return row
            }
          }
          ));
      }
      setLoadingGroupTable(false);
      return updatedRow;
    } catch (e) {
      console.log(e);
    }
    setLoadingGroupTable(false);
  }

  // const assignToGroup = async () => {
  // if (selectedRowIds.length === 0) {
  //   alert("You should select PriceTiers!")
  //   return;
  // }
  // if (selectedTierGroup == '') {
  //   alert("You should select a Group!")
  //   return;
  // }
  // const { status: createStatus, data } = await axiosInstance.post(
  //   `/settings/tierGroup/updatePriceTiers`,
  //   {
  //     id: selectedTierGroup,
  //     priceTierIds: selectedRowIds
  //   }
  // );
  // if (createStatus === 201) {
  //   if (data) {
  //     alert("Assign Successed!");
  //     loadTierGroupData();
  //     loadPriceTierData();
  //   } else {
  //     alert("Assign Failed!");
  //   }
  // }
  // }

  // const tierGroupComboHandleChange = (event: SelectChangeEvent) => {
  //   // setSelectedTierGroup(event.target.value);
  //   const tiersByGroup = groupRows.find((group) => group.id === event.target.value)?.priceTiers;
  //   if (!!tiersByGroup) {
  //     const selTierIds: number[] = tiersByGroup?.map((tier: any) => tier.id);
  //     if (!!selTierIds && selTierIds.length > 0) {
  //       setSelectedRowIds(selTierIds);
  //     }
  //   }
  // };

  return (
    <>
      <div className="grid grid-cols-4 gap-5 mt-5">
        <div className="col-span-3 bg-white mt-3 h-[calc(100vh-100px)]">
          <div className="w-full flex items-center justify-between mt-2 mb-5">
            <h2 className="text-lg font-bold">Manage PriceTiers</h2>
            {/* <div className="flex items-center">
              <FormControl sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="demo-simple-select-helper-label">Select Group</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={selectedTierGroup}
              label="Select Group"
              onChange={tierGroupComboHandleChange}
            >
              <MenuItem key="merchant-menuitem-none" value="">
                <em>None</em>
              </MenuItem>
              {tierGroupOptions.map((tierGroup: CommonOption) => (
                <MenuItem key={`merchant-menuitem-${tierGroup.id}`} value={tierGroup.id}>
                  {tierGroup.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
              <Button
                variant="contained"
                color="primary"
                startIcon={<DiscountIcon />}
                onClick={() => assignToGroup()}
                style={{ height: '45px' }}
              >Assign To Group
              </Button>
            </div> */}
          </div>
          <CrudGrid
            baseApiPath="settings/priceTier"
            checkboxSelection={true}
            defaultColumnDefs={defaultColumnDefs}
            tableKey={StorageKeys.PriceTierTableHeaders}
            editable={
              role.type === RoleType.SuperAdmin ? true
                : !!role.permissions.find((permission) => permission.name === PermissionEnum.EditPriceTier)
            }
            deletable={
              role.type === RoleType.SuperAdmin ? true
                : !!role.permissions.find((permission) => permission.name === PermissionEnum.DeletePriceTier)
            }
            loading={loadingTable}
            pinActions={true}
            rowModesModel={rowModesModel}
            rows={rows}
            processRowUpdate={processRowUpdate}
            setLoading={setLoadingTable}
            setRowModesModel={setRowModesModel}
            setRows={setRows}
            setSelectedRowIds={setSelectedRowIds}
            rowSelectionModel={selectedRowIds}
            slots={{
              toolbar: (props: EditToolbarProps) =>
                EditToolbar({
                  ...props, defaultValues: {
                    showAddButton:
                      role.type === RoleType.SuperAdmin ? true
                        : !!role.permissions.find((permission) => permission.name === PermissionEnum.CreatePriceTier)
                  }
                }),
            }} dataGridKey={""} />
        </div>
        <div className="bg-white mt-3 h-[calc(100vh-100px)]">
          <div className="w-full flex items-center justify-between mt-2 mb-5">
            <h2 className="text-lg font-bold">Manage PriceTierGroups</h2>
          </div>
          <CrudGrid
            baseApiPath="settings/tierGroup"
            checkboxSelection={false}
            defaultColumnDefs={defaultGroupColumnDefs}
            tableKey={StorageKeys.TierGroupTableHeaders}
            editable={
              role.type === RoleType.SuperAdmin ? true
                : !!role.permissions.find((permission) => permission.name === PermissionEnum.EditPriceTier)
            }
            deletable={
              role.type === RoleType.SuperAdmin ? true
                : !!role.permissions.find((permission) => permission.name === PermissionEnum.DeletePriceTier)
            }
            loading={loadingGroupTable}
            pinActions={true}
            rowModesModel={groupRowModesModel}
            rows={groupRows}
            disableMultipleRowSelection={true}
            processRowUpdate={processGroupRowUpdate}
            setLoading={setLoadingGroupTable}
            setRowModesModel={setGroupRowModesModel}
            setRows={setGroupRows}
            slots={{
              toolbar: (props: EditToolbarProps) =>
                EditToolbar({
                  ...props, defaultValues: {
                    showAddButton:
                      role.type === RoleType.SuperAdmin ? true
                        : !!role.permissions.find((permission) => permission.name === PermissionEnum.CreatePriceTier)
                  }
                }),
            }} dataGridKey={""} />
        </div>
      </div>
    </>
  );
}
