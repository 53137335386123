import { createSlice } from "@reduxjs/toolkit";

export interface CartLinkSlice {
  id: number;
  publication: string;
  quantity: number;
  price: number;
  casino?: number;
  loan?: number;
  erotic?: number;
  dating?: number;
  cbd?: number;
  crypto?: number;
}


interface BuyLinkSlice {
  id: number;
  publication: string;
  quantity: number;
  price: number;
}

const cartLinkSlice = createSlice({
  name: "cartLink",
  initialState: [] as CartLinkSlice[],
  reducers: {
    addToCart: (state, action) => {
      state.push({ ...action.payload });
    },
    clearCartLink: (state) => {
      state = [];
      return state;
    },
    removeFromCart: (state, action) => {
      const itemId = action.payload;
      return state.filter((item) => item.id !== itemId);
    },
    incrementQuantity: (state, action) => {
      const itemId = action.payload;
      const item = state.find((item) => item.id === itemId);
      if (item) {
        item.quantity += 1;
      }
    },
    decrementQuantity: (state, action) => {
      const itemId = action.payload;
      const item = state.find((item) => item.id === itemId);
      if (item && item.quantity > 1) {
        item.quantity -= 1;
      }
    },
  },
});

const buyLinkSlice = createSlice({
  name: "buyLink",
  initialState: [] as BuyLinkSlice[],
  reducers: {
    buyLink: (state, action) => {
      state.push({ ...action.payload, quantity: 1 });
    },
    removeFromBuyList: (state, action) => {
      const itemId = action.payload;
      return state.filter((item) => item.id !== itemId);
    },
    incQuantity: (state, action) => {
      const itemId = action.payload;
      const item = state.find((item) => item.id === itemId);
      if (item) {
        item.quantity += 1;
      }
    },
    decQuantity: (state, action) => {
      const itemId = action.payload;
      const item = state.find((item) => item.id === itemId);
      if (item && item.quantity > 1) {
        item.quantity -= 1;
      }
    },
  },
});

export const {
  addToCart,
  clearCartLink,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
} = cartLinkSlice.actions;

export const { buyLink, removeFromBuyList, incQuantity, decQuantity } =
  buyLinkSlice.actions;

export const cartLinkReducer = cartLinkSlice.reducer;
export const buyLinkReducer = buyLinkSlice.reducer;
