import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchAPI } from "../../api/fetch-api";
import { StorageKeys } from "../../constants/base.const";
import { CommonOption } from "../../interfaces/common.type";
import { Cart } from "../../model/cart.model";
import { Permission } from "../../model/permission.model";
import { Role } from "../../model/role.model";
import {
  FetchUserByTokenProps,
  LoginUserProps,
  SignUpUserProps,
  User
} from "../../model/user.model";
import { setStorageValue } from "../../util/localStorage.util";
import { AsyncThunkConfig } from "../store";

interface UserSlice {
  user: User;
  userPermissions: Permission[];
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string;
  poNumber?: number;
}

const initialState = {
  user: {},
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
} as UserSlice;

export const signupUser = createAsyncThunk<
  any,
  SignUpUserProps,
  AsyncThunkConfig
>("users/signupUser", async ({ name, email, password }, thunkAPI) => {
  try {
    const { response, data } = await fetchAPI({
      url: "/auth/register",
      method: "POST",
      body: {
        name,
        email,
        password,
      },
    });
    const token = response?.headers.get("Authorization");
    if (response?.status === 201) {
      setStorageValue(StorageKeys.Token, token);
      return { ...data, name, email };
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const initialCart: Cart = {
  articleWord: 0,
  contentByLink: false,
  customerId: "",
  backlinkId: 0,
  price: 0,
  publication: "",
  restricted: "",
};

export const loginUser = createAsyncThunk<
  any,
  LoginUserProps,
  AsyncThunkConfig
>("users/login", async ({ email, password }, thunkAPI) => {
  try {
    const { response, data } = await fetchAPI({
      url: "/auth/login",
      method: "POST",
      body: {
        email,
        password,
      },
    });
    const token = response?.headers.get("Authorization");
    if (response?.status === 200) {
      setStorageValue(StorageKeys.Token, token);
      return data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    thunkAPI.rejectWithValue(e.response.data);
  }
});

export const fetchUserBytoken = createAsyncThunk<
  any,
  FetchUserByTokenProps,
  AsyncThunkConfig
>("users/fetchUserByToken", async ({ token }, thunkAPI) => {
  try {
    const { response, data } = await fetchAPI({
      url: "/auth/me",
      method: "GET",
      token: token || "",
    });
    if (response?.status === 200) {
      if (data.carts) {
        data.carts = data.carts.map((cart: Partial<Cart>) => ({
          ...initialCart,
          ...cart,
        }));
      }
      return data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateField: (state, action: PayloadAction<Partial<User>>) => {
      if (action.payload.carts) {
        action.payload.carts = action.payload.carts.map((cart) => ({
          ...initialCart,
          ...cart,
        }));
      }
      state.user = { ...state.user, ...action.payload };
      return state;
    },
    addToCartAction: (state, action: PayloadAction<Partial<Cart>>) => {
      state.user.carts.push({ ...initialCart, ...action.payload });
      return state;
    },
    removeFromCartAction: (state, action) => {
      const idx = state.user.carts.findIndex(
        (cart) => cart.id === action.payload
      );
      state.user.carts.splice(idx, 1);
      return state;
    },
    updatePoNumber: (state, action) => {
      state.poNumber = action.payload;
    },
    updateMyRole: (state, action) => {
      state.user.roles[0] = { ...state.user.roles[0], ...action.payload };
    },
    updateCart: (
      state,
      action: PayloadAction<{ id: number; updates: Partial<Cart> }>
    ) => {
      const idx = state.user.carts.findIndex(
        (cart) => cart.id === action.payload.id
      );
      state.user.carts[idx] = {
        ...state.user.carts[idx],
        ...action.payload.updates,
      };
      return state;
    },
    changeOrder: (state, action) => {
      state.user.order = action.payload;
      return state;
    },
    changeHidden: (state, action) => {
      state.user.hidden = action.payload;
      return state;
    },
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
    updateMerchant: (state, action) => {
      state.user.merchant = action.payload
      return state;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupUser.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.user = payload;
      })
      .addCase(signupUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(signupUser.rejected, (state, { error }) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = error.message || "";
      })

      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.user = payload;
        const userPermissions: Permission[] = [];
        payload.roles.forEach((userRole: Role) => {
          userRole.permissions.forEach((permission) => {
            userPermissions.push(permission);
          });
        });
        state.userPermissions = userPermissions.filter(
          (el, index) => {
            return userPermissions.indexOf(el) === index;
          }
        );
      })
      .addCase(loginUser.rejected, (state, { error }) => {
        console.log("error", error);
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = error.message || "";
      })

      .addCase(fetchUserBytoken.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchUserBytoken.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.user = payload;
        const userPermissions: Permission[] = [];
        payload.roles.forEach((userRole: Role) => {
          userRole.permissions.forEach((permission) => {
            userPermissions.push(permission);
          });
        });
        state.userPermissions = userPermissions.filter(
          (el, index) => userPermissions.indexOf(el) === index
        );
        if(!!payload?.merchant) {
          const userMerchant: CommonOption = {
            id: payload.merchant.id,
            value: payload.merchant.companyName
          }
          state.user.merchant = userMerchant;
        }
      })
      .addCase(fetchUserBytoken.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      });
  },
});

export const {
  clearState,
  changeOrder,
  changeHidden,
  updateField,
  updateCart,
  addToCartAction,
  removeFromCartAction,
  updatePoNumber,
  updateMyRole,
  updateMerchant
} = userSlice.actions;

export default userSlice.reducer;
