import axios from "axios";
import { API_URL, StorageKeys } from "../constants/base.const";
import { getStorageValue } from "../util/localStorage.util";

type Props = {
  url: string;
  method: "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
  token?: string;
  body?: any;
};

export const fetchAPI = async ({ url, method, body }: Props) => {
  try {
    const response = await fetch(`${API_URL}${url}`, {
      method,
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: getStorageValue(StorageKeys.Token),
      },
      body: JSON.stringify(body),
    });
    const data = await response?.json();

    return { response, data };
  } catch (e) {
    console.error("fetchAPI error: ", e);
    return { response: undefined, data: undefined };
  }
};

export const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const token = getStorageValue(StorageKeys.Token);
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});
