import {
  MinusCircleIcon,
  PlusCircleIcon, ShoppingCartIcon, XMarkIcon
} from "@heroicons/react/24/solid";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  CartLinkSlice,
  decrementQuantity,
  incrementQuantity,
  removeFromCart
} from "../../store/slices/cartLinkSlice";
import { CheckBox } from "../common";


export default function Cart({ setCartOpen }: {setCartOpen: (status: boolean) => void}) {
  const cartLinks: CartLinkSlice[] = useSelector((state: any) => state.cartLink);
  const dispatch = useDispatch();

  const handleDecrement = (itemId: number) => {
    dispatch(decrementQuantity(itemId));
  };

  const handleIncrement = (itemId: number) => {
    dispatch(incrementQuantity(itemId));
  };

  const handleRemove = (itemId: number) => {
    dispatch(removeFromCart(itemId));
  };

  const subtotal = cartLinks.reduce(
    (sum, link) => sum + link.price * link.quantity,
    0
  );

  return (
    <div className="w-full text-black flex flex-col">
      <h5 className="text-lg text-center font-bold flex items-center justify-center gap-2">
        <ShoppingCartIcon className="w-7" /> Shopping Cart
      </h5>
      <div className="p-3 rounded-md bg-white shadow-md">
        <ul
          className={`${cartLinks.length > 9 &&
            "overflow-y-scroll scroll-container max-h-80"
            }`}
        >
          {cartLinks.map((link) => (
            <li
              className="w-full border-b py-1 flex items-center justify-between gap-3"
              key={link.id}
            >
              <div className="hidden md:flex items-center justify-between gap-1 w-16">
                <button
                  type="button"
                  disabled={link.quantity === 1}
                  onClick={() => handleDecrement(link.id)}
                >
                  <MinusCircleIcon className="cursor-pointer text-red-500 w-5" />
                </button>
                {link.quantity}
                <button
                  type="button"
                  onClick={() => handleIncrement(link.id)}
                  className="cursor-pointer text-green-500 w-5"
                >
                  <PlusCircleIcon />
                </button>
              </div>
              <p className="flex-1 text-left w-16">{link.publication}</p>
              <p>${link.price * link.quantity}</p>
              <XMarkIcon
                onClick={() => handleRemove(link.id)}
                className="w-4 text-red-500 cursor-pointer"
              />
            </li>
          ))}
        </ul>
        <div className="flex item-center justify-between py-3">
          <p className="font-semibold">Amount</p>
          <p>
            {cartLinks.length} Link{cartLinks.length > 1 && "s"}
          </p>
        </div>
        <div className="flex item-center justify-between">
          <p className="font-semibold">Sub Total</p>
          <p>${subtotal.toFixed(2)}</p>
        </div>
      </div>
      <div className="flex content-center">
        {/* <div className="grid gap-2 md:gap-5 md:grid-cols-2 mt-3"> */}
        {/* <Link to="/dashboard/marketplace/order">
          <button disabled={!cartLinks.length} className="btnSecondary w-full">
            Save as quote
          </button>
        </Link> */}
        <Link to="/dashboard/marketplace/order" style={{ width: '100%' }}>
          <button
            type="button"
            disabled={!cartLinks.length}
            className="btnPrimary w-full text-white"
            onClick={() => {
              setCartOpen(false)
            }}
          >
            Checkout
          </button>
        </Link>
      </div>
      <div className="mt-3 bg-secondary/10 border-secondary border-2 border-dashed rounded-md text-xs py-2 px-3">
        <CheckBox checked={true} disabled={true} label="Content creation for your links is Included in the price" />
      </div>
    </div>
  );
}
