import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, Navigate } from "react-router-dom";
import { axiosInstance } from "../../api/fetch-api";
import { InputField } from "../../components/common";
import { useAppDispatch, useAppSelector } from "../../hooks/rtkHooks";
import { clearCartLink } from "../../store/slices/cartLinkSlice";
import { updateField } from "../../store/slices/userSlice";

interface OrderProps {
  poNumber: string | number,
  businessName: string,
  address: string,
  county: string,
  postalCode: string,
  city: string,
  invoiceEmail: string,
  isQuote: boolean,
  user: { id: number },
  payment?: { id: number },
}

export default function Billing() {
  const [user, poNumber] = useAppSelector((state) => [
    state.user.user,
    state.user.poNumber,
  ]);

  const { lastPayment } = user;

  const [cardNumber, setCardNumber] = useState(lastPayment?.cardNumber || "");
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const shouldDefaultToCurrentDate =
    !lastPayment ||
    (lastPayment && lastPayment?.year < currentYear) ||
    (lastPayment?.year === currentYear && lastPayment.month < currentMonth);
  const [month, setMonth] = useState(
    shouldDefaultToCurrentDate ? lastPayment?.month : currentMonth
  );
  const [year, setYear] = useState(
    shouldDefaultToCurrentDate ? lastPayment?.year : currentYear
  );
  const [paymentCounty, setPaymentCounty] = useState(lastPayment?.county || "");

  const [businessName, setBusinessName] = useState("");
  const [address, setAddress] = useState("");
  const [county, setCounty] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [invoiceEmail, setInvoiceEmail] = useState("");
  const [isPayLater, setIsPayLater] = useState<boolean>(false);

  const [submitting, setSubmitting] = useState<string | null>(null);

  useEffect(() => {
    if (!user.merchant?.id) return;
    axiosInstance
      .get(
        `/settings/merchant?filter=id||$eq||${user.merchant?.id}`
      )
      .then(async (response) => {
        setBusinessName(response.data[0]?.companyName || "");
        setAddress(response.data[0]?.address || "")
        setCity(response.data[0]?.city || "");
        setCounty(response.data[0]?.country || "");
        setPostalCode(response.data[0]?.zipCode);
        setInvoiceEmail(response.data[0]?.merchantAdminEmail);
      })
  }, [user.merchant?.id]);

  const getErrors = () => {
    const errors: { [key: string]: string } = {};
    if (!cardNumber && !isPayLater) {
      errors.cardNumber = "Card number is required";
    } else if (!!cardNumber &&
      !/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}(?:2131|1800|35\d{3})\d{11})$/m.test(
        cardNumber
      )
    ) {
      errors.cardNumber = "Invalid card number";
    }
    if (!year && !isPayLater) {
      errors.year = "year is required";
    } else if (!!year && year < currentYear) {
      errors.year = "Invalid year";
    }
    if (!month && !isPayLater) {
      errors.month = "Month is required";
    } else if (!!month && month < currentMonth && year === currentYear) {
      errors.month = "Invalid month";
    }
    if (!paymentCounty && !isPayLater) {
      errors.paymentCounty = "Payment county is required";
    }
    if (!businessName) {
      errors.businessName = "Business name is required";
    }
    if (!address) {
      errors.address = "Address is required";
    }
    if (!county) {
      errors.county = "County is required";
    }
    if (!city) {
      errors.city = "City is required";
    }
    if (!postalCode) {
      errors.postalCode = "Postal code is required";
    } else if (!/^[0-9]*$/.test(postalCode)) {
      errors.postalCode = "Invalid postal code";
    }
    if (!invoiceEmail) {
      errors.invoiceEmail = "Invoice email is required";
    } else if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        invoiceEmail
      )
    ) {
      errors.invoiceEmail = "Invalid invoice email";
    }
    console.log("🚀 ~ file: index.tsx:121 ~ getErrors ~ errors:", errors);
    return errors;
  };

  const errors = getErrors();
  // const isValid = !Object.keys(errors).length;

  const dispatch = useAppDispatch();

  const handleSubmit = async (type: string) => {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).map((key: string) => toast.error(errors[key]));
      return;
    }
    const isQuote = type === "quote";
    const samePayment =
      lastPayment &&
      lastPayment.cardNumber === cardNumber &&
      lastPayment.county === paymentCounty &&
      lastPayment.month === month &&
      lastPayment.year === year;
    let lastPaymentId = lastPayment?.id || null;
    setSubmitting(type);
    if (!samePayment && !isPayLater) {
      const paymentBody = {
        cardNumber,
        month,
        year,
        county: paymentCounty,
        user: { id: user.id },
      };
      try {
        const { status, data } = await axiosInstance.post(
          `/payment`,
          paymentBody
        );
        if (status === 201) {
          lastPaymentId = data.id;
          dispatch(updateField({ lastPayment: data }));
        } else return;
      } catch (error) {
        console.log(error);
        setSubmitting(null);
        toast.error("Failed to create payment");
        return;
      }
    }
    let orderBody: OrderProps = {
      poNumber: poNumber || "",
      businessName,
      address,
      county,
      postalCode,
      city,
      invoiceEmail,
      isQuote,
      user: { id: user.id },
    };
    if (!isPayLater) {
      orderBody = { ...orderBody, payment: { id: lastPaymentId || 0 } };
    }

    try {
      const { status, data } = await axiosInstance.post(`/order`, orderBody);
      if (status === 201) {
        const orderId = data.id;
        const bulk = user.carts.map((cart) => {
          const {
            articleWord,
            contentByLink,
            customerId,
            price,
            restricted,
            backlinkId,
          } = cart;
          return {
            articleWord,
            contentByLink,
            customerId,
            price,
            restricted,
            backlink: { id: backlinkId },
            order: { id: orderId },
            user: { id: user.id },
          };
        });
        try {
          const { status } = await axiosInstance.post(
            `/order-backlink/bulk`,
            { bulk }
          );
          if (status === 201) {
            try {
              const { status } = await axiosInstance.delete(
                `/settings/cart/user/${user.id}`
              );
              if (status === 200) {
                toast.success("Order created");
                setBusinessName("");
                setAddress("");
                setCounty("");
                setCity("");
                setPostalCode("");
                setInvoiceEmail("");
                dispatch(updateField({ carts: [] }));
                dispatch(clearCartLink());
              }
            } catch (err) {
              toast.error("Something went wrong");
              console.log(err);
              return;
            } finally {
              setSubmitting(null);
            }
          }
        } catch (error) {
          toast.error("Something went wrong");
          console.log(error);
          setSubmitting(null);
          return;
        }
      }
    } catch (error) {
      if (Object.keys(errors).length > 0) {
        Object.keys(errors).map((key: string) => toast.error(errors[key]));
      }
      toast.error("Couldn't create order");
      setSubmitting(null);
    }
  };

  const handleChangePayLater = (e: any) => {
    setCardNumber(e.target.checked ? "" : lastPayment?.cardNumber || "")
    setMonth(e.target.checked ? 0 : shouldDefaultToCurrentDate ? lastPayment?.month : currentMonth)
    setYear(e.target.checked ? 0 : shouldDefaultToCurrentDate ? lastPayment?.year : currentYear)
    setPaymentCounty(e.target.checked ? "" : lastPayment?.county || "");
    setIsPayLater(e.target.checked);
  }

  if (!user.carts.length) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <div className="-mt-3 border-b grid grid-cols-marketTopBar items-center pb-2">
        <div className="flex flex-col">
          <p className="text-sm">Dashboard</p>
          <div className="flex items-center space-x-2 text-xs text-gray-500">
            <Link to="/dashboard">Dashboard</Link>
            <Link to="/dashboard/marketplace" className="flex items-center">
              <ChevronDoubleRightIcon className="w-3" /> Marketplace
            </Link>
            <Link
              to="/dashboard/marketplace/order/billing"
              className="flex items-center"
            >
              <ChevronDoubleRightIcon className="w-3" /> Order
            </Link>
            <Link
              to="/dashboard/marketplace/order/billing"
              className="flex items-center"
            >
              <ChevronDoubleRightIcon className="w-3" /> Billing
            </Link>
          </div>
        </div>
        <div className="flex items-center justify-center gap-3"></div>
        <div className="flex itmes-center justify-end gap-3"></div>
      </div>
      <h1 className="text-2xl font-bold my-3">Billing</h1>
      <div className="">
        <div className="p-8 rounded-lg bg-white shadow-lg">
          <div className="grid grid-cols-2 gap-10">
            <div>
              <h2 className="text-xl font-bold">Media</h2>
              <p className="text-gray-500">
                Make sure the information is up to date and correct.
              </p>
              <form action="" className="grid grid-cols-2 gap-3 w-full mt-5">
                <InputField
                  label="Business Name"
                  placeholder="Enter business name"
                  value={businessName}
                  disabled={!!submitting}
                  onChange={({ target: { value } }) => setBusinessName(value)}
                />
                <InputField
                  label="Address"
                  placeholder="Address"
                  disabled={!!submitting}
                  value={address}
                  onChange={({ target: { value } }) => setAddress(value)}
                />
                <InputField
                  label="County"
                  placeholder="County"
                  disabled={!!submitting}
                  value={county}
                  onChange={({ target: { value } }) => setCounty(value)}
                />
                <InputField
                  label="Postal Code"
                  placeholder="Postal Code"
                  disabled={!!submitting}
                  value={postalCode}
                  onChange={({ target: { value } }) => setPostalCode(value)}
                />
                <InputField
                  label="City"
                  placeholder="City"
                  disabled={!!submitting}
                  value={city}
                  onChange={({ target: { value } }) => setCity(value)}
                />
                <InputField
                  label="Invoice Email"
                  placeholder="Invoice Email"
                  disabled={!!submitting}
                  value={invoiceEmail}
                  onChange={({ target: { value } }) => setInvoiceEmail(value)}
                />
              </form>
              <div className="flex flex-row w-full gap-3">
                <button
                  className="btnPrimary mt-5 text-white w-32"
                  type="button"
                  disabled={!!submitting}
                  onClick={() => handleSubmit("order")}
                >
                  {submitting === "order" ? (
                    <CircularProgress color="primary" size={20} />
                  ) : (
                    "Create Order"
                  )}
                </button>
                <button
                  className="btnPrimary mt-5 text-white w-32"
                  type="button"
                  disabled={!!submitting}
                  onClick={() => handleSubmit("quote")}
                >
                  {submitting === "quote" ? (
                    <CircularProgress color="primary" size={20} />
                  ) : (
                    "Create Quote"
                  )}
                </button>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-start">
                <div>
                  <h2 className="text-xl font-bold">Payment Details</h2>
                  <p className="text-gray-500">
                    All transations are secured and encrypted.
                  </p>
                </div>
                <div>
                  <div className="flex items-center justify-end gap-3">
                    <p className="font-semibold">Credit/Debit Card</p>
                    <p className="font-semibold text-gray-400">Paypal</p>
                  </div>
                  <div className="flex">
                    <input className="mr-1" defaultChecked={isPayLater} value="payLater" type="checkbox" onChange={handleChangePayLater} />
                    <span> Pay Later </span>
                  </div>
                </div>
              </div>
              <form action="" className="mt-5 w-full">
                <InputField
                  label="Card number"
                  disabled={isPayLater ? isPayLater : !!submitting}
                  placeholder="Visa/Credit/Debit card"
                  value={cardNumber}
                  onChange={({ target: { value } }) => setCardNumber(value)}
                />
                <div className="flex items-center justify-between my-3 gap-3">
                  <InputField
                    label="Month"
                    placeholder="Month"
                    disabled={isPayLater ? isPayLater : !!submitting}
                    type="number"
                    value={month}
                    step={1}
                    onChange={({ target: { value } }) => setMonth(+value)}
                  />
                  <InputField
                    label="Year"
                    placeholder="Year"
                    disabled={isPayLater ? isPayLater : !!submitting}
                    type="number"
                    value={year}
                    step={1}
                    onChange={({ target: { value } }) => setYear(+value)}
                  />
                </div>
                <InputField
                  label="County"
                  placeholder="County"
                  disabled={isPayLater ? isPayLater : !!submitting}
                  value={paymentCounty}
                  onChange={({ target: { value } }) => setPaymentCounty(value)}
                />
              </form>
              <p className="my-3 text text-gray-500">
                By providing your card information, you allow link headquarter
                APPs to charge your card future payments in accordance with
                their teams.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
