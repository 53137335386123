import React from "react";

interface Props {
  type?: string;
}

export default function Status({ type }: Props) {

  var color = "text-gray-600"
  switch (type) {
    case "Approved":
      color = "text-red-600"
      break;
    case "Pending":
      color = "text-yellow-600"
      break;
    case "Rejected":
      color = "text-emerald-600"
      break;
    case "InProcess":
      color = "text-indigo-600"
      break;
  }
  const className = "p-2 font-semibold rounded-md text-center px-4 w-24 " + color;
  console.log(type);
  return (
    <>
      <div className={className}>
        {type}
      </div>
    </>
  );
}
