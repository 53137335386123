import { axiosInstance } from "./fetch-api";

interface getOrderStatusCountResponse {
  data: Array<{
    isQuote: boolean,
    orderStatusId: number,
    count: number;
  }>
}

interface getOrderStatusCountReqestProps {
  userId: number;
}

export const getOrderStatusCount = async (args: any): Promise<getOrderStatusCountResponse> => {
  try {
    const res: getOrderStatusCountResponse = await axiosInstance.get(`/order/statusCount/${args.userId}`);
    return res;
  }
  catch (e) {
    console.error("Error in getOrderStatusCount: ", e);
    return { data: [] };
  }
}