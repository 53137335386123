import React, { useState } from "react";
import {
  Bars3BottomLeftIcon,
  Bars3Icon,
  BellAlertIcon,
  ChatBubbleBottomCenterTextIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { BiLogOut } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Cart from "../../components/cards/Cart";
import { DropdownModal } from "../../components/common";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import Topbar from "../../components/Menu/Topbar";
import { StorageKeys } from "../../constants/base.const";
import { useAppDispatch, useAppSelector } from "../../hooks/rtkHooks";
import { clearState } from "../../store/slices/userSlice";
import { setStorageValue } from "../../util/localStorage.util";

interface Props {
  show_navlabel: boolean;
  toggleNavbar: () => void;
}

export default function DashboardHead({ show_navlabel, toggleNavbar }: Props) {
  const user = useAppSelector((state) => state?.user?.user);
  const dispatch = useAppDispatch();
  const onLogOut = () => {
    setStorageValue(StorageKeys.Token, "");
    dispatch(clearState());
  };
  const cartLinks = useSelector((state: any) => state?.cartLink);

  const [cartOpen, setCartOpen] = useState(false);

  return (
    <div className="fixed top-0 w-full z-40">
      <header
        className={
          show_navlabel
            ? "grid grid-cols-body"
            : "grid grid-cols-bodyClosed bg-dark text-white"
        }
      >
        {show_navlabel ? (
          <div className="flex px-5 pt-3 items-center justify-between">
            {show_navlabel && (
              <Link to="/dashboard">
                <img src="/logoWhite.png" className="h-16" alt="" />
              </Link>
            )}
            <Bars3Icon
              onClick={toggleNavbar}
              className="cursor-pointer w-6 text-white"
            />
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <Bars3BottomLeftIcon
              onClick={toggleNavbar}
              className="cursor-pointer w-6"
            />
          </div>
        )}

        <div
          className={
            show_navlabel
              ? "flex items-center justify-between px-3 border-b bg-white"
              : "flex items-center gap-7 py-2"
          }
        >
          {!show_navlabel && (
            <Link to="/dashboard">
              <img src="/logoWhite.png" className="h-10 mr-3" alt="" />
            </Link>
          )}
          <div className="flex items-center flex-1 justify-between">
            {show_navlabel ? (
              <div className="flex flex-col justify-center">
                <p className="text-xl font-bold">Dashboard</p>
                <Breadcrumbs />
              </div>
            ) : (
              <Topbar show_navlabel={undefined} />
            )}
            <div className="flex items-center justify-end gap-4 pr-3 lg:pr-6">
              <div className="flex items-center justify-end gap-3">
                <DropdownModal
                  Icon={ChatBubbleBottomCenterTextIcon}
                  title={""}
                  className={""}
                  modalStyle={""}
                  checkbox={false}
                  dropdown={false}
                >
                  <div className="text-black">Messages</div>
                </DropdownModal>
                <DropdownModal
                  Icon={BellAlertIcon}
                  title={""}
                  className={""}
                  modalStyle={""}
                  checkbox={false}
                  dropdown={false}
                >
                  <div className="text-black">Notifications</div>
                </DropdownModal>
                <DropdownModal
                  showValue
                  iconValue={cartLinks.length}
                  modalStyle="md:w-[450px]"
                  Icon={ShoppingCartIcon}
                  title={""}
                  className={""}
                  checkbox={false}
                  dropdown={false}
                  open={cartOpen}
                  setOpen={(status: boolean) => setCartOpen(status)}
                >
                  <Cart
                    setCartOpen={(status: boolean) => setCartOpen(status)}
                  />
                </DropdownModal>
              </div>
              <div className="navDropdownButton relative ml-5">
                <div className="navDropdown flex-col w-[200px] absolute top-0 right-0 mt-11 p-2 bg-white text-black dark:bg-blue rounded-md capitalize shadow-xl shadow-black/25">
                  <div>
                    <Link
                      to={"/dashboard/account-settings"}
                      className="pageLink hover:bg-gray-200"
                    >
                      <FiSettings />
                      <span className="ml-3">Account Settins</span>
                    </Link>
                  </div>

                  <div>
                    <Link
                      to={""}
                      className="text-red-400 pageLink hover:bg-gray-200"
                      onClick={onLogOut}
                    >
                      <BiLogOut />
                      <span className="ml-3">Logout</span>
                    </Link>
                  </div>
                </div>
                <div className="hidden md:flex">
                  <UserCircleIcon className="w-12 mr-1 text-secondary" />
                  <span>
                    <p className="font-semibold text-lg">
                      {!!user?.merchant
                        ? user?.name + "(" + user?.merchant.value + ")"
                        : user?.name}
                    </p>
                    <p className="text-sm -mt-1 text-gray-500">{user?.email}</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
