import { UserCircleIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { BiLogOut } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { StorageKeys } from "../../constants/base.const";
import { RoleType } from "../../constants/base.enum";
import { useAppDispatch, useAppSelector } from "../../hooks/rtkHooks";
import { clearState } from "../../store/slices/userSlice";
import { setStorageValue } from "../../util/localStorage.util";
import Dropdown from "./Dropdown";
import useSideMenu from "./Menu";

interface Props {
  show_navlabel?: boolean;
}

const Sidebar = ({ show_navlabel }: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const location = useLocation();
  const { data: sideMenu } = useSideMenu();
  const currentPath = location.pathname;

  const role = user.roles[0];

  const [activeMenuItem, setActiveMenuItem] = useState("Dashboard");

  useEffect(() => {
    const path = window.location.pathname;
    const label = sideMenu.find((item) => item?.to === path);
    if (label) {
      setActiveMenuItem(label.label);
    }
  }, [sideMenu]);

  const onLogOut = () => {
    setStorageValue(StorageKeys.Token, "");
    dispatch(clearState());
  };
  return (
    <div className="p-3 pt-24 bg-dark text-white shadow-r">
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="flex md:hidden mb-5 mt-2">
            <UserCircleIcon className="w-12 mr-1 text-secondary" />
            <span>
              <p className="font-semibold text-lg">{user?.name}</p>
              <p className="text-sm -mt-1 text-gray-500">{user?.email}</p>
            </span>
          </div>
          {sideMenu.map((item) => {
            const { label, to, Icon, children, permission } = item;
            if (role) {
              if (role.type === RoleType.SuperAdmin) {
                if (label === "Marketplace") {
                  return <React.Fragment key="react.fragment-3"></React.Fragment>;
                }
              } else {
                if (!!permission) {
                  const ownPermission = role.permissions.find((pm) => pm.name === permission);
                  if (!!!ownPermission) {
                    return <React.Fragment key={"react.fragment-" + label}></React.Fragment>;
                  }
                }
              }

            } else {
              return <React.Fragment key="react.fragment-2"></React.Fragment>;
            }

            return (
              <div key={`sidebar-item-${item.label}`} className="sidebar-item">
                {item?.children ? (
                  <Dropdown
                    show_navlabel={!!show_navlabel}
                    label={label}
                    activeMenuItem={activeMenuItem}
                    setActiveMenuItem={setActiveMenuItem}
                    to={to}
                    Icon={Icon}
                    options={children}
                  />
                ) : (
                  <Link
                    className={`pageLink ${to === currentPath
                      ? show_navlabel
                        ? "bg-secondary hover:text-white"
                        : "flex items-center justify-center"
                      : show_navlabel
                        ? "hover:text-white"
                        : "flex items-center justify-center "
                      }`}
                    title={label}
                    to={to}
                    onClick={() => setActiveMenuItem(item.label)}
                  >
                    <Icon />
                    {show_navlabel && <span className="ml-3">{label}</span>}
                  </Link>
                )}
              </div>
            );
          })}
        </div>

        <div>
          <div>
            {/* <Link
              to={"/dashboard/account-settings"}
              className={
                show_navlabel
                  ? "pageLink"
                  : "pageLink flex items-center justify-center"
              }
            >
              <FiSettings />
              {show_navlabel && <span className="ml-3">Account Settings</span>}
            </Link> */}
          </div>

          <div>
            <Link
              to={""}
              className={
                show_navlabel
                  ? "text-red-400 pageLink"
                  : "text-red-400 pageLink flex items-center justify-center"
              }
              onClick={onLogOut}
            >
              <BiLogOut />
              {show_navlabel && <span className="ml-3">Logout</span>}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
