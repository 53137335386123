import React from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function Tab2({
  formViewData,
  setFormViewData,
  termsOptions,
  paymentTypeOptions,
}) {
  return (
    <div>
      <div className="grid grid-cols-4">
        <FormControl margin="normal" sx={{ minWidth: 120 }}>
          <InputLabel id="demo-simple-select-label-terms">Terms</InputLabel>
          <Select
            id="demo-simple-select-terms"
            labelId="demo-simple-select-label"
            value={formViewData.termsId?.toString() || ""}
            label="Terms"
            onChange={(event) => {
              setFormViewData({
                ...formViewData,
                termsId: parseInt(event.target.value),
              });
            }}
          >
            {termsOptions.map((opt) => (
              <MenuItem key={`terms-item-` + opt.id} value={opt.id}>
                {opt.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="grid grid-cols-4 gap-x-4">
        <FormControl
          margin="normal"
          sx={{ minWidth: 120, backgroundColor: "white" }}
        >
          <InputLabel
            id="demo-simple-select-label-paymentType"
            sx={{ backgroundColor: "white" }}
          >
            CreditCard on File
          </InputLabel>
          <Select
            labelId="demo-simple-select-label-paymentType"
            id="demo-simple-select-paymentType"
            value={formViewData.paymentTypeId?.toString() || ""}
            label="CreditCard on File"
            onChange={(event) => {
              setFormViewData({
                ...formViewData,
                paymentTypeId: parseInt(event.target.value),
              });
            }}
            sx={{ backgroundColor: "white" }}
          >
            {paymentTypeOptions.map((opt) => (
              <MenuItem key={`paymentType-item-` + opt.id} value={opt.id}>
                {opt.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="creditCardNumber"
          label="Credit Card Number"
          variant="outlined"
          defaultValue={formViewData.creditCardNumber}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              creditCardNumber: event.target.value,
            })
          }
        />
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="expiration"
          label="Expiration"
          variant="outlined"
          defaultValue={formViewData.expiration}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              expiration: event.target.value,
            })
          }
        />
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="cvv"
          label="CVV"
          variant="outlined"
          defaultValue={formViewData.cvv}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              cvv: event.target.value,
            })
          }
        />
      </div>
      <div className="mt-3">
        <FormControlLabel
          control={<Checkbox defaultChecked={false} />}
          label="Billing address same as above"
        />
      </div>
      <div className="grid grid-cols-4 gap-x-4">
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="billAddress"
          label="Address"
          variant="outlined"
          defaultValue={formViewData.billAddress}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              billAddress: event.target.value,
            })
          }
        />
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="billCity"
          label="City"
          variant="outlined"
          defaultValue={formViewData.billCity}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              billCity: event.target.value,
            })
          }
        />
        <TextField
          margin="normal"
          sx={{ backgroundColor: "white" }}
          id="billState"
          label="State"
          variant="outlined"
          defaultValue={formViewData.billState}
          onChange={(event) =>
            setFormViewData({
              ...formViewData,
              billState: event.target.value,
            })
          }
        />
        <div className="flex">
          <TextField
            margin="normal"
            sx={{ backgroundColor: "white" }}
            id="billZipCode"
            label="ZIP Code"
            variant="outlined"
            defaultValue={formViewData.billZipCode}
            onChange={(event) =>
              setFormViewData({
                ...formViewData,
                billZipCode: event.target.value,
              })
            }
          />
          <TextField
            margin="normal"
            sx={{ backgroundColor: "white" }}
            id="billCountry"
            label="Country"
            variant="outlined"
            defaultValue={formViewData.billCountry}
            onChange={(event) =>
              setFormViewData({
                ...formViewData,
                billCountry: event.target.value,
              })
            }
          />
        </div>
      </div>
    </div>
  );
}
