import {
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  styled,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { axiosInstance } from "../../api/fetch-api";
import { UpdatedResponseStatus } from "../../constants/base.const";
import {
  DocumentType,
  MerchantBusinessType,
  MerchantBuyingType,
  RoleType,
} from "../../constants/base.enum";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAppDispatch, useAppSelector } from "../../hooks/rtkHooks";
import { CommonOption } from "../../interfaces/common.type";
import { updateField } from "../../store/slices/userSlice";
import StarRating from "../../components/common/StarRating";
import { InputField } from "../../components/common";
import Tabs from "./Tabs";
import Tab1 from "./Tabs/Tab1";
import Tab2 from "./Tabs/Tab2";
import Tab3 from "./Tabs/Tab3";
import { PencilIcon } from "@heroicons/react/24/solid";

interface SettingItemProps {
  editing: any;
  setEditing: (value: any) => void;
  name: string;
  label: string;
  user: any;
  icon: any;
  hidden?: any;
}

interface merchantDtoProps {
  id?: number;
  merchantAdminEmail: string;
  companyName: string;
  firstName: string;
  lastName: string;
  adminPhone?: string;
  mobilePhone?: string;
  companyNumber?: string;
  extension?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  merchantComments?: string;
  termsId?: number;
  paymentTypeId?: number;
  creditCardNumber?: string;
  expiration?: string;
  cvv?: string;
  isBillingSameAddress: boolean;
  billAddress?: string;
  billCity?: string;
  billState?: string;
  billZipCode?: string;
  billCountry?: string;
  documentType?: string;
  businessType?: string;
  buying?: string;
  signedOnDate?: string;
  signedBy?: string;
  capturedIp?: string;
  documentLink?: string;
}

const businessTypeOption = [
  { label: MerchantBusinessType.Agency },
  { label: MerchantBusinessType.Media },
  { label: MerchantBusinessType.Affiliate },
  { label: MerchantBusinessType.Brand },
  { label: MerchantBusinessType.Operator },
];

const buyingTypeOption = [
  { label: MerchantBuyingType.Yes },
  { label: MerchantBuyingType.No },
];

const documentTypeOption = [
  { label: DocumentType.TermsAndCondition },
  { label: DocumentType.Privacy },
];

function SettingItem({
  editing,
  setEditing,
  name,
  label,
  user,
  icon,
  hidden,
}: SettingItemProps) {
  const [value, setValue] = useState(hidden ? "" : user[name]);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  const handleEdit = (field: any) => () => {
    setEditing(field);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmitting(true);
    axiosInstance
      .patch(`/users/${user.id}`, { id: user.id, [name]: value })
      .then(() => {
        toast.success(`${label} updated successfully`);
        if (name !== "password") {
          dispatch(updateField({ [name]: value }));
        }
        setEditing(null);
      })
      .catch((err) => {
        toast.error(`Invalid ${label}`);
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return editing === name ? (
    <form onSubmit={handleSubmit}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>{icon}</Avatar>
        </ListItemAvatar>
        <TextField
          name={name}
          label={label}
          sx={{ my: 1 }}
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type={hidden ? "password" : "text"}
          disabled={submitting}
        />
        <Button
          size="medium"
          variant="outlined"
          color="warning"
          sx={{ ml: 1 }}
          type="submit"
          disabled={submitting}
        >
          Update
        </Button>
        <Button
          type="button"
          size="medium"
          variant="outlined"
          color="error"
          disabled={submitting}
          sx={{ ml: 1 }}
          onClick={() => setEditing(false)}
        >
          Cancel
        </Button>
      </ListItem>
    </form>
  ) : (
    <ListItemButton onClick={handleEdit(name)}>
      <ListItemAvatar>
        <Avatar>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={label}
        secondary={hidden ? "********" : user[name]}
      />
    </ListItemButton>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};
const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 24px ${
    theme.palette.mode === "dark" ? blue[900] : blue[100]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function AccountSettings() {
  const user = useAppSelector((state) => state?.user?.user);
  const [editing, setEditing] = useState<boolean>(false);

  const [formViewData, setFormViewData] = useState<merchantDtoProps>({
    id: undefined,
    merchantAdminEmail: "",
    companyName: "",
    firstName: "",
    lastName: "",
    adminPhone: undefined,
    mobilePhone: "",
    companyNumber: "",
    extension: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    merchantComments: "",
    termsId: undefined,
    paymentTypeId: undefined,
    creditCardNumber: "",
    expiration: "",
    cvv: "",
    isBillingSameAddress: false,
    billAddress: "",
    billCity: "",
    billState: "",
    billZipCode: "",
    billCountry: "",
    documentType: "",
    businessType: "",
    buying: "",
    signedOnDate: format(new Date(), "yyyy-MM-dd"),
    signedBy: "",
    capturedIp: "",
    documentLink: "",
  });
  const [tabValue, setTabValue] = React.useState(0);
  const [termsOptions, setTermsOptions] = useState<CommonOption[]>([]);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState<CommonOption[]>(
    []
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!user.merchant?.id) return;
    axiosInstance
      .get(`/settings/merchant?filter=id||$eq||${user.merchant?.id}`)
      .then((response) => {
        const merchantData: any = response.data[0];
        const merchant: merchantDtoProps = {
          id: response.data[0]?.id,
          merchantAdminEmail: merchantData?.merchantAdminEmail,
          companyName: merchantData?.companyName,
          firstName: merchantData?.firstName,
          lastName: merchantData?.lastName,
          adminPhone: merchantData?.adminPhone,
          mobilePhone: merchantData?.mobilePhone,
          companyNumber: merchantData?.companyNumber,
          extension: merchantData?.extension,
          address: merchantData?.address,
          city: merchantData?.city,
          state: merchantData?.state,
          zipCode: merchantData?.zipCode,
          country: merchantData?.country,
          merchantComments: merchantData?.merchantComments,
          termsId: merchantData?.termsId,
          paymentTypeId: merchantData?.paymentTypeId,
          creditCardNumber: merchantData?.creditCardNumber,
          expiration: merchantData?.expiration,
          cvv: merchantData?.cvv,
          isBillingSameAddress: merchantData?.isBillingSameAddress,
          billAddress: merchantData?.billAddress,
          billCity: merchantData?.billCity,
          billState: merchantData?.billState,
          billZipCode: merchantData?.billZipCode,
          billCountry: merchantData?.billCountry,
          documentType: merchantData?.documentType,
          businessType: merchantData?.businessType,
          buying: merchantData?.buying,
          signedOnDate: merchantData?.signedOnDate,
          signedBy: merchantData?.signedBy,
          capturedIp: merchantData?.capturedIp,
          documentLink: merchantData?.documentLink,
        };
        setFormViewData(merchant);
      });
    axiosInstance.get(`/settings/terms`).then((response) => {
      if (response.data) {
        const tempOptions: CommonOption[] = response.data.map((row: any) => ({
          id: row.id,
          value: row.name,
        }));
        setTermsOptions(tempOptions);
      }
    });
    axiosInstance.get(`/settings/paymentType`).then((response) => {
      if (response.data) {
        const tempOptions: CommonOption[] = response.data.map((row: any) => ({
          id: row.id,
          value: row.name,
        }));
        setPaymentTypeOptions([...[{ id: 0, value: "" }], ...tempOptions]);
      }
    });
  }, [axiosInstance]);

  const handleSaveFormView = async () => {
    const { status: updateStatus } = await axiosInstance.patch(
      `/settings/merchant/${formViewData.id}`,
      {
        ...formViewData,
      }
    );
    if (updateStatus === UpdatedResponseStatus) {
    } else {
      alert("Update failed!");
    }
  };

  const tabs = [
    {
      label: "Merchant Info",
      content: (
        <Tab1 formViewData={formViewData} setFormViewData={setFormViewData} />
      ),
    },
    {
      label: "Billing Info",
      content: (
        <Tab2
          termsOptions={termsOptions}
          paymentTypeOptions={paymentTypeOptions}
          formViewData={formViewData}
          setFormViewData={setFormViewData}
        />
      ),
    },
    {
      label: "Documents",
      content: (
        <Tab3
          businessTypeOption={businessTypeOption}
          documentTypeOption={documentTypeOption}
          buyingTypeOption={buyingTypeOption}
          formViewData={formViewData}
          setFormViewData={setFormViewData}
        />
      ),
    },
  ];

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&:after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  return (
    <>
      <h1 className="text-2xl font-bold">Account Settings</h1>
      <div className="bg-white rounded-xl mt-5 p-6">
        <div className="grid grid-cols-profile gap-10">
          <div>
            <div className="flex flex-col items-center">
              <img src="/user4.png" className="h-32" alt="" />
              <p className="capitalize font-bold text-2xl mt-3">{user?.name}</p>
              <p className="text-gray-400 mb-1">uxamabt111</p>
              <div className="flex items-center gap-1 font-medium text-lg">
                5.0 <StarRating rating={5} />
              </div>
              <div className="flex items-center w-full gap-3 mt-1">
                <div className="bg-gray-100 rounded-full flex-1 w-full">
                  <div className="bg-secondary rounded-full w-7/12 p-1"></div>
                </div>
                <p>99% trust</p>
              </div>
            </div>
          </div>
          <div>
            <div className="pb-3 border-b-2">
              <label className="inputLabel">Setting:</label>
              <div className="flex items-center justify-between text-gray-500 mt-2">
                <p className="flex items-center gap-2">
                  Language:{" "}
                  <span className="text-purple-500 font-semibold">
                    English, US
                  </span>
                  <PencilIcon className="w-3 text-dark cursor-pointer" />
                </p>
                <p className="flex items-center gap-2">
                  Account Status:
                  <FormControlLabel
                    control={<Android12Switch defaultChecked />}
                    label="Active"
                  />
                </p>
                <p className="flex items-center gap-2">
                  Get Notifcations:
                  <Switch defaultChecked color="warning" />
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-6 mt-4">
              <div className="flex flex-col gap-4">
                <InputField
                  label="Company Name"
                  placeholder="company name"
                  value="Test Company"
                />
                <InputField
                  label="Designation"
                  placeholder="Enter your desgination"
                  value="Managing Director"
                />
                <InputField label="Gender" placeholder="Gender" value="Male" />
              </div>
              <div className="flex flex-col gap-4">
                <InputField
                  label="Email"
                  placeholder="email"
                  value={user.email}
                />
                <InputField label="Phone" placeholder="Phone number" />
                <InputField label="Password" value="" />
              </div>
            </div>
          </div>
        </div>
        {/* <div>
          <List style={{ maxWidth: "512px" }}>
            <SettingItem
              editing={editing}
              setEditing={setEditing}
              label={"Name"}
              name={"name"}
              user={user}
              icon={<Person />}
            />
            <SettingItem
              editing={editing}
              setEditing={setEditing}
              label={"Email"}
              name={"email"}
              user={user}
              icon={<Mail />}
            />
            <SettingItem
              editing={editing}
              setEditing={setEditing}
              label={"Password"}
              name={"password"}
              user={user}
              hidden={true}
              icon={<Lock />}
            />
          </List>
        </div> */}
        {user.roles[0].type === RoleType.MerchantAdmin && (
          <div className="mt-10">
            <Box>
              {/* <Tabs
                className="border-b-2 border-secondary"
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                indicatorColor="secondary"
              >
                <Tab
                  icon={<LocalLibraryIcon />}
                  iconPosition="start"
                  label="Merchant Information"
                  {...a11yProps(0)}
                />
                <Tab
                  icon={<RequestQuoteIcon />}
                  iconPosition="start"
                  label="Billing Info"
                  {...a11yProps(1)}
                />
                <Tab
                  icon={<LibraryBooksIcon />}
                  iconPosition="start"
                  label="Documents"
                  {...a11yProps(2)}
                />
              </Tabs> */}
            </Box>
            <Tabs tabs={tabs} />

            <div className="flex justify-end py-2">
              <Button
                variant="contained"
                color="success"
                sx={{ marginRight: "5px" }}
                onClick={handleSaveFormView}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AccountSettings;
