import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { Fragment, useState } from "react";
import { RangeValueType } from ".";

interface DropdownButtonSelectProps {
  title: string;
  Icon: any;
  currentValue?: any | "";
  valueSetter: (value: any) => void;
  name: string;
  options: any[];
}

export function DropdownButtonSelect({
  title,
  Icon,
  currentValue,
  valueSetter,
  name,
  options,
}: DropdownButtonSelectProps) {
  const [value, setValue] = useState(currentValue);

  const handleChange = ({ target: { value } }: any) => {
    setValue(value);
    if (!value) {
      valueSetter(null);
    } else {
      valueSetter(value);
    }
  };

  const handleReset = () => {
    setValue("");
    valueSetter(null);
  };

  return (
    <Menu as="div" className="relative">
      <Menu.Button title="Text Editor Guide">
        <div
          className={`btnSecondary px-3 text-sm flex-nowrap whitespace-nowrap font-medium ${!!currentValue ? "bg-yellow-200" : "bg-white"
            }`}
        >
          {Icon && <Icon className="w-5" />} {title}{" "}
          {!!currentValue && `(${currentValue})`}
          <ChevronDownIcon className="w-4" />
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 top-0 mt-10 bg-white p-5 z-20 shadow-lg shadow-black/15 rounded-lg w-72">
          <div className="w-full">
            {/* <div className="grid grid-cols-2 gap-2"> */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{title}</InputLabel>
              <Select
                name={name}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label={title}
                onChange={handleChange}
              >
                <MenuItem value={""} key={""}>
                  None
                </MenuItem>
                {options.map((opt) => {
                  if (!!opt?.id) {
                    return <MenuItem value={opt.id} key={opt.id}>
                      {opt.name}
                    </MenuItem>
                  } else {
                    return <MenuItem value={opt} key={opt}>
                      {opt}
                    </MenuItem>
                  }
                }
                )}
              </Select>
            </FormControl>
            <button
              className="btnPrimary font-medium w-full text-white mt-3"
              onClick={handleReset}
              disabled={!currentValue}
            >
              Reset
            </button>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

interface DropdownButtonRangeProps {
  currentValue?: RangeValueType;
  valueSetter: (value: RangeValueType) => void;
  Icon: any;
  title?: string | "Price";
}

export function DropdownButtonRange({
  currentValue,
  valueSetter,
  Icon,
  title = "Price",
}: DropdownButtonRangeProps) {
  const [minVal, setMinVal] = useState(`${currentValue?.min}` || "");
  const [maxVal, setMaxVal] = useState(`${currentValue?.max}` || "");

  const isValid: boolean = !!minVal && !!maxVal && +minVal < +maxVal;
  const isSame: boolean =
    minVal === `${currentValue?.min}` && maxVal === `${currentValue?.max}`;

  const handleSubmit = () => {
    if (isValid) {
      valueSetter({ min: +minVal, max: +maxVal });
    }
  };

  const handleReset = () => {
    setMinVal("");
    setMaxVal("");
    valueSetter({ min: 0, max: 0 });
  };
  return (
    <Menu as="div" className="relative">
      <Menu.Button title="Text Editor Guide">
        <div
          className={`btnSecondary px-3 text-sm flex-nowrap whitespace-nowrap font-medium ${!!currentValue ? "bg-yellow-200" : "bg-white"
            }`}
        >
          {Icon && <Icon className="w-5" />} {title}{" "}
          {!!currentValue && `(${currentValue.min} - ${currentValue.max})`}
          <ChevronDownIcon className="w-4" />
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 top-0 mt-10 bg-white p-5 z-20 shadow-lg shadow-black/15 rounded-lg w-72">
          <div className="w-full">
            <div className="grid grid-cols-3 gap-2">
              <FormControl fullWidth>
                <TextField
                  value={minVal}
                  label="Min"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  type="number"
                  onChange={({ target: { value } }) => {
                    if (/[0-9]*/.test(`${value}`)) {
                      setMinVal(value);
                    }
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  value={maxVal}
                  label="Max"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  type="number"
                  onChange={({ target: { value } }) => {
                    if (/[0-9]*/.test(`${value}`)) {
                      setMaxVal(value);
                    }
                  }}
                />
              </FormControl>
              <button
                className="btnPrimary font-medium w-full text-white"
                onClick={handleSubmit}
                disabled={!isValid || isSame}
              >
                Search
              </button>
            </div>
            <button
              className="btnPrimary font-medium w-full text-white mt-3"
              onClick={handleReset}
              disabled={!currentValue}
            >
              Reset
            </button>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
