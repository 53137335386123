import { EnvelopeIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { Noop } from "react-hook-form";

interface Props {
  name?: string;
  label?: string;
  onChange?: (...event: any[]) => void;
  onBlur?: Noop;
  type?: React.HTMLInputTypeAttribute | undefined;
  disabled?: boolean;
  placeholder?: string;
  error?: string;
  value?: string | number;
  max?: number;
  min?: number;
  step?: number;
  className?: string;
}

const InputField = ({
  name,
  label,
  onChange,
  onBlur,
  type,
  disabled = false,
  placeholder,
  value,
  max,
  min,
  step = 0,
  error,
  className,
}: Props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="w-full">
      {label && (
        <label className="inputLabel mb-1" htmlFor={name}>
          {label}
        </label>
      )}
      <div className="relative">
        <input
          type={passwordVisible ? "text" : type || "text"}
          disabled={disabled}
          name={name}
          placeholder={placeholder}
          className={`inputField ${className}`}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          max={max}
          min={min}
          step={step}
        />
        {type === "password" && (
          <button
            type="button"
            className="absolute inset-y-0 right-0 px-3 py-2"
            onClick={togglePasswordVisibility}
          >
            {passwordVisible ? (
              <EyeSlashIcon className="h-5 w-5 text-gray-500" />
            ) : (
              <EyeIcon className="h-5 w-5 text-gray-500" />
            )}
          </button>
        )}
        {(type === "email" || type === "username") && (
          <button className="absolute inset-y-0 right-0 px-3 py-2">
            <EnvelopeIcon className="h-5 w-5 text-gray-500" />
          </button>
        )}
        {error && <span className="text-red-500">{error}</span>}
      </div>
    </div>
  );
};

export default InputField;
