import React, { useState } from "react";

export default function FormStep3({ handlePrevious, handleNext }) {
  return (
    <div className="mt-12 grid lg:grid-cols-2 gap-5 lg:gap-16 w-full">
      <div>
        <h1 className="text-5xl font-bold capitalize">
          Area you buying{" "}
          <span className="text-secondary">links already? *</span>
        </h1>
        <p className="py-8 text-gray-200">
          We work with some of the best SEO specialists in the world, but we
          also help clients get started on their link-building journey. Let us
          know about your experience so we can help you in the best possible
          way.
        </p>
        <CheckboxGrid />
        <div className="flex items-center justify-end gap-2">
          <button
            onClick={handlePrevious}
            type="submit"
            className="btnPrimary py-3 px-6 mt-10"
          >
            Back
          </button>
          <button
            onClick={handleNext}
            type="submit"
            className="btnPrimary py-3 px-6 mt-10"
          >
            Next
          </button>
        </div>
      </div>
      <div>
        <img src="/signup.png" alt="" className="mt-12 ml-auto lg:w-10/12" />
      </div>
    </div>
  );
}

const options = ["No", "Yes"];

function CheckboxGrid() {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="grid grid-cols-2 gap-5 text-black text-xl">
      {options.map((option) => (
        <label
          key={option}
          className={`cursor-pointer w-full rounded font-semibold flex items-center justify-between py-3 px-6 ${
            selectedOption === option ? "bg-secondary" : "bg-gray-50"
          }`}
        >
          {option}
          <input
            type="radio"
            className="w-4 h-4 rounded-full"
            name="options"
            value={option}
            checked={selectedOption === option}
            onChange={handleOptionChange}
          />
        </label>
      ))}
    </div>
  );
}
