import React, { useEffect, useState } from "react";

import { GridColDef, GridRowModel, GridRowSelectionModel, GridRowsProp } from "@mui/x-data-grid-pro";
import { axiosInstance } from "../../api/fetch-api";
import CrudGrid from "../../components/CrudGrid/CrudGrid";
import EditToolbar, { EditToolbarProps } from "../../components/EditToolbar";
import { CreatedResponseStatus, StorageKeys, UpdatedResponseStatus } from "../../constants/base.const";
import { PermissionEnum, RoleType } from "../../constants/base.enum";
import { useAppSelector } from "../../hooks/rtkHooks";
import { CommonOption } from "../../interfaces/common.type";

export default function Users() {
  // all users

  const options = useAppSelector((state) => state.role.roles);
  const role = useAppSelector((state) => state.user.user.roles[0]);
  const user = useAppSelector((state) => state.user.user);

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [merchantOptions, setMerchantOptions] = useState<CommonOption[]>([]);
  const [loadingTable, setLoadingTable] = useState(false);

  const defaultColumnDefs: GridColDef[] = [
    {
      field: "customId",
      headerName: "Sr#",
      editable: false,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "name",
      headerName: "Username",
      editable: true,
      type: "string",
    },
    {
      field: "email",
      headerName: "Email",
      editable: true,
      type: "string",
    },
    {
      field: "password",
      headerName: "Password",
      editable: true,
      type: "string",
    },
    {
      field: "roles",
      headerName: "Role",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.at(0)?.id || null,
      valueSetter: (opt) => ({
        ...opt?.row,
        roleIds: [opt?.value]
      }),
      valueOptions: options.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    },
    {
      field: "merchant",
      headerName: "Merchant",
      editable: true,
      type: "singleSelect",
      valueGetter: (opt) => opt?.value?.id || "",
      valueSetter: (opt) => ({
        ...opt?.row,
        merchantId: opt?.value
      }),
      valueOptions: merchantOptions.map((opt: CommonOption) => ({
        value: opt.id,
        label: opt.value,
      })),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      editable: true,
      type: "date",
      valueGetter: (params) => new Date(params.value),
    }
  ];

  // load users only in this component
  useEffect(() => {
    axiosInstance.get(
      `/settings/merchant`
    ).then(async (response) => {
      if (response.data) {
        const tempOptions: CommonOption[] = response.data.map((row: any, index: number) => ({ id: row.id, value: row.company }));
        setMerchantOptions(tempOptions);
      }
    });
    setLoadingTable(true);
    if (role.type === RoleType.SuperAdmin) {
      axiosInstance.get(`/users?join=roles&join=merchant`).then(({ data }) => {
        const rowsWithId = data.map((row: any, index: number) => ({
          ...row,
          customId: index + 1,
        }));
        setRows(rowsWithId);
        setLoadingTable(false);
      });
    }
    if (role.type === RoleType.MerchantAdmin) {
      axiosInstance.get(`/users?join=roles&join=merchant&filter=merchant.id||$eq||${user.merchant?.id}`).then(({ data }) => {
        const rowsWithId = data.map((row: any, index: number) => ({
          ...row,
          customId: index + 1,
        }));
        setRows(rowsWithId);
        setLoadingTable(false);
      });
    }
  }, [role, user.merchant]);

  const processRowUpdate = async (
    newRow: GridRowModel,
    oldRow: GridRowModel
  ) => {
    setLoadingTable(true);
    const updatedFields = Object.keys(newRow).filter(
      (fieldName) =>
        JSON.stringify(newRow[fieldName]) !== JSON.stringify(oldRow[fieldName])
    );
    const updatedRow = newRow;
    const filteredRows = rows.filter((row) => selectedRowIds.includes(row.id));
    const selectedRows = filteredRows.length > 0 ? filteredRows : [updatedRow];
    const createdRows: GridRowModel[] = []; // Allow adding only one row at a time.
    const updatedRows: GridRowModel[] = [];
    if (updatedRow.isNew) {
      createdRows.push({
        ...updatedRow,
        isNew: undefined,
        customId: undefined,
      });
    } else {
      selectedRows.forEach((selectedRow) => {
        updatedFields.forEach((updatedField) => {
          selectedRow[updatedField] = updatedRow[updatedField];
        });
        updatedRows.push(selectedRow);
      });
    }
    try {
      if (updatedRows.length) {
        const [update] = updatedRows;
        const userId = update.id;
        const { status: updateStatus, data: updatedData } =
          await axiosInstance.patch(`/users/${userId}`, {
            ...update,
          });
        if (updateStatus === UpdatedResponseStatus) {
          // TODO: define interfaces and set correct types
          setRows((oldData: any) => {
            const idx = oldData.findIndex(
              (item: any) => item.id === updatedData.id
            );
            const newData = [...oldData];
            newData[idx] = { ...updatedData, customId: idx };
            return newData as never[];
          });
        }
      }
      // create
      const createdRow = createdRows.at(0);
      if (!!createdRow) {
        const { status: createStatus, data } = await axiosInstance.post(
          `/auth/register`,
          createdRow
        );
        createStatus === CreatedResponseStatus &&
          setRows(rows.map((row) => {
            if (row.id === newRow.id) {
              return { ...data, customId: rows.length }
            }
            else {
              return row
            }
          }
          ));
      }
      setLoadingTable(false);
      return updatedRow;
    } catch (e) {
      console.log(e);
    }
    setLoadingTable(false);
  }
  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-bold">User Listing</h1>
        </div>
        <div className="mt-6">
          <CrudGrid
            checkboxSelection={true}
            tableKey={StorageKeys.UserTableHeaders}
            defaultColumnDefs={defaultColumnDefs}
            editable={
              role.type === RoleType.SuperAdmin ? true
                : !!role.permissions.find((permission) => permission.name === PermissionEnum.EditUser)
            }
            deletable={
              role.type === RoleType.SuperAdmin ? true
                : !!role.permissions.find((permission) => permission.name === PermissionEnum.DeleteUser)
            }
            loading={loadingTable}
            pinActions={true}
            baseApiPath="users"
            disableMultipleRowSelection={true}
            processRowUpdate={processRowUpdate}
            rows={rows}
            rowModesModel={rowModesModel}
            setRows={setRows}
            setRowModesModel={setRowModesModel}
            setSelectedRowIds={setSelectedRowIds}
            setLoading={setLoadingTable}
            slots={{
              toolbar: (props: EditToolbarProps) =>
                EditToolbar({
                  ...props, defaultValues: {
                    showAddButton:
                      role.type === RoleType.SuperAdmin ? true
                        : !!role.permissions.find((permission) => permission.name === PermissionEnum.CreateUser)
                  }
                }),
            }}
            dataGridKey={""}
          />
        </div>
      </div>
    </>
  );
}
