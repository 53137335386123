import {
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon
} from "@heroicons/react/24/solid";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SaveIcon from '@mui/icons-material/Save';
import { TreeItem, TreeView } from "@mui/lab";
import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../api/fetch-api";
import DataTable from "../../components/common/DataTable";
import { API_URL, CreatedResponseStatus } from "../../constants/base.const";
import { PermissionEnum, RoleType } from "../../constants/base.enum";
import { useAppSelector } from "../../hooks/rtkHooks";
import { Permission } from "../../model/permission.model";
import { Role } from "../../model/role.model";
import { permissionList, RenderTree } from "./Permission";


const roleTypeOption = [
  RoleType.SuperAdmin,
  RoleType.MerchantAdmin,
  RoleType.AgencyAdmin
];

export default function Roles() {

  const role = useAppSelector((state) => state.user.user.roles[0]);

  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedRole, setSelectedRole] = useState<Role>();
  const [editingRoleName, setEditingRoleName] = useState('');
  const [editingRoleType, setEditingRoleType] = useState('');
  const [selectedPermissions, setSelectedPermissions] = React.useState<Permission[]>([]);

  const fetchRoles = () => {
    axiosInstance
      .get(`/settings/role/getAll?join=permissions&sort=id,ASC`)
      .then((res) => setRoles(res.data))
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleEditRole = (roleId: number) => {
    const selRole = roles.find((role: Role) => role.id === roleId);
    setSelectedRole(selRole);
    if (!!selRole) {
      const { name, type, permissions } = selRole;
      setEditingRoleName(name);
      setEditingRoleType(type);
      setSelectedPermissions(permissions);
    }
  }

  const handleDeleteRole = async (roleId: number) => {
    const { status: createStatus } = await axiosInstance.post(
      `${API_URL}/settings/role/deleteOne`,
      { id: roleId }
    );
    if (createStatus === CreatedResponseStatus) {
      alert("Successfully deleted!");
      setRoles(roles.filter((role) => !(role.id === roleId)));
    }
  }

  function getChildById(node: RenderTree, id: number) {
    let array: Permission[] = [];

    //returns an array of nodes ids: clicked node id and all children node ids
    function getAllChild(nodes: RenderTree | null) {
      if (nodes === null) return [];
      array.push({ id: parseInt(nodes.id), name: nodes.name as PermissionEnum });
      if (Array.isArray(nodes.children)) {
        nodes.children.forEach((node) => {
          array = [...array, ...getAllChild(node)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    //returns the node object that was selected
    function getNodeById(nodes: RenderTree, id: string) {
      if (nodes.id === id) {
        return nodes;
      } else if (Array.isArray(nodes.children)) {
        let result = null;
        nodes.children.forEach((node) => {
          if (!!getNodeById(node, id)) {
            result = getNodeById(node, id);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(node, id.toString()));
  }

  function getParentById(rootNode: RenderTree, id: number) {
    let array: Permission[] = [];
    //returns an array of nodes ids: clicked node id and all children node ids
    function getNodeById(nodes: RenderTree, id: string) {
      if (nodes === null) {
        return [];
      } else if (Array.isArray(nodes.children)) {
        nodes.children.forEach((node) => {
          if (node.id === id) {
            if (nodes.id !== "0") {
              array.push({ id: parseInt(nodes.id), name: nodes.name as PermissionEnum });
              getNodeById(rootNode, nodes.id);
            } else {
              return array;
            }
          } else {
            getNodeById(node, id);
          }
        });
      }
      array = array.filter((v, i) => array.indexOf(v) === i);
      return array;
    }
    return getNodeById(rootNode, id.toString());
  }

  function getOnChange(checked: boolean, nodes: RenderTree) {
    //gets all freshly selected or unselected nodes
    const allNode: Permission[] = getChildById(permissionList, parseInt(nodes.id));
    const allParentNode = getParentById(permissionList, parseInt(nodes.id));
    //combines newly selected nodes with existing selection
    //or filters out newly deselected nodes from existing selection
    let array = checked
      ? [...selectedPermissions, ...allNode]
      : selectedPermissions.filter((value) => !!!allNode.find((node) => (node.id === value.id)));

    const filteredParent = allParentNode.filter((parent) => {
      return !!!array.find((item) => item.id === parent.id)
    });

    // if(!checked) {
    //   filteredParent.filter((parent) => {
    //     const allNode = getChildById(permissionList, parent.id).filter((pm) => pm.id !== parent.id );
    //     allNode.map((sNode) => {
    //       if(array.find((selParent) => selParent.id === sNode.id)) {
    //         return false;
    //       }
    //     })
    //   })
    // }

    if (filteredParent.length > 0) {
      array = checked
        ? [...array, ...filteredParent] : [...array]
    }
    setSelectedPermissions(array);
  }

  const RenderTreeWithCheckboxes = (nodes: RenderTree) => {
    // const classes = makeStyles((theme: any) => ({
    //   checkbox: {
    //     "&.MuiCheckbox-root": {
    //       color: "rgba(81, 185, 201, 0.8)"
    //     },
    //     "&.MuiCheckbox-colorSecondary": {
    //       "&.Mui-checked": {
    //         color: "rgba(160, 81, 201, 1)"
    //       }
    //     }
    //   }
    // }));
    return (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        label={
          <FormControlLabel
            label={<>{nodes.name}</>}
            labelPlacement="end"
            control={
              <Checkbox
                checked={selectedPermissions.some((item) => item.id === parseInt(nodes.id))}
                onChange={(event) =>
                  getOnChange(event.currentTarget.checked, nodes)
                }
                //onClick={(e) => e.stopPropagation()}
                className={""}
              />
            }
            key={nodes.id}
          />
        }
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => RenderTreeWithCheckboxes(node))
          : null}
      </TreeItem>
    );
  };

  const handleEditingRoleName = (value: string) => {
    setEditingRoleName(value);
    let tempSelRole = { ...selectedRole };
    tempSelRole.name = value;
    setSelectedRole(tempSelRole as Role);
  }

  const handleEditingRoleType = (value: string) => {
    setEditingRoleType(value)
    let tempSelRole = { ...selectedRole };
    tempSelRole.type = value;
    setSelectedRole(tempSelRole as Role);
  }

  const [openModal, setOpenModal] = useState(false);
  const [newRoleName, setNewRoleName] = useState('');
  const [newRoleType, setNewRoleType] = useState<string>(roleTypeOption[0]);


  const handleClose = () => {
    setOpenModal(!openModal);
  }

  const handleAddRole = async () => {
    if (!!!newRoleName || !!!newRoleType) {
      return;
    }
    const { status: createStatus, data } = await axiosInstance.post(
      `${API_URL}/settings/role/createOne`,
      { id: undefined, name: newRoleName, type: newRoleType, permissions: [] }
    );
    if (createStatus === CreatedResponseStatus) {
      const tempRoles: Role[] = [...roles];
      tempRoles.push(data);
    }
    handleClose();
  }

  const handleUpdateRole = async () => {
    if (!!!selectedRole) {
      alert("You should select a role.");
      return;
    }
    const { status: createStatus } = await axiosInstance.post(
      `${API_URL}/settings/role/updateOne`,
      { ...selectedRole, permissions: selectedPermissions.map((permission) => permission.id.toString()) }
    );
    if (createStatus === CreatedResponseStatus) {
      const tempSelRole: Role = selectedRole;
      tempSelRole.permissions = selectedPermissions;
      setSelectedRole(tempSelRole);
      setRoles(roles.map((role, idx) => {
        if (tempSelRole.id === role.id) {
          return tempSelRole;
        }
        else {
          return role;
        }
      }));
      alert("Successfully updated!");
    } else {
      alert("Update failed!");
    }
  }

  const style = {
    maxHeight: "100%",
    overflow: "auto",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    whiteSpace: "pre-wrap"
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" marginBottom={"10px"}>
            Add Role
          </Typography>
          <Stack direction="column" spacing={2} className='items-center'>
            <TextField
              required
              id="create-role-name"
              label="Name"
              value={newRoleName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNewRoleName(event.target.value);
              }}
            />
            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <InputLabel id="create-role-type-input-label">Selected Type</InputLabel>
              <Select
                labelId="create-role-type-select-label"
                id="create-role-type-select-id"
                value={newRoleType}
                label="Selected Type"
                onChange={(event: SelectChangeEvent) => { setNewRoleType(event.target.value) }}
                required
              >
                {/* <MenuItem key="create-roletype-menuitem-none" value="">
                  <em>None</em>
                </MenuItem> */}
                {roleTypeOption.map((type) => (
                  <MenuItem key={`create-roletype-menuitem-${type}`} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="warning"
              startIcon={<SaveIcon />}
              onClick={() => handleAddRole()}
            >Save
            </Button>
          </Stack>
        </Box>
      </Modal>

      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-bold">User Roles</h1>
        <div className="flex items-center justify-end gap-2">
          <button className="btnSecondary">Reset</button>
          <button className="btnPrimary">Search</button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-5 mt-5">
        <div className="p-3 bg-white rounded">
          <div className="w-full flex items-center mt-2 mb-5">
            <TextField
              required
              id="outlined-required"
              label="Name"
              value={editingRoleName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleEditingRoleName(event.target.value);
              }}
            />
            <FormControl sx={{ m: 1, minWidth: 150 }}>
              <InputLabel id="demo-simple-select-helper-label">Selected Type</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={editingRoleType}
                label="Selected Type"
                onChange={(event: SelectChangeEvent) => { handleEditingRoleType(event.target.value) }}
                required
              >
                <MenuItem key="merchant-menuitem-none" value="">
                  <em>None</em>
                </MenuItem>
                {roleTypeOption.map((type) => (
                  <MenuItem key={`merchant-menuitem-${type}`} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {(role.type === RoleType.SuperAdmin ? true
              : !!role.permissions.find((permission) => permission.name === PermissionEnum.EditRole)) &&
              <button className="btnPrimary bg-green-500 py-1 px-3" onClick={handleUpdateRole}>
                Save Role
              </button>
            }
          </div>
          <div>
            <h2 className="text-lg font-bold">Manage Permissions</h2>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpanded={["0", "1", "3", "4", "9", "14", "19", "20", "25", "30", "35", "40", "45", "47", "52", "58", "63"]}
              defaultExpandIcon={<KeyboardArrowRightIcon />}
            >
              {RenderTreeWithCheckboxes(permissionList)}
            </TreeView>
          </div>
        </div>
        <div className="bg-white rounded">
          <div className="w-full flex items-center justify-between mt-5 mb-2 px-3">
            <h2 className="text-lg font-bold">Manage Role List</h2>
            {(role.type === RoleType.SuperAdmin ? true
              : !!role.permissions.find((permission) => permission.name === PermissionEnum.CreateRole)) &&
              <button className="btnPrimary py-1 px-3" onClick={() => setOpenModal(true)}>
                <PlusCircleIcon className="w-4" />
                Add Role
              </button>
            }
          </div>
          <DataTable
            className="mt-0"
            heads={["Sr#", "Role Name", "Role Type", "Action"]}
            items={roles.map((role: Role, index: number) => ({
              serialNumber: index + 1,
              name: role.name,
              type: (
                <span
                  className={`px-4 py-1 rounded-full w-full text-center font-medium capitalize ${(role.type === "super-admin" && "bg-blue-100") ||
                    (role.type === "agency-admin" && "bg-green-100") ||
                    (role.type === "merchant-admin" && "bg-orange-100")}`}
                >
                  {role.type}
                </span>
              ),
              action: (
                <span className="h-full w-full flex items-center justify-center px-3">
                  <button className="text-gray-400 hover:text-yellow-500" onClick={() => handleEditRole(role.id)}>
                    <PencilSquareIcon className="h-4" />
                  </button>
                  {role.type === RoleType.SuperAdmin ? true
                    : !!role.permissions.find((permission) => permission.name === PermissionEnum.DeleteRole) &&
                    <button className="ml-2 text-gray-400 hover:text-red-400" onClick={() => handleDeleteRole(role.id)}>
                      <TrashIcon className="h-4" />
                    </button>
                  }
                </span>
              ),
            }))}
            tableHeight={40}
            isLoading={false}
          />
        </div>
      </div>
    </>
  );
}
