import { CheckCircleOutline, Error } from "@mui/icons-material";
import ViewListIcon from '@mui/icons-material/ViewList';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import {
  GridColDef,
  GridRowModel,
  GridRowModesModel, GridRowsProp
} from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../api/fetch-api";
import CrudGrid from "../../components/CrudGrid/CrudGrid";
import { StorageKeys } from "../../constants/base.const";
import { ImportTestResult } from "../../constants/base.enum";

export default function ImportLogTable() {


  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [loadingTable, setLoadingTable] = useState(false);

  // fetch orders data from backend
  useEffect(() => {
    setLoadingTable(true);
    axiosInstance
      .get(
        `/settings/importLog?sort=id,DESC`
      )
      .then(async (response) => {

        const rowsWithId = await response.data.map((row: GridRowModel, index: number) => {
          const { ...others } = row;
          return {
            ...others,
            customId: index + 1,
          };
        });
        setRows(rowsWithId);
        setLoadingTable(false);
      })
  }, []);

  // columns definition
  const defaultColumnDefs: GridColDef[] = [
    {
      field: "customId",
      headerName: "Id",
      editable: false,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "testResult",
      headerName: "Test Result",
      editable: false,
      type: "string",
      headerAlign: "left",
      renderCell: (params) => {
        const value = params.value;
        let icon = null;
        let iconColor = "";
        if (value === ImportTestResult.Pass) {
          icon = <CheckCircleOutline />;
          iconColor = "green";
        } else {
          icon = <Error />;
          iconColor = "red";
        }
        return (
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              {icon && (
                <span style={{ color: iconColor, marginRight: 8 }}>{icon}</span>
              )}
            </Grid>
            <Grid item>
              <Typography>{value}</Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "totalRows",
      headerName: "Total Rows",
      editable: false,
      type: "number",
      headerAlign: "left",
    },
    {
      field: "passed",
      headerName: "Passed",
      editable: false,
      type: "number",
      headerAlign: "left",
    },
    {
      field: "failed",
      headerName: "Failed",
      editable: false,
      type: "number",
      headerAlign: "left",
    },
    {
      field: "skipped",
      headerName: "Skipped",
      editable: true,
      type: "number",
      headerAlign: "left"
    },
    {
      field: "submitedDate",
      headerName: "Submited",
      editable: false,
      headerAlign: "left",
      valueFormatter: (params) => {
        const value = params.value;
        const date = new Date(value);
        return format(date, "dd/MM/yyyy hh:mm:ss a");
      },
    },
    {
      field: "comittedDate",
      headerName: "Comitted",
      editable: false,
      headerAlign: "left",
      type: "string",
      valueGetter: (opt) => {
        if (!!opt?.value) {
          return format(new Date(opt.value), "dd/MM/yyyy hh:mm:ss a");
        } else {
          return "Rejected";
        }
      }
    },
    {
      field: "actions",
      headerName: 'Actions',
      renderCell: (cellValues) => {
        return (
          <div>
            <Button
              variant="contained"
              color="info"
              startIcon={<ViewListIcon />}
              onClick={() => {
                setImportLogResult(cellValues?.row.resultLog)
                handleLogPopupClick();
              }}
            >
              View
            </Button>
          </div>
        );
      }
    }
  ];
  const [importLogResult, setImportLogResult] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleLogPopupClick = () => { handleModal() }

  const handleClose = () => setOpenModal(false);

  const handleModal = () => {
    setOpenModal(!openModal);
  };


  const style = {
    maxHeight: "100%",
    overflow: "auto",
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    whiteSpace: "pre-wrap"
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Uploading Status
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {importLogResult}
          </Typography>
        </Box>
      </Modal>

      <div className="bg-white mt-3 h-[calc(100vh-100px)]">
        <CrudGrid
          baseApiPath="orders"
          checkboxSelection={false}
          dataGridKey={""}
          tableKey={StorageKeys.ImportLogTableHeaders}
          defaultColumnDefs={defaultColumnDefs}
          editable={false}
          loading={loadingTable}
          pinActions={undefined}
          rowModesModel={rowModesModel}
          rows={rows}
          setLoading={setLoadingTable}
          setRowModesModel={setRowModesModel}
          setRows={setRows}
        // slots={{
        //   toolbar: EditToolbar,
        // }}
        />
      </div>
    </>
  );
}
