import { GridColDef, GridRowModel, GridRowModesModel, GridRowsProp, GridValidRowModel } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../api/fetch-api";
import CrudGrid from "../../components/CrudGrid/CrudGrid";
import EditToolbar, { EditToolbarProps } from "../../components/EditToolbar";
import { API_URL, CreatedResponseStatus, StorageKeys, UpdatedResponseStatus } from "../../constants/base.const";
import { PermissionEnum, RoleType, SettingOption } from "../../constants/base.enum";
import { useAppDispatch, useAppSelector } from "../../hooks/rtkHooks";
import {
  addSettingOptionType, updateSettingOptionType
} from "../../store/slices/settingsSlice";

export default function Vendor() {
  const dispatch = useAppDispatch();
  const options = useAppSelector((state) => state.settings.vendorOptions);
  const role = useAppSelector((state) => state.user.user.roles[0]);
  // const { create, edit, delete: _delete } = roleSetting;

  const [loadingTable, setLoadingTable] = useState(false);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = useState<GridRowsProp>([]);
  // const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>([]);

  // initialize grid with data from server
  useEffect(() => {
    setLoadingTable(true);
    const rowsWithId = options.map(
      (row, index: number) => ({
        ...row,
        customId: index + 1,
      })
    );
    setRows(rowsWithId);
    setLoadingTable(false);
  }, [options]);

  const processRowUpdate = async (
    newRow: GridRowModel, oldRow: GridValidRowModel
  ) => {
    setLoadingTable(true);
    const payload = { ...newRow, customId: undefined, isNew: undefined };
    try {
      //update
      if (!newRow.isNew) {
        const { status: updateStatus } = await axiosInstance.patch(
          `${API_URL}/settings/vendor/${newRow.id}`,
          payload
        );
        if (updateStatus === UpdatedResponseStatus) {
          setRows(rows.map((row, idx) => {
            if (newRow.id === row.id) {
              dispatch(
                updateSettingOptionType({
                  category: SettingOption.Vendor,
                  code: newRow.code,
                  name: newRow.name,
                  notes: newRow.notes,
                  isDeletable: true,
                  idx: idx,
                })
              );
              return newRow;
            }
            else {
              return row;
            }
          }));
        }
        setLoadingTable(false);
        return newRow;
      } else {
        const { status: createStatus, data } = await axiosInstance.post(
          `${API_URL}/settings/vendor`,
          { ...payload, id: undefined }
        );
        createStatus === CreatedResponseStatus &&
          setRows(rows.map((row) => {
            if (row.id === newRow.id) {
              dispatch(
                addSettingOptionType({
                  category: SettingOption.Vendor,
                  newOption: data,
                })
              );
              return { ...data, customId: rows.length }
            }
            else {
              return row
            }
          }
          ));
        setLoadingTable(false);
        return newRow;
      }
    } catch (e) {
      console.log(e);
    }
  }

  const defaultColumnDefs: GridColDef[] = [
    {
      field: "customId",
      headerName: "Id",
      editable: false,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "code",
      headerName: "Code",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "name",
      headerName: "Name",
      editable: true,
      type: "string",
      headerAlign: "left",
    },
    {
      field: "notes",
      headerName: "Notes",
      editable: true,
      type: "string",
      headerAlign: "left",
    }
  ];

  return (
    <>
      <div className="bg-white mt-3 h-[calc(100vh-100px)]">
        <CrudGrid
          baseApiPath="settings/vendor"
          checkboxSelection={true}
          tableKey={StorageKeys.VendorTableHeaders}
          defaultColumnDefs={defaultColumnDefs}
          editable={
            role.type === RoleType.SuperAdmin ? true
              : !!role.permissions.find((permission) => permission.name === PermissionEnum.EditVendor)
          }
          loading={loadingTable}
          pinActions={true}
          rowModesModel={rowModesModel}
          rows={rows}
          setLoading={setLoadingTable}
          setRowModesModel={setRowModesModel}
          processRowUpdate={processRowUpdate}
          deletable={
            role.type === RoleType.SuperAdmin ? true
              : !!role.permissions.find((permission) => permission.name === PermissionEnum.DeleteVendor)}
          setRows={setRows}
          // setSelectedRowIds={setSelectedRowIds}
          slots={{
            toolbar: (props: EditToolbarProps) =>
              EditToolbar({
                ...props, defaultValues: {
                  showAddButton:
                    role.type === RoleType.SuperAdmin ? true
                      : !!role.permissions.find((permission) => permission.name === PermissionEnum.CreateVendor)
                }
              }),
          }} dataGridKey={""} />
      </div>
    </>
  );
}
