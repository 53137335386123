import { StorageKeys } from "./base.const";

export const TableHeaders: {
  [key: string]: any[]
} = {
  [StorageKeys.OrderTableHeaders]: [
    {
      field: "customId",
      width: 100,
      hidden: false,
      sort: "asc"
    },
    {
      field: "businessName",
      width: 180,
      hidden: false,
      sort: "asc"
    },
    {
      field: "address",
      width: 150,
      hidden: false,
      sort: "asc"
    },

    {
      field: "county",
      width: 100,
      hidden: false,
      sort: "asc"
    },

    {
      field: "city",
      width: 100,
      hidden: false,
      sort: "asc"
    },

    {
      field: "orderStatus",
      width: 100,
      hidden: false,
      sort: "asc"
    },
    {
      field: "createdAt",
      width: 120,
      hidden: false,
      sort: "asc"
    },
    {
      field: "view",
      width: 100,
      hidden: false,
    }
  ],
  [StorageKeys.BacklinkTableHeaders]: [
    {
      field: "customId",
      width: 100,
    },
    {
      field: "vendor",
      width: 100,
    },
    {
      field: "createdDate",
      width: 100,
    },
    {
      field: "update",
      width: 100,
    },
    {
      field: "publication",
      width: 100,
    },
    {
      field: "cost",
      width: 100,
    },
    {
      field: "country",
      width: 100,
    },
    {
      field: "city",
      width: 100,
    },
    {
      field: "state",
      width: 100,
    },
    {
      field: "dr",
      width: 100,
    },
    {
      field: "as",
      width: 100,
    },
    {
      field: "da",
      width: 100,
    },
    {
      field: "alexaRank",
      width: 100,
    },
    {
      field: "monthlyTraffic",
      width: 100,
    },
    {
      field: "spam",
      width: 100,
    },
    {
      field: "referringDomains",
      width: 100,
    },
    {
      field: "isHomepageLink",
      width: 100,
    },
    {
      field: 'category1',
      width: 100,
    },
    {
      field: 'category2',
      width: 100,
    },
    {
      field: 'category3',
      width: 100,
    },
    {
      field: 'category4',
      width: 100,
    },
    {
      field: 'category5',
      width: 100,
    },
    {
      field: 'category6',
      width: 100,
    },
    {
      field: 'category7',
      width: 100,
    },
    {
      field: 'category8',
      width: 100,
    },
    {
      field: 'category9',
      width: 100,
    },
    {
      field: 'category10',
      width: 100,
    },
    {
      field: 'category11',
      width: 100,
    },
    {
      field: 'category12',
      width: 100,
    },
    {
      field: 'category13',
      width: 100,
    },
    {
      field: 'category14',
      width: 100,
    },
    {
      field: 'category15',
      width: 100,
    },
    {
      field: 'niche1',
      width: 100,
    },
    {
      field: 'niche2',
      width: 100,
    },
    {
      field: 'niche3',
      width: 100,
    },
    {
      field: 'niche4',
      width: 100,
    },
    {
      field: 'niche5',
      width: 100,
    },
    {
      field: 'niche6',
      width: 100,
    },
    {
      field: 'multiplier1',
      width: 100,
    },
    {
      field: 'multiplier2',
      width: 100,
    },
    {
      field: 'multiplier3',
      width: 100,
    },
    {
      field: 'multiplier4',
      width: 100,
    },
    {
      field: 'multiplier5',
      width: 100,
    },
    {
      field: 'multiplier6',
      width: 100,
    },
    {
      field: "tatDaysMin",
      width: 100,
    },
    {
      field: "tatDaysMax",
      width: 100,
    },
    {
      field: "image",
      width: 100,
    },
    {
      field: "indexed",
      width: 100,
    },
    {
      field: "sponsored",
      width: 100,
    },
    {
      field: "follow",
      width: 100,
    },
    {
      field: "publicationSamples",
      width: 100,
    },
    {
      field: "notes",
      width: 100,
    },
    {
      field: "status",
      width: 100,
    },
  ],
  [StorageKeys.MarketplaceTableHeaders]: [
    {
      field: "orderBacklinks",
      width: 100,
    },
    {
      field: "publication",
      width: 100,
    },
    {
      field: "language",
      width: 100,
    },
    {
      field: "county",
      width: 100,
    },
    {
      field: "city",
      width: 100,
    },
    {
      field: "orderStatus",
      width: 100,
    },
    {
      field: "dr",
      width: 100,
    },
    {
      field: "as",
      width: 100,
    },
    {
      field: "da",
      width: 100,
    },
    {
      field: "spam",
      width: 100,
    },
    {
      field: "referringDomains",
      width: 100,
    },
    {
      field: "niches",
      width: 100,
    },
  ],
  [StorageKeys.UserTableHeaders]: [
    {
      field: "customId",
      width: 100
    },
    {
      field: "name",
      width: 120
    },
    {
      field: "email",
      width: 150
    },
    {
      field: "password",
      width: 100
    },
    {
      field: "roles",
      width: 150
    },
    {
      field: "merchant",
      width: 150
    },
    {
      field: "createdAt",
      width: 120
    },
  ],
  [StorageKeys.VendorTableHeaders]: [
    {
      field: "customId",
      width: 100
    },
    {
      field: "code",
      width: 100
    },
    {
      field: "name",
      width: 150
    },
    {
      field: "notes",
      width: 200
    }
  ],
  [StorageKeys.CategoryTableHeaders]: [
    {
      field: "customId",
      width: 100
    },
    {
      field: "name",
      width: 250
    },
  ],
  [StorageKeys.ImportLogTableHeaders]: [
    {
      field: "customId",
      width: 100
    },
    {
      field: "testResult",
      width: 100
    },
    {
      field: "totalRows",
      width: 100
    },
    {
      field: "passed",
      width: 100
    },
    {
      field: "failed",
      width: 100
    },
    {
      field: "skipped",
      width: 100
    },
    {
      field: "submitedDate",
      width: 170
    },
    {
      field: "comittedDate",
      width: 170
    },
    {
      field: "actions",
      width: 120
    },
  ],
  [StorageKeys.PriceTierTableHeaders]: [
    {
      field: "customId",
      width: 70
    },
    {
      field: "tierName",
      width: 100
    },
    {
      field: "startingCost",
      width: 100
    },
    {
      field: "endingCost",
      width: 100
    },
    {
      field: "tierType",
      width: 150
    },
    {
      field: "tierValue",
      width: 100
    },
    {
      field: "roundTo",
      width: 100
    },
    {
      field: "tierGroups",
      width: 250
    }
  ],
  [StorageKeys.TierGroupTableHeaders]: [
    {
      field: "customId",
      width: 50
    },
    {
      field: "tierGroupName",
      width: 100
    },
    {
      field: "assignedTiers",
      width: 80
    }
  ],
  [StorageKeys.BundleTableHeaders]: [
    {
      field: "customId",
      width: 100
    },
    {
      field: "createdDate",
      width: 100
    },
    {
      field: "bundleName",
      width: 120
    },
    {
      field: "assignedLinks",
      width: 140
    },
    {
      field: "totalCost",
      width: 100
    },
    {
      field: "totalRetail",
      width: 120
    },
    {
      field: "status",
      width: 100
    }
  ],
  [StorageKeys.GroupTableHeaders]: [
    {
      field: "customId",
      width: 100
    },
    {
      field: "createdDate",
      width: 100
    },
    {
      field: "groupName",
      width: 120
    },
    {
      field: "assignedLinks",
      width: 140
    },
    {
      field: "totalCost",
      width: 100
    },
    {
      field: "totalRetail",
      width: 120
    },
    {
      field: "status",
      width: 100
    }
  ],
  [StorageKeys.MerchantTableHeaders]: [
    {
      field: "view",
      width: 120
    },
    {
      field: "customId",
      width: 80
    },
    {
      field: "merchantAdminEmail",
      width: 220
    },
    {
      field: "companyName",
      width: 200
    },
    {
      field: "firstName",
      width: 100
    },
    {
      field: "lastName",
      width: 120
    },
    {
      field: "adminPhone",
      width: 150
    },
    {
      field: "mobilePhone",
      width: 150
    },
    {
      field: "group",
      width: 200
    },
    {
      field: "status",
      width: 150
    },
    {
      field: "creationDate",
      width: 150
    },
    {
      field: "terms",
      width: 150
    },
    {
      field: "paymentType",
      width: 100
    },
    {
      field: "tierGroup",
      width: 150
    },
    {
      field: "documentType",
      width: 100
    },
    {
      field: "merchantComments",
      width: 150
    },
    {
      field: "businessType",
      width: 100
    },
    {
      field: "buying",
      width: 100
    },
    {
      field: "address",
      width: 100
    },
    {
      field: "city",
      width: 100
    },
    {
      field: "zipCode",
      width: 100
    },
    {
      field: "country",
      width: 100
    },
    {
      field: "companyNumber",
      width: 100
    },
    {
      field: "extension",
      width: 100
    },
  ],
  [StorageKeys.OrderStatusTableHeaders]: [
    {
      field: "customId",
      width: 100
    },
    {
      field: "name",
      width: 200
    },
    {
      field: "systemStatus",
      width: 150
    }
  ],
  [StorageKeys.TermsTableHeaders]: [
    {
      field: "customId",
      width: 100
    },
    {
      field: "name",
      width: 200
    },
  ],
  [StorageKeys.PaymentTypeTableHeaders]: [
    {
      field: "customId",
      width: 100
    },
    {
      field: "name",
      width: 200
    },
  ],
}