import {
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import {
  FaInstagram,
  FaTwitter,
  FaPinterest,
  FaGoogle,
  FaFacebookF,
} from "react-icons/fa";
import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="py-16 flex items-center justify-center bg-dark text-white">
      <div className="flex items-center justify-center flex-col w-10/12">
        <div className="grid lg:grid-cols-footer gap-10">
          <div>
            <img src="/logoWhite.png" alt="" className="h-16" />
            <p className="mt-4 text-gray-400">
              Link Headquarters is the premier link building platform for
              Agencies, Marketing Professionals, businesses and individuals
              looking to utilize the power of our publishing relationships.
            </p>
          </div>
          <div>
            <h3 className="text-lg font-bold uppercase mb-2">Company Info</h3>
            <ul className=" text-gray-400">
              <li>
                <Link to="/register">About us</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-bold uppercase mb-2">services</h3>
            <ul className=" text-gray-400">
              <li>How It works</li>
              <li>
                <Link to="/terms-and-conditions">Term & condition</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-bold uppercase mb-2">Address</h3>
            <p className=" text-gray-400">
              123, Lorem Ipsum Street California, USA
            </p>
          </div>
        </div>
        <div className="flex justify-between mt-16 pt-7 border-t border-white/25 w-full text-gray-400">
          <div>
            <span className="flex gap-2 mb-2">
              <MapPinIcon className="w-5" />
              <p>Los Angeles California</p>
            </span>
            <span className="flex gap-2 mb-2">
              <EnvelopeIcon className="w-5" />
              <p>support@linkheadquarters.com</p>
            </span>
            <span className="flex gap-2 mb-2">
              <PhoneIcon className="w-5" />
              <p>+1 800 854-36-80</p>
            </span>
          </div>
          <div className="flex flex-col items-end">
            <div className="flex items-center justify-end gap-3">
              <span className="border p-3 rounded-full cursor-pointer">
                <FaFacebookF className="text-2xl" />
              </span>
              <span className="border p-3 rounded-full cursor-pointer">
                <FaInstagram className="text-2xl" />
              </span>
              <span className="border p-3 rounded-full cursor-pointer">
                <FaTwitter className="text-2xl" />
              </span>
              <span className="border p-3 rounded-full cursor-pointer">
                <FaPinterest className="text-2xl" />
              </span>
              <span className="border p-3 rounded-full cursor-pointer">
                <FaGoogle className="text-2xl" />
              </span>
            </div>
            <p className="mt-5">All rights reserved © 2023 linkheadquarter</p>
          </div>
        </div>
      </div>
    </div>
  );
}
