import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Dialog, DialogActions, DialogTitle } from "@mui/material";
import {
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridValidRowModel,
  MuiEvent,
} from "@mui/x-data-grid";
import {
  DataGridPro,
  GridActionsCellItem,
  GridCellParams,
  GridColumnVisibilityModel,
  GridInputRowSelectionModel,
  GridRowSelectionModel,
  UncapitalizedGridProSlotsComponent,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Button } from "antd";
import React, { Key, useState } from "react";

import { axiosInstance } from "../../api/fetch-api";
import { API_URL, StorageKeys } from "../../constants/base.const";
import { TableHeaders } from "../../constants/table-headers.const";
import { getStorageValue, setStorageValue } from "../../util/localStorage.util";

interface Props {
  baseApiPath: string;
  checkboxSelection?: boolean;

  tableKey:
    | StorageKeys.OrderTableHeaders
    | StorageKeys.BacklinkTableHeaders
    | StorageKeys.MarketplaceTableHeaders
    | StorageKeys.UserTableHeaders
    | StorageKeys.VendorTableHeaders
    | StorageKeys.CategoryTableHeaders
    | StorageKeys.ImportLogTableHeaders
    | StorageKeys.PriceTierTableHeaders
    | StorageKeys.TierGroupTableHeaders
    | StorageKeys.BundleTableHeaders
    | StorageKeys.GroupTableHeaders
    | StorageKeys.MerchantTableHeaders
    | StorageKeys.OrderStatusTableHeaders
    | StorageKeys.TermsTableHeaders
    | StorageKeys.PaymentTypeTableHeaders;
  defaultColumnDefs: GridColDef[];
  dataGridKey: Key;
  columnHeaderHeight?: number;
  disableMultipleRowSelection?: boolean;
  editable?: boolean;
  deletable?: boolean;
  loading: boolean;
  pinActions?: boolean;
  hiddenFields?: string[];
  rows: GridRowsProp;
  onOrderChange?: (order: string, hidden: string) => void;
  rowModesModel: GridRowModesModel;
  slots?: Partial<UncapitalizedGridProSlotsComponent>;
  handleCellClick?: (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>
  ) => void;
  handleFileUpload?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  processRowUpdate?: (
    newRow: GridRowModel,
    oldRow: GridRowModel
  ) => Promise<GridValidRowModel | undefined>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  setRows: React.Dispatch<React.SetStateAction<readonly GridValidRowModel[]>>;
  setSelectedRowIds?: React.Dispatch<
    React.SetStateAction<GridRowSelectionModel>
  >;
  rowSelectionModel?: GridInputRowSelectionModel;
}

export default function CrudGrid({
  baseApiPath,
  checkboxSelection,
  tableKey,
  dataGridKey,
  defaultColumnDefs,
  columnHeaderHeight = 36,
  disableMultipleRowSelection,
  editable,
  deletable,
  loading,
  pinActions,
  rows,
  rowModesModel,
  slots,
  hiddenFields,
  onOrderChange,
  handleCellClick,
  handleFileUpload,
  processRowUpdate,
  setLoading,
  setRowModesModel,
  setRows,
  setSelectedRowIds,
  rowSelectionModel,
}: Props) {
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [rowIdToDelete, setRowIdToDelete] = useState<GridRowId>(0);

  const apiRef = useGridApiRef();

  // manage headers
  const [tableFields, setTableFields] = useState(
    (getStorageValue(tableKey) as any[]) || TableHeaders[tableKey]
  );
  const handleOrderedTableFields = (newTableFields: string[]) => {
    const filteredFieldNameList = newTableFields.filter(
      (orderHeaderName) =>
        orderHeaderName !== "__check__" && orderHeaderName !== "action"
    );
    const updatedTableFields = filteredFieldNameList.map((newFieldName) => {
      return tableFields.find(
        (tableField) => tableField.field === newFieldName
      );
    });
    setTableFields(updatedTableFields);
    setStorageValue(tableKey, updatedTableFields);
  };

  const columnDefs: GridColDef[] = tableFields.map((tableField: any) => {
    return defaultColumnDefs.find((def) => {
      if (def.field === tableField.field) {
        def.width = tableField.width;
        return def;
      }
    }) as GridColDef;
  });

  const actionColDef: GridColDef = {
    field: "action",
    headerName: "Action",
    type: "actions",
    cellClassName: "actions",
    disableColumnMenu: false,
    width: 120,
    getActions: ({ id, row }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      const actionItems = [];
      if (
        deletable &&
        (row?.isDeletable !== undefined ? row?.isDeletable : true)
      ) {
        //if row should not remove, it should have isDeletable:false
        actionItems.push(
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
          />
        );
      }
      if (editable) {
        actionItems.push(
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />
        );
      }

      return actionItems.reverse();
    },
  };

  const columns: GridColDef[] =
    pinActions === undefined
      ? columnDefs
      : pinActions
      ? ([actionColDef] as GridColDef[]).concat(columnDefs)
      : columnDefs.concat([actionColDef] as GridColDef[]);

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => {
    setRowIdToDelete(id);
    setShowDeletePrompt(true);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleRowEditStart = (
    _params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    console.log(">>> handleRowEditStart", _params.row);
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    _params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    console.log(newRowModesModel);
    setRowModesModel(newRowModesModel);
  };

  // handle columnVisibilityModel
  const handleColumnVisibilityModelChange = (
    model: GridColumnVisibilityModel
  ) => {
    const updatedTableFields = tableFields.map((tableField) => {
      // default value of `model[tableField.field]` is undefined
      // if tableField is hidden, value of `model[tableField.field]` is false
      console.log(
        "tableField.field, model[tableField.field]:",
        tableField.field,
        model[tableField.field]
      );
      return {
        ...tableField,
        hidden: model[tableField.field] === false ? true : false,
      };
    });
    setTableFields(updatedTableFields);
    setStorageValue(tableKey, updatedTableFields);
  };

  return (
    <Box
      className="shadow-md shadow-black/5 rounded-lg bg-white h-full w-full p-4"
      sx={{
        height: 520,
        width: "100%",
        "& .actions": {
          color: "#263238",
        },
        "& .textPrimary": {
          color: "#263238",
        },
      }}
    >
      <DataGridPro
        key={dataGridKey}
        rows={rows}
        columns={columns}
        rowSelectionModel={rowSelectionModel}
        editMode="row"
        loading={loading}
        checkboxSelection={!!checkboxSelection}
        disableMultipleRowSelection={disableMultipleRowSelection}
        rowModesModel={rowModesModel}
        rowHeight={40}
        columnHeaderHeight={columnHeaderHeight}
        onColumnOrderChange={(_params, _event, details: any) => {
          handleOrderedTableFields(details.api.state.columns.orderedFields);
        }}
        // onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        onCellClick={handleCellClick}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        onRowSelectionModelChange={(
          rowSelectionModel: GridRowSelectionModel
        ) => {
          setSelectedRowIds && setSelectedRowIds(rowSelectionModel);
        }}
        processRowUpdate={processRowUpdate}
        slots={slots}
        slotProps={{
          toolbar: {
            rowModesModel,
            setRows,
            setRowModesModel,
            handleFileUpload,
            disable: loading,
          },
        }}
        onProcessRowUpdateError={console.error}
        classes={{
          columnHeaderTitle: "tableHead font-bold text-md capitalize px-0",
          row: "text-md hover:bg-gray-50",
          columnHeaders: "bg-gray-100 shadow",
        }}
        apiRef={apiRef}
        initialState={{ pinnedColumns: { left: ["__check__"] } }}
      />
      <Dialog
        open={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to delete record?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowDeletePrompt(false)}>Cancel</Button>
          <Button
            onClick={async () => {
              try {
                setShowDeletePrompt(false);
                setLoading(true);
                await axiosInstance.delete(
                  `${API_URL}/${baseApiPath}/${rowIdToDelete}`
                );
                setRows(rows.filter((row) => row.id !== rowIdToDelete));
              } catch (e) {
                console.log(e);
              }
              setLoading(false);
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
